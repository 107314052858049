import React, {
    forwardRef, Fragment, useEffect, useImperativeHandle, useRef, useState
} from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { QuestionMarkCircleIcon, XMarkIcon } from '@heroicons/react/24/outline'
import { Spinner } from "../components/spinner";
import YouTube from 'react-youtube';
import * as Util from "../helpers/util";


export const TutorialModal = forwardRef((props, ref) => {
    const { open, sub_tutorial, onClose, showToast } = props

    //useImperativeHandle(ref, () => ({
    //}))

    const initial_state = {
        feedback: "",
        show_feedback: false,
    }

    const [state, setState] = useState({
        tutorials: [],
        ...initial_state
    })
    const { feedback, show_feedback, tutorials } = state

    useEffect(() => {
        Util.fetch_js('/api/tutorial/list/', {},
            js => {
                setState(prev => ({ ...prev,
                    tutorials: js.tutorials
                }))
            }, showToast )

        // access to player in all event handlers via playerRef.current
        //playerRef.current.internalPlayer.pauseVideo();
    }, [])

    useEffect(() => {
        if ( !open ) {
            setTimeout(() => setState( prev => ({ ...prev, ...initial_state }) ), 1000)
        }
    }, [open])

    const playerRef = useRef(null);

    const opts = {
        height: '390',
        width: Util.isSM()? '620': window.screen.width - 60,
        playerVars: {
            autoplay: 1,
        },
    };

    const onReady = (event) => {
        playerRef.current = event.target;
    };

    const handleShowFeedback = () => {
        //Show the spinner
        setState(prev => ({ ...prev, show_feedback: true }))
    }

    const handleSendFeedback = () => {
        if ( feedback === "" ) {
            showToast('Please enter feedback', "warning")
            return
        }

        Util.fetch_js('/api/tutorial/feedback/', { tutorial_code, feedback },
            js => {
                //Send feedback
                showToast('Feedback submitted', "success")

                //Close the modal
                onClose()
            }, showToast )
    }

    const handleChange = (event) => {
        const { id, value } = event.target;
        setState(prev => ({ ...prev, [id]: value }))
    }

    const path = `${window.location.pathname}`.replace(/^\//, '').replace(/\/.*$/, '').toLowerCase()
    let tutorial_code = ''
    if ( path === '' ) {
        tutorial_code = 'landing'
    }
    else if ( path === 'document' ) {
        if ( sub_tutorial === 'Feed' ) {
            tutorial_code = 'legislation feed'
        }
        else if ( sub_tutorial === 'Rating' ) {
            tutorial_code = 'legislation rating'
        }
        else if ( sub_tutorial === 'Details' ) {
            tutorial_code = 'legislation details'
        }
        else if ( sub_tutorial === 'Bill Chat' ) {
            tutorial_code = 'legislation chat'
        }
        else if ( sub_tutorial === 'Bill Text' ) {
            tutorial_code = 'legislation text'
        }
    }
    else if ( path === 'activity' ) {
        tutorial_code = 'activity'
    }
    else if ( path === 'health' ) {
        tutorial_code = 'health'
    }
    else if ( path === 'anchors' ) {
        tutorial_code = 'anchor list'
    }
    else if ( path === 'anchor' ) {
        tutorial_code = 'anchor document'
    }
    else if ( path === 'tags' ) {
        tutorial_code = 'tags'
    }
    else if ( path === 'team' ) {
        tutorial_code = 'tenant team'
    }
    else if ( path === 'profile' ) {
        tutorial_code = 'tenant profile'
    }
    else if ( path === 'tenant' ) {
        tutorial_code = 'tenant detail'
    }
    console.log(path)
    console.log(tutorial_code)

    const tutorial = tutorials.find( x => x.type.toLowerCase() === tutorial_code.toLowerCase()) || { title: 'Anchor tutorial', description: 'Welcome to the anchor', yt_code: 'xxx' }

    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" className="relative z-40" onClose={onClose}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-40 w-screen overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel
                                className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-3xl sm:p-6">
                                <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                                    <button
                                        type="button"
                                        className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none"
                                        onClick={onClose}>
                                        <span className="sr-only">Close</span>
                                        <XMarkIcon className="h-6 w-6"
                                                   aria-hidden="true"/>
                                    </button>
                                </div>
                                <div className="sm:flex sm:items-start">
                                    <div
                                        className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full sm:mx-0 sm:h-10 sm:w-10">
                                        <QuestionMarkCircleIcon
                                            className="h-6 w-6 text-black flex-shrink-0"
                                            aria-hidden="true"/>
                                    </div>
                                    <div
                                        className="mt-3 text-center sm:mx-4 sm:mt-0 sm:text-left w-full">
                                        <Dialog.Title as="h3"
                                                      className="text-base font-semibold leading-6 text-gray-900">
                                            {tutorial?.title}
                                        </Dialog.Title>
                                        {show_feedback &&
                                            <div className="mt-3 w-full">
                                                <textarea
                                                    id="feedback"
                                                    name="feedback"
                                                    rows={6}
                                                    className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                                                    placeholder="Feedback"
                                                    value={feedback}
                                                    onChange={handleChange}>
                                                </textarea>
                                            </div>
                                        }
                                        {!show_feedback &&
                                        <div className="mt-3 w-full">
                                            <div>
                                                <YouTube videoId={tutorial?.yt_code} opts={opts} onReady={onReady}/>
                                            </div>
                                            <p className="mt-1 text-sm text-gray-500">
                                                {tutorial?.description}
                                            </p>
                                        </div>
                                        }
                                    </div>
                                </div>
                                <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                                    {show_feedback &&
                                        <button
                                            type="button"
                                            className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 sm:ml-3 sm:w-auto"
                                            onClick={handleSendFeedback}>
                                            Submit
                                        </button>
                                    }
                                    {!show_feedback &&
                                    <button
                                        type="button"
                                        className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 sm:ml-3 sm:w-auto"
                                        onClick={handleShowFeedback}>
                                        Feedback
                                    </button>
                                    }
                                    <button
                                        type="button"
                                        className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                                        onClick={onClose}>
                                        Close
                                    </button>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
})
