import React, { useState } from 'react'
import {ArrowUpOnSquareStackIcon} from '@heroicons/react/24/outline'
import * as Util from '../helpers/util'
import { ButtonOptions } from "./button_options";
import { PillChange, simpleValue } from "./pill_change";
import { UserRatingModal } from "../modals/user_rating_modal";


const RateActions = {
    RATE: 0,
    RATE_FORCE: 1,
    EXPORT_XLSX: 2,
}

const RateOptions = [
    'Rate Legislation',
    'Force Re-Rating',
    'Export XLSX',
]

export const RateLegislation = props => {
    const { policies, policy_uid, content_uid, onRatePolicy, onUserRating, showToast } = props
    const user_rating = props.user_rating || 0
    const ai_rating = props.ai_rating || { pos_rating: 0, neg_rating: 0}

    const [state, setState] = useState({
        show_user_rating: false,
    })
    const { show_user_rating } = state

    //const policy = policies.find( pol => pol.uid === policy_uid ) || { uid: null, name: 'Empty'}

    const handleRate = (action) => {
        if ( action === RateActions.RATE ) {
            onRatePolicy(policy_uid)
        }
        else if ( action === RateActions.RATE_FORCE ) {
            onRatePolicy(policy_uid, true)
        }
        else if ( action === RateActions.EXPORT_XLSX ) {
            if ( policies.length === 0 ) {
                showToast( "No policy found", "error" )
                return
            }
            Util.post_new_tab( '/api/document/export_rating/', { content_uid, policy_uid } )
        }
    }

    const handleChangeUserRating = (document_uid, user_rating) => {
        if ( user_rating !== null ) {
            onUserRating( {target:{value: user_rating}} )
        }

        setState( prev => ({
            ...prev, show_user_rating: false
        }) )
    }

    return (
        <div className="bg-white mt-8">
            {/* Filters */}
            <section aria-labelledby="filter-heading">
                <div className="border-b border-gray-200 bg-white pb-4">
                    <div
                        className="mx-auto flex max-w-7xl items-center justify-between">
                        <ButtonOptions
                            align="left"
                            options={RateOptions}
                            onClick={handleRate}>
                            <ArrowUpOnSquareStackIcon
                                className="-ml-0.5 mr-2 h-4 w-4 flex-shrink-0"
                                aria-hidden="true"/>
                            Rate Legislation
                        </ButtonOptions>

                        <div className="mr-4 inline-flex items-center text-indigo-600">
                            AI Rating
                            <PillChange
                                className="ml-4"
                                {...simpleValue( ai_rating.pos_rating )}
                            />
                            <PillChange
                                className="mx-2"
                                {...simpleValue( ai_rating.neg_rating )}
                            />
                        </div>

                        <div
                            className="mr-4 inline-flex items-center text-indigo-600 cursor-pointer"
                            onClick={() => setState( prev => ({
                                ...prev,
                                show_user_rating: true
                            }) )}>
                            User Rating
                            <PillChange
                                className="mx-4"
                                {...simpleValue( user_rating )}
                            />
                        </div>
                    </div>
                </div>
            </section>

            <UserRatingModal
                open={show_user_rating}
                value={user_rating}
                onClose={handleChangeUserRating}
                showToast={showToast}
            />
        </div>)
}
