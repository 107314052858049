import * as Util from "../helpers/util.js"

import React, {useEffect, useRef, useState} from 'react'
import {
    EllipsisHorizontalIcon,
} from '@heroicons/react/24/solid'
import { useStore } from "../store";
import {
    PencilSquareIcon,
} from "@heroicons/react/24/outline";
import { ChatQuery } from "../components/chat_query";
import { ChatConversation } from "../components/chat_conversation";


export const LegislationChat = (props) => {
    const {document, content_idx, contents, chat, history, onQuestion, onRate, onHistory, showToast} = props

    const {usr_info} = useStore( x => x)

    const readOnly = (chat !== null && chat.human.uid !== usr_info.uid)

    const handleCreate = () => {
        onHistory(null)
    }

    const humans = {}
    props.humans.forEach( h => humans[h.uid] = h)

    const [contentHeight, setContentHeight] = useState(0);
    const footerRef = useRef(null);

    useEffect(() => {
        const resizeContentDiv = () => {
            if ( !footerRef.current ) {
                return;
            }

            const screenHeight = window.innerHeight;
            const headerHeight = 328 //Imperical number
            const footerHeight = footerRef.current.offsetHeight;
            const newContentHeight = screenHeight - headerHeight - footerHeight;

            setContentHeight((newContentHeight < 520)? 520: newContentHeight);
        };

        resizeContentDiv(); // Call on initial render
        window.addEventListener('resize', resizeContentDiv); // Call on window resize

        return () => {
            window.removeEventListener('resize', resizeContentDiv); // Cleanup on component unmount
        };
    }, []);

    return (
        <div>
            <div style={{height: `${contentHeight}px`}} className="py-0 flex flex-col md:flex-row">
                <div className={
                    Util.classNames(
                        "panel flex flex-col md:w-1/4 py-4 md:border-r",
                        "overflow-y-auto",
                    )}>
                    <p className="px-4 pb-4 inline-flex justify-between text-lg font-bold text-gray-600">
                        History
                        <PencilSquareIcon
                            className="h-6 w-6 ml-2 text-indigo-600 cursor-pointer"
                            onClick={handleCreate}
                        />
                    </p>

                    {history.map( ( hist, idx ) => (
                        <div key={`history_${idx}`}
                             className="flex justify-between gap-x-4 p-2 border-t border-gray-300 cursor-pointer"
                             onClick={() => onHistory(hist)}>
                            <p className="text-sm truncate">
                                {hist.name}
                            </p>
                            <EllipsisHorizontalIcon
                                className="hidden flex-shrink-0 sm:inline h-6 w-6 hover:text-indigo-600"/>
                        </div>
                    ))}
                </div>
                <div className="panel p-4 lg:pr-1 md:w-full">
                    <p className="text-lg font-bold text-gray-600">
                        Chat
                    </p>
                    <ChatConversation
                        chat={chat}
                        showToast={showToast}
                    />
                    {/*onRate={onRate}*/}
                </div>
            </div>
            <div ref={footerRef} className="px-4 py-4 sm:px-6">
                <ChatQuery
                    readOnly={readOnly}
                    onQuestion={onQuestion}
                    showToast={showToast}
                />
            </div>
        </div>
    )
}
