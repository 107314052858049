import React from "react";
import {
    ArrowDownIcon,
    ArrowLeftIcon,
    ArrowUpIcon, MinusIcon
} from "@heroicons/react/20/solid";
import * as Util from "./util";

export const LoginType = {
    None:       0,
    AuthReq:    1,
    CreateReq:  2,
    LoggedIn:   3,
};

export const RatingOverlap = {
    SUPPORTED: "Supported",
    AGAINST: "Against",
    DOES_NOT_APPLY: "Does Not Apply",
}

export const PRIORITIES = [ "high", "medium", "low", "none" ]

export const COLORS = [
    'red', 'yellow', 'green', 'blue', 'fuchsia', 'none'
]

export const TextColor = (color) => {
    const colors = {
        red: 'text-red-600 font-bold',
        green: "text-green-600 font-semibold",
        blue: "text-sky-500 font-semibold",
        yellow: "text-yellow-400 font-semibold",
        fuchsia: "text-fuchsia-600 font-semibold",

        orange: 'text-orange-600',
        none: "text-gray-900",
    }

    color = Util.xstr(color).toLowerCase()
    if ( !(color in colors) ) {
        color = 'none'
    }

    color = color.toLowerCase()
    return colors[color]
}

export const CircleColor = (color) => {
    const colors = {
        red: 'bg-red-600',
        yellow: "bg-yellow-400",
        green: "bg-green-500",
        blue: "bg-sky-500",
        fuchsia: "bg-fuchsia-600",

        orange: 'bg-orange-600',
        none: "ring-gray-300 ring-1",
    }

    color = Util.xstr(color).toLowerCase()
    if ( !(color in colors) ) {
        color = 'none'
    }

    return (<div className={`w-4 h-4 rounded-full ${colors[color]}`}/>)
}

export const ARROW_DIRECTION = {
    high: (<ArrowUpIcon className={`w-5 h-5 rounded-full text-red-600`}/>),
    medium: (<ArrowLeftIcon className={`w-5 h-5 rounded-full text-purple-600`}/>),
    low: (<ArrowDownIcon className={`w-5 h-5 rounded-full text-teal-500`}/>),
    none: (<MinusIcon className={`w-5 h-5 rounded-full text-gray-500`}/>),
}

export const CONDITIONAL = {
    RELEVANCE: "Relevance",
    SUPPORTING: "Supporting",
    OPPOSING: "Opposing",
}