import React, {useEffect, useState} from 'react'
import {
    ArrowDownIcon,
    ArrowLeftIcon,
    ArrowUpIcon,
    CheckIcon,
    ChevronUpDownIcon,
    TrashIcon
} from '@heroicons/react/20/solid'
import { Combobox } from '@headlessui/react'
import * as Util from "../helpers/util.js";
import {EmptyList} from "../components/empty_list";
import {BuildingLibraryIcon} from "@heroicons/react/24/outline";
import {ARROW_DIRECTION, COLOR_LOOKUP} from "../helpers/consts";


export const GovernmentSelector = props => {
    const {selected_uids, className, onChange, showToast} = props

    const [state, setState] = useState({
        loaded: false,
        lookup: {},
        governments: [],
        query: "",
    })
    const {loaded, lookup, governments, query} = state

    useEffect(() => {
        Util.fetch_js('/api/government/list/', {},
            js => {
                const {governments} = js
                const lookup = {}
                governments.forEach(government => {
                    lookup[government.uid] = government
                })

                setState(prev => ({ ...prev,
                    loaded: true,
                    governments,
                    lookup,
                }))
            }, showToast)
    }, [])

    const handleChange = ary => {
        setState(prev => ({...prev, query: ''}))
        onChange(ary)
    }

    const handleKeyDown = (e, uid) => {
        if (e.key === 'Enter') {
            onChange([...selected_uids, uid])
        }
    }

    const _filteredGovernments = (query === '') ? governments : governments.filter((government) => { return government.name.toLowerCase().includes(query.toLowerCase()) })
    const filteredGovernments = _filteredGovernments.filter((government) => selected_uids.findIndex(uid => uid === government.uid) === -1)

    const handleAdd = (government) => {
    }

    const handleDelete = (government_uid) => {
    }

    const sel_govs = selected_uids.filter(uid => uid in lookup ).map( uid => lookup[uid] )

    return (
        <div className={Util.classNames("py-5 flex flex-col items-center", className)}>
            <div className="w-full flex items-start">
                <Combobox as="div" onChange={handleAdd}>
                    <Combobox.Label className="block text-sm font-bold leading-6 text-gray-900">
                        Governments
                    </Combobox.Label>
                    <div className="relative mt-2">
                        <Combobox.Input
                            className="w-full rounded-md border-0 bg-white py-1.5 pl-3 pr-12 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            onChange={(event) => setState(prev => ({...prev, query: event.target.value}))}
                        />
                        <Combobox.Button
                            className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
                            <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true"/>
                        </Combobox.Button>

                        <Combobox.Options
                            className="absolute z-20 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                            {filteredGovernments.map((government) => (
                                <Combobox.Option
                                    key={government.uid}
                                    value={government}
                                    className={({active}) =>
                                        Util.classNames(
                                            'relative cursor-default select-none py-2 pl-3 pr-9',
                                            active ? 'bg-indigo-600 text-white' : 'text-gray-900'
                                        )
                                    }>
                                    <div className="flex items-center"
                                        onClick={() => handleChange([...selected_uids, government.uid])}>
                                        <span className={Util.classNames('ml-3 truncate')}>
                                            {government.name}
                                        </span>
                                    </div>
                                </Combobox.Option>
                            ))}
                            {filteredGovernments.length === 0 &&
                                <div className="py-2 pl-3 pr-9 text-gray-900">
                                    No governments left
                                </div>
                            }
                        </Combobox.Options>
                    </div>
                </Combobox>
            </div>

            <div className="px-4 sm:px-6 lg:px-8 w-full">
                <div className="mt-4 flow-root">
                    <div className="-mx-4 -my-2 sm:-mx-6 lg:-mx-8">
                        <div className="inline-block min-w-full py-2 align-middle">
                            <table className="min-w-full border-separate border-spacing-0">
                                <thead>
                                <tr>
                                    <th scope="col"
                                        className="sticky top-0 border-b border-gray-300 bg-white bg-opacity-75 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:pl-6 lg:pl-8">
                                        Name
                                    </th>
                                    <th scope="col"
                                        className="sticky top-0 border-b border-gray-300 bg-white bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter">
                                    </th>
                                </tr>
                                </thead>
                                <tbody>
                                {sel_govs.map( ( government, idx ) => (
                                    <tr key={`government_tr_${idx}`}>
                                        <td className={Util.classNames(
                                                idx !== selected_uids.length - 1 ? 'border-b border-gray-200' : '',
                                                'whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 lg:pl-8',
                                            )}>
                                            {government.name}
                                        </td>
                                        <td className={Util.classNames( idx !== selected_uids.length - 1 ? 'border-b border-gray-200' : '', 'whitespace-nowrap px-3 py-4 text-sm text-gray-500' )}>
                                            <TrashIcon className="h-5 w-5 text-gray-400 hover:text-red-500 cursor-pointer"
                                                       aria-hidden="true"
                                                       onClick={() => handleChange( selected_uids.filter(uid => uid !== government.uid ))}
                                            />
                                        </td>
                                    </tr>) )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>

            {loaded && selected_uids.length === 0 &&
                <EmptyList
                    icon={BuildingLibraryIcon}
                    name="government"
                />
            }
        </div>
    )
}
