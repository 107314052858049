import { PlusIcon } from "@heroicons/react/24/solid";
import React from "react";

export const PolicyClaimFooter = (props) => {
    const {p_idx, pillar, onAdd, onDelete} = props

    return (
        <div className="flex items-center justify-between gap-x-6 border-t border-gray-900/10 px-4 py-4 sm:px-8">
            <button
                className="inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                onClick={() => onAdd( p_idx )}>
                <PlusIcon className="h-4 w-4 mr-1 flex-shrink-0"
                          aria-hidden="true"/>
                Claim
            </button>
            {false&&
            <button type="button"
                    onClick={() => onDelete( pillar.uid )}
                    className="text-sm font-semibold leading-6 text-red-600">
                Remove Pillar
            </button>
            }
        </div>
    )
}
