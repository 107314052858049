import React, { useState, useRef, useEffect } from "react"
import * as Util from "../helpers/util.js"
import { Link, useNavigate } from "react-router-dom";

import {ArrowDownIcon, ArrowUpIcon, TrashIcon} from '@heroicons/react/20/solid'
import { Dropdown } from "../components/dropdown";
import {PillChange, PillType} from "../components/pill_change";
import {DocumentTextIcon} from "@heroicons/react/24/outline";
import {PlusIcon} from "@heroicons/react/24/solid";
import { EmptyList } from "../components/empty_list";
import { ConfirmModal } from "../modals/confirm_modal";
import {PageTitle} from "../components/page_title";


export const Policy = (props) => {
    const {government_uid, onPolicyTaint, showToast} = props

    const [state, setState] = useState({
        loaded: false,
        policies: [],
        show_delete: null,
    })
    const { loaded, policies, show_delete } = state

    const navigate = useNavigate()

    useEffect(() => {
        Util.fetch_js('/api/policy/tenant/', {},
            js => {
                setState(prev => ({ ...prev,
                    loaded: true,
                    policies: js.policies,
                }))

            }, showToast)

    }, [])

    const handleCreate = () => {
        const payload = {
            name: 'New Anchor',
                government_uids: [government_uid],
                add_tenant_government: true
        }
        Util.fetch_js( '/api/policy/create/', payload,
            js => {
                onPolicyTaint()

                navigate( `/anchor/${js.policy.uid}` )
            }, showToast )
    }

    const handleDelete = (uid) => {
        setState(prev => ({ ...prev, show_delete: null }))

        if ( uid !== null ) {
            Util.fetch_js( '/api/policy/remove/', { policy_uids: [uid] },
                js => {
                    onPolicyTaint()

                    setState(prev => ({ ...prev,
                        policies: js.policies,
                    }))
                }, showToast )
        }
    }

    return (
        <div>
            <PageTitle
                title="Anchor Docs"
                subtitle="Create and manage your anchor documents"
                button_text="Create Anchor"
                button_icon={<PlusIcon className="-ml-0.5 mr-1.5 h-5 w-5" aria-hidden="true" />}
                onClick={handleCreate}
            />

            <div className="px-4 sm:px-6 lg:px-8">
                <div className="-mx-4 -my-2 sm:-mx-6 lg:-mx-8">
                    <div className="inline-block min-w-full py-2 align-middle">
                        <table className="min-w-full border-separate border-spacing-0">
                            <thead>
                            <tr>
                                <th scope="col"
                                    className="sticky top-0 z-10 border-b border-gray-300 bg-white bg-opacity-75 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:pl-6 lg:pl-8">
                                    Title
                                </th>
                                <th scope="col"
                                    className="sticky top-0 z-10 hidden border-b border-gray-300 bg-white bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:table-cell">
                                    Governments
                                </th>
                                <th scope="col"
                                    className="sticky top-0 z-10 border-b border-gray-300 bg-white bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter">
                                    Pillars
                                </th>
                                <th scope="col"
                                    className="sticky top-0 z-10 border-b border-gray-300 bg-white bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter">
                                    Claims
                                </th>
                                <th scope="col"
                                    className="sticky top-0 z-10 border-b border-gray-300 bg-white bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter">
                                    Prompts
                                </th>
                                <th scope="col"
                                    className="sticky top-0 z-10 border-b border-gray-300 bg-white bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter">
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            {policies.map((policy, idx) => (
                                <tr key={`policy_tr_${idx}`}>
                                    <td className={Util.classNames(idx !== policies.length - 1 ? 'border-b border-gray-200' : '', 'whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 lg:pl-8')}>
                                        <Link
                                            to={`/anchor/${policy.uid}`}
                                            className="text-indigo-600 hover:text-indigo-900">
                                            {policy.name}
                                        </Link>
                                    </td>
                                    <td className={Util.classNames(idx !== policies.length - 1 ? 'border-b border-gray-200' : '', 'whitespace-wrap hidden px-3 py-4 text-sm text-gray-500 sm:table-cell')}>
                                        {policy.governments.map(x => x.name).join(', ')}
                                    </td>
                                    <td className={Util.classNames(idx !== policies.length - 1 ? 'border-b border-gray-200' : '', 'whitespace-nowrap px-3 py-4 text-sm text-gray-500')}>
                                        {policy.pillar_count}
                                    </td>
                                    <td className={Util.classNames(idx !== policies.length - 1 ? 'border-b border-gray-200' : '', 'whitespace-nowrap px-3 py-4 text-sm text-gray-500')}>
                                        {policy.claim_count}
                                    </td>
                                    <td className={Util.classNames(idx !== policies.length - 1 ? 'border-b border-gray-200' : '', 'whitespace-nowrap px-3 py-4 text-sm text-gray-500')}>
                                        {policy.prompt_count}
                                    </td>
                                    <td className={Util.classNames(idx !== policies.length - 1 ? 'border-b border-gray-200' : '', 'whitespace-nowrap px-3 py-4 text-sm text-gray-500')}>
                                        <TrashIcon className="h-5 w-5 text-gray-400 hover:text-red-500 cursor-pointer"
                                                   aria-hidden="true"
                                                   onClick={() => setState(prev => ({
                                                       ...prev,
                                                       show_delete: policy.uid
                                                   }))}
                                        />
                                    </td>
                                </tr>))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

            {loaded && policies.length === 0 &&
                <EmptyList
                    icon={DocumentTextIcon}
                    name="policy"
                    onCreate={handleCreate}
                />
            }

            <ConfirmModal
                open={show_delete !== null}
                uid={show_delete}
                danger={true}
                title="Delete this policy?"
                message="Are you sure you want to delete this policy?"
                confirmBtn="Delete"
                onClose={handleDelete}
            />
        </div>
    )
}
