import React, { Fragment, useEffect, useState } from 'react'
import { Dialog, Disclosure, Menu, Popover, Transition } from '@headlessui/react'
import {
    ArrowTopRightOnSquareIcon,
    XMarkIcon
} from '@heroicons/react/24/outline'
import {ChevronDownIcon, ChevronUpIcon} from '@heroicons/react/20/solid'
import * as Util from '../helpers/util'
import { Dropdown, DropdownItem } from "./dropdown";
import { COLOR_LOOKUP, COLORS } from "../helpers/consts";
import { GovernmentSelector } from "./government_selector";
import { SelectMenu } from "./select_menu";
import { ChatQuery } from "./chat_query";
import { ChatConversation } from "./chat_conversation";
import { closeWS, connectWS, sendWS } from "../helpers/util";
import { WS_URL } from "../settings";
import { useStore } from "../store";
import { convertRating } from "./pill_change";
import { ComboboxMenu } from "./combobox_menu";


export const PolicySandbox = props => {
    const { document_uid, onDocumentChange, onConnectionError, onQuestion, showToast } = props
    const className = props.className || ''

    const years = Array.from({length: new Date().getFullYear() - 2022}, (_, i) => 2023 + i);

    const { usr_info } = useStore( x => x )

    const [state, setState] = useState( {
        government_uid: null,
        governments: [],
        documents: [],
        contents: [],
        year: new Date().getFullYear(),
        readOnly: false,
        ready: false,
        connection_retry: 0,

        question: "",
        chat: { payload: { conversation: [] }, human: usr_info, uid: '' },
    })
    const { governments, government_uid, documents, contents, year, readOnly, ready, connection_retry, question, chat } = state

    const [ai_response, setAiResponse] = useState( null )
    const [rating, setRating] = useState( null )
    const [socket, setSocket] = useState( null );

    useEffect( () => {
        Util.fetch_js( '/api/government/list/', {},
            js => {
                handleGovernmentChange( { target: { value: js.government_uid }})
                setState( prev => ({ ...prev,
                    governments: js.governments,
                    government_uid: js.government_uid,
                }) )
            }, showToast )

    }, [socket] )

    //Websocket client loading here
    useEffect( () => {
        if ( connection_retry < 0 ) {
            return
        }

        const _socket = connectWS( `${WS_URL}/ws/cluster`, setSocket, {
            'enqueue_query': recvQuery,
            'enqueue_rate': recvRate,
        } )

        //Track the state of the socket
        _socket.onopen = () => {
            console.log( "Socket connected" )
            if ( connection_retry > 0 ) {
                showToast( "Connection re-established.", "successful" )
            }

            setState( prev => ({ ...prev, ready: true }) )
        }
        _socket.onclose = () => {
            console.log( 'Socket closed')
            onConnectionError()
            setState( prev => ({ ...prev,
                ready: false,
            }) )

            setTimeout( () => {
                setState( prev => ({ ...prev, connection_retry: prev.connection_retry + 1 }))
            }, 5000 )
        }

        return () => {
            console.log("Calling close")
            closeWS( _socket )
        }
    }, [] );

    useEffect( () => {
        if ( ai_response === null || state.chat === null ) {
            console.log( "Got AI response, but chat isn't read: ", ai_response, state.chat )
            return
        }

        const human_uid = usr_info.uid || null
        if ( human_uid === null ) {
            showToast( 'Invalid human or content', 'error' )
            return
        }

        // Immediately rate
        sendWS( socket, 'enqueue_rate', { question, response: ai_response, human_uid })

        const chat = { ...state.chat }
        if ( chat.payload.conversation.length > 0 ) {
            chat.payload.conversation[chat.payload.conversation.length - 1].response = ai_response
        }

        setState( prev => ({ ...prev,
            chat,
        }) )

    }, [ai_response] );

    const recvQuery = ( { response } ) => {
        setAiResponse( response ) // Required to do this, and then "useEffect" to keep things in sync
        setRating( null )
    }

    const recvRate = ( { rating } ) => {
        console.log(rating)
        setRating( rating )
    }

    const handleQuestion = ( question ) => {
        const human_uid = usr_info.uid || null
        const content_uid = (contents.length > 0)? contents[contents.length - 1].uid: null
        if ( human_uid === null || content_uid === null ) {
            showToast( 'Invalid human or content', 'error' )
            return
        }

        //Send the query
        sendWS( socket, 'enqueue_query', { question, human_uid, content_uid })

        //Force creation of a chat object
        const chat = { ...state.chat }
        chat.payload.conversation = [{
            uid: '',
            question,
            response: null,
            created_on: Util.epoch(),
        }]
        setState( prev => ({ ...prev,
            chat,
            question,
        }) )

        Util.copyToClipboard( question )
        showToast( 'Question copied to clipboard', 'info' )

        onQuestion( question )
    }

    const handleGovernmentChange = ( e ) => {
        const government_uid = e.target.value
        Util.fetch_js( '/api/document/list/', { government_uid },
            js => {
                if ( js.documents.length > 0 ) {
                    handleDocumentChange( js.documents[0].uid )
                }

                setState( prev => ({ ...prev,
                    documents: js.documents,
                }) )
            }, showToast )

        setState( prev => ({ ...prev,
            government_uid,
        }) )
    }

    const handleDocumentChange = ( document_uid ) => {
        const human_uid = usr_info.uid || null
        /*
        if ( socket && human_uid ) {
            sendWS( socket, 'chat_init', { document_uid, human_uid } )
        }
        else {
            //console.log( socket, human_uid )
        }
         */

        Util.fetch_js( '/api/document/detail/', { document_uid },
            js => {
                setState( prev => ({ ...prev,
                    contents: js.contents,
                }) )
            }, showToast )

        onDocumentChange( document_uid )
    }

    const handleChange = ( e ) => {
        setState( prev => ({ ...prev,
            [e.target.id]: e.target.value,
        }) )
    }

    const government = governments.find( gov => gov.uid === government_uid ) || { name: 'None' }
    const document = documents.find( doc => doc.uid === document_uid ) || { code: 'None', title: 'None' }
    const pdf_url = (contents.length > 0)? contents[contents.length - 1].content_url +`/${document.code}.pdf`: null

    return (
        <div className={Util.classNames("grid grid-cols-1 gap-x-8 gap-y-8 md:grid-cols-3 border-b pb-10", className)}>
            <div className="px-4 sm:px-0">
                <h2 className="text-base font-semibold leading-7 text-gray-900">
                    Sandbox Context
                </h2>
            </div>

            <div className="bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2">
                <div className="px-4 pt-6 pb-0 sm:p-8">
                    <div
                        className="grid w-full grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                        <div className="sm:col-span-3">
                            <label htmlFor="government"
                                   className="block text-sm font-bold leading-6 text-gray-900">
                                Government
                            </label>
                            <div className="mt-2">
                                <Dropdown
                                    className="mx-4"
                                    header='Governments'
                                    value={government.name}
                                    align="left">
                                    {governments.map( ( gov, idx ) => (
                                        <DropdownItem
                                            id={government_uid}
                                            key={`${government_uid}_${idx}`}
                                            value={gov.uid}
                                            onClick={handleGovernmentChange}>
                                            {gov.name}
                                        </DropdownItem>) )}
                                </Dropdown>
                            </div>
                        </div>

                        <div className="sm:col-span-3">
                            <label htmlFor="year"
                                   className="block text-sm font-bold leading-6 text-gray-900">
                                Year
                            </label>
                            <div className="mt-2">
                                <Dropdown
                                    className="mx-4"
                                    header='Year'
                                    value={year}
                                    align="left">
                                    {years.map( ( y, idx ) => (<DropdownItem
                                        id="year"
                                        key={`year_${idx}`}
                                        value={y}
                                        onClick={handleChange}>
                                        {y}
                                    </DropdownItem>) )}
                                </Dropdown>
                            </div>
                        </div>

                        <div className="sm:col-span-4">
                            <label htmlFor="document"
                                   className="block text-sm font-bold leading-6 text-gray-900">
                                Legislation
                            </label>
                            <div className="mt-2 ml-4">
                                <ComboboxMenu
                                    idx={documents.findIndex( doc => doc.uid === document_uid )}
                                    items={documents.map( doc => ({
                                        name: doc.code,
                                        desc: doc.title.substring( 0, 32 )
                                    }) )}
                                    onChange={idx => handleDocumentChange( documents[idx].uid )}
                                />
                            </div>
                        </div>

                        <div className="sm:col-span-4">
                            <label htmlFor="title"
                                   className="inline-flex text-sm font-bold leading-6 text-gray-900">
                                Title

                                {pdf_url && <ArrowTopRightOnSquareIcon
                                    className="h-5 w-5 ml-2 text-indigo-600 cursor-pointer shrink-0"
                                    onClick={() => window.open( pdf_url, '_blank' )}
                                />}
                            </label>
                            <div className="mt-2 ml-4">
                                {document.title}
                            </div>
                        </div>

                        <div className="sm:col-span-6">
                            <label htmlFor="title"
                                   className="inline-flex text-sm font-bold leading-6 text-gray-900">
                                Live Prompts
                            </label>
                            <div className="mt-2 pb-2">
                                <ChatConversation
                                    chat={chat}
                                    rating={convertRating(rating)}
                                    is_rating={true}
                                    showToast={showToast}
                                />
                            </div>
                        </div>

                        <div className="sm:col-span-6">
                            <ChatQuery
                                readOnly={readOnly}
                                onQuestion={handleQuestion}
                                showToast={showToast}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>)
}