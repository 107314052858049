import React, { Fragment, useEffect, useState } from 'react'
import {
    ArrowDownIcon,
    ArrowUpIcon,
    ChevronDownIcon,
    XMarkIcon,
    MinusIcon,
} from '@heroicons/react/20/solid'
import * as Util from "../helpers/util.js";
import {
    convertRating,
    PillChange,
    PillType, simpleValue
} from "../components/pill_change";
import { RatingOverlap } from "../helpers/consts";
import {
    Bars2Icon, ChatBubbleLeftEllipsisIcon, HashtagIcon, CheckCircleIcon,
} from "@heroicons/react/24/outline";
import { RateLegislation } from "../components/rate_legislation";
import { Spinner } from "../components/spinner";
import { SummaryRating } from "../components/summary_rating";
import { Tooltip } from "../components/tooltip";

export const LegislationRatingTab = (props) => {
    const { policy_uid, policies, document, contents, content_idx, updating_prompt, updated_policy } = props
    const { onPolicyChange, onRatePolicy, onRateClaim, onRatePrompt, onUserRating, showToast } = props

    const [state, setState] = useState({
        policy: { uid: null, pillars: [] },
        ratings: [],
        assessments: [],
        stats: [
            { name: 'Affirming', overlap: RatingOverlap.SUPPORTED, stat: 0, value: 0, isGood: x => (x >= 0) },
            { name: 'Opposing', overlap: RatingOverlap.AGAINST, stat: 0, value: 0, isGood: x => (x >= 0) },
        ],
        expanded: {},
    })
    const { policy, ratings, assessments, stats, expanded } = state

    //Non-relevance claims
    const policyUids = (policy) => {
        const claim_uids = {}
        const prompt_uids = {}
        const relevance_uids = {}
        policy.pillars.forEach( pillar => {
            pillar.claims.forEach( claim => {
                claim_uids[claim.uid] = true
                if ( claim.relevance ) {
                    relevance_uids[claim.uid] = true
                }

                claim.prompts.forEach( prompt => {
                    prompt_uids[prompt.uid] = true
                })
            })
        })

        return [claim_uids, relevance_uids, prompt_uids]
    }
    const [claim_uids, relevance_uids, prompt_uids] = policyUids( policy )
    const content = (content_idx >= 0 && content_idx < contents.length)? contents[content_idx]: {uid: null}
    const content_ratings = ratings.filter( x => x.prompt_uid in prompt_uids && x.content_uid === content.uid)
    const assessment = assessments.find( x => x.policy_uid === policy.uid && x.content_uid === content.uid ) || { assessment_claims: [] }

    useEffect( () => {
        updatePolicy( policy_uid, true )
    }, [policy_uid, document, updating_prompt] );

    //Update a new rating
    useEffect( () => {
        const { rating } = updated_policy
        if ( rating === null || rating === undefined ) {
            return
        }

        const ratings = [...state.ratings]
        const idx = ratings.findIndex( x => x.prompt_uid === rating.prompt_uid && x.content_uid === rating.content_uid )
        if ( idx >= 0 ) {
            ratings.splice( idx, 1, rating )
        }
        else {
            ratings.push( rating )
        }

        //Update my rating
        setState( prev => ({...prev,
            ratings
        }))

    }, [updated_policy] );

    const updatePolicy = ( policy_uid, force ) => {
        if (policy_uid === null || policy_uid === undefined || policy_uid === "" ||
            document === null || document === undefined || !('uid' in document) || document.uid === "" ||
            (policy.uid === policy_uid && !force) ) {
            return
        }

        //Pull my rating info
        Util.fetch_js('/api/document/rating/', {document_uid: document.uid, policy_uid},
            js => {
                setState( prev => ({...prev,
                    policy: js.policy,
                    ratings: js.ratings.reverse(), //We want to find the newest first
                    assessments: js.assessments.reverse(), //We want to find the newest first
                }))
            }, showToast)

        //Update the policy way back in the base system
        if ( props.policy_uid !== policy_uid ) {
            onPolicyChange({target: {id: 'policy_uid', name: 'policy_uid', value: policy_uid}})
        }
    }

    const handleExpand = (uid) => {
        const expanded = {...state.expanded}

        expanded[uid] = (uid in expanded)? !expanded[uid] : true
        setState(prev => ({...prev, expanded}))
    }

    const calcStats = ( stats, content_ratings ) => {
        return stats.map( x => {
            const stat = {...x}
            if ( 'stat' in stat ) {
                stat.stat = 0
            }
            if ( 'value' in stat ) {
                stat.value = 0 //Rating
            }

            if ( stat.name === "User Rating" ) {
                return { ...stat, ...simpleValue(document.user_rating) }
            }

            content_ratings.filter(r => !(r.claim_uid in relevance_uids)).forEach( r => {
                const rating = safeRating( r )
                if ( stat.overlap !== rating.overlap ) {
                    return
                }

                //Count the match
                if ( 'stat' in stat ) {
                    stat.stat++
                }

                //Supported
                if ( stat.overlap === RatingOverlap.SUPPORTED ) {
                    stat.value += rating.rating
                }
                //Against
                else if ( stat.overlap === RatingOverlap.AGAINST ) {
                    stat.value -= rating.rating
                }
                //N/A
                else if ( stat.overlap === RatingOverlap.DOES_NOT_APPLY ) {
                }
            })

                /*
            //Calculate the average
            if ( 'value' in stat && stat.stat > 0 ) {
                //stat.value /= stat.stat
            }
                 */

            return stat
        })
    }

    const safeRating = ( rating ) => {
        if ( rating === null || rating === undefined ) {
            return null
        }
        //Handle overlap
        let overlap = rating.overlap
        /*
        if ( rating.inverted ) {
            if ( overlap === RatingOverlap.SUPPORTED ) {
                overlap = RatingOverlap.AGAINST
            }
            else if ( overlap === RatingOverlap.AGAINST ) {
                overlap = RatingOverlap.SUPPORTED
            }
        }
         */

        return {...rating, overlap}
    }

    //Calculate the 3 pill summary for one pillar
    const pillarSummary = ( ass_claims, pillar ) => {
        const supported = {
            value: 0,
            //change: (<HashtagIcon className="-ml-1 mr-0.5 h-3 w-3 flex-shrink-0 self-center text-green-500"/>),
            type: PillType.GOOD
        }
        const against = {
            value: 0,
            //change: (<HashtagIcon className="-ml-1 mr-0.5 h-3 w-3 flex-shrink-0 self-center text-red-500"/>),
            type: PillType.BAD,
        }
        const does_not_apply = {
            value: 0,
            change: (<HashtagIcon className="-ml-1 mr-0.5 h-3 w-3 flex-shrink-0 self-center text-gray-500"/>),
            type: PillType.NA
        }

        const applied = {}
        ass_claims.forEach(ac => {
            if (ac.status.toLowerCase() === "applied") {
                applied[ac.claim_uid] = true
            }
        })

        pillar.claims.filter(c => !c.relevance && c.uid in applied ).forEach( claim => {
            if ( claim.rating > 0 ) {
                supported.value = Math.max( claim.rating, supported.value )
                does_not_apply.value++
            }
            else if ( claim.rating < 0 ) {
                against.value = Math.min( claim.rating, against.value )
                does_not_apply.value++
            }
        })

        /*
        const claims = pillar.claims //[pillar].concat( pillar.claims )
        claims.filter(c => !c.relevance && c.uid in claim_uids ).forEach( claim => {
            claim.prompts.forEach( prompt => {
                const rating = safeRating( content_ratings.find( x => x.prompt_uid === prompt.uid ) )
                if ( rating === null ) {
                    //does_not_apply.value += 1 // Need a better way to show unrated
                    return
                }

                //Count the overlaps
                if ( rating.overlap === RatingOverlap.SUPPORTED ) {
                    supported.value += 1
                }
                else if ( rating.overlap === RatingOverlap.AGAINST ) {
                    against.value += 1
                }
                else if ( rating.overlap === RatingOverlap.DOES_NOT_APPLY ) {
                    does_not_apply.value += 1
                }
                else {
                    console.error( `Unknown rating overlap: ${rating.overlap}` )
                }
            })
        })
         */

        return [supported, against, does_not_apply]
    }

    const mapClaims = ( claims, ass ) => {
        //Map the required data into my claims so its easy to visualize
        return claims.map( c => {
            const claim = {
                ...c,
                assessment: ass.assessment_claims.find( x => x.claim_uid === c.uid ),
            }

            claim.applied = claim.assessment !== null && claim.assessment !== undefined && claim.assessment.status === "Applied"
            claim.prompts = c.prompts.map( prompt => {
                const rating = safeRating(content_ratings.find(x => x.prompt_uid === prompt.uid))
                return {
                    ...prompt,
                    relevance: claim.relevance,
                    rating,
                    pill: convertRating(rating),
                }
            })

            return claim
        })
    }

    const promptMatchCss = (prompt) => {
        if ( prompt.rating === null || prompt.rating.overlap === RatingOverlap.DOES_NOT_APPLY ) {
            return "text-gray-400 bg-gray-0 ring-gray-50"
        }
        else if ( prompt.rating.overlap === RatingOverlap.SUPPORTED && !prompt.inverted ||
            prompt.rating.overlap === RatingOverlap.AGAINST && prompt.inverted ) {
            return "text-indigo-500 bg-indigo-50 ring-indigo-200"
        }
        else {
            return "text-gray-500 bg-gray-50 ring-gray-200"
        }
        /*
        if ( prompt.rating === null || prompt.rating.overlap === RatingOverlap.DOES_NOT_APPLY ) {
            return "text-gray-500 bg-gray-50 ring-gray-50"
        }
        else if ( prompt.rating.overlap === RatingOverlap.SUPPORTED && !prompt.inverted ||
                  prompt.rating.overlap === RatingOverlap.AGAINST && prompt.inverted ) {
            return "text-green-600 bg-green-50 ring-green-600"
        }
        else {
            return "text-red-600 bg-red-50 ring-red-600"
        }
         */
    }

    const promptMatch = (prompt, good, bad, na) => {
        if ( prompt.rating === null || prompt.rating.overlap === RatingOverlap.DOES_NOT_APPLY ) {
            return na
        }
        else if ( prompt.rating.overlap === RatingOverlap.SUPPORTED && !prompt.inverted ||
            prompt.rating.overlap === RatingOverlap.AGAINST && prompt.inverted ) {
            return good
        }
        else {
            return bad
        }
    }

    const promptMatchType = (prompt) => {
        if ( prompt.rating === null || prompt.rating.overlap === RatingOverlap.DOES_NOT_APPLY ) {
            return PillType.NA
        }
        else if ( prompt.rating.overlap === RatingOverlap.SUPPORTED && !prompt.inverted ||
            prompt.rating.overlap === RatingOverlap.AGAINST && prompt.inverted ) {
            return PillType.GOOD
        }
        else {
            return PillType.BAD
        }
    }

    const calcAssessmentStats = ( ass_claims, document ) => {
        let pos_rating = 0
        let neg_rating = 0
        let pos_count = 0
        let neg_count = 0

        ass_claims.forEach(x => {
            if ( x.status.toLowerCase() === "applied" ) {
                if ( x.claim_rating > 0 ) {
                    pos_rating = Math.max( pos_rating, x.claim_rating )
                    pos_count++
                }
                else if ( x.claim_rating < 0 ) {
                    neg_rating = Math.min( neg_rating, x.claim_rating )
                    neg_count++
                }
            }
        })

        return [
            { name: 'Positive Claims', overlap: RatingOverlap.SUPPORTED, stat: pos_count, value: null, isGood: x => (x >= 0) },
            { name: 'Negative Claims', overlap: RatingOverlap.AGAINST, stat: neg_count, value: null, isGood: x => (x >= 0) },
        ]
    }

    return (
        <div className="px-4 py-5 sm:p-6 sm:pb-12">
            <SummaryRating
                stats={calcAssessmentStats( assessment.assessment_claims )}
                policies={policies}
                policy_uid={policy_uid}
                onPolicy={onPolicyChange}
            />

            <RateLegislation
                content_uid={content.uid}
                policy_uid={policy.uid}
                policies={policies}
                ai_rating={assessment}
                user_rating={document.user_rating}
                onPolicyChange={updatePolicy}
                onRatePolicy={onRatePolicy}
                onUserRating={onUserRating}
                showToast={showToast}
            />

            {policy.pillars.map( ( pillar, p_idx ) => (
                <div key={`pillar_${p_idx}`} className="mt-12 flex flex-col w-full rounded-lg bg-white shadow">
                    <div className="cursor-pointer px-6 py-2 w-full flex flex-row justify-between"
                         onClick={e => handleExpand( pillar.uid )}>
                        <div className="inline-flex text-base font-semibold text-gray-900">
                            {pillar.name}
                            <ChevronDownIcon
                                className="ml-2 h-6 w-6 text-indigo-600"
                                aria-hidden="true"
                            />
                        </div>
                        <div className="inline-flex text-sm text-gray-500">
                            {pillar.claims.find( x => x.uid === updating_prompt ) &&
                                <Spinner
                                    className="ml-2 w-6 h-6 text-indigo-600"
                                />
                            }
                            {pillarSummary( assessment.assessment_claims, pillar ).map( ( pill, idx ) => {
                                if ( pill.value === 0 && pill.type !== PillType.NA) {
                                    return null
                                }

                                return (<PillChange
                                    key={`pillar_pill_${idx}`}
                                    className="ml-1"
                                    value={pill.value}
                                    change={pill.change}
                                    type={pill.type}
                                />)
                            } )}
                        </div>
                    </div>

                    {mapClaims( pillar.claims, assessment ).map( ( claim, c_idx ) => (
                        <div key={`${p_idx}_claim_${c_idx}`}
                             className={Util.classNames(
                                 "pl-7 pr-6 w-full flex flex-col",
                                 'border-t border-gray-200',
                                 "transform transition-all duration-75 ease-in-out",
                                 (pillar.uid in expanded && expanded[pillar.uid] ? 'opacity-100' : 'opacity-0 h-0'),
                             )}>

                            {expanded[pillar.uid] &&
                            <div className="inline-flex items-center mt-2 justify-between">
                                {claim.relevance &&
                                <div className={
                                    Util.classNames(claim.applied? "font-semibold": "text-gray-500",
                                                    "-ml-2 inline-flex items-center")}>
                                    Relevance Test
                                    {claim.applied &&
                                    <CheckCircleIcon className="ml-2 h-6 w-6 text-green-600"/>
                                    }
                                    {claim.assessment !== undefined && claim.assessment.status === "Failed" &&
                                    <XMarkIcon className="ml-2 h-6 w-6 text-red-600"/>
                                    }
                                    {(claim.assessment === undefined || claim.assessment.status == "N/A") &&
                                    <MinusIcon className="ml-2 h-6 w-6 text-gray-400"/>
                                    }
                                </div>
                                }
                                {!claim.relevance &&
                                    <div className={
                                        Util.classNames(claim.applied? "font-semibold": "text-gray-500",
                                            "-ml-2 inline-flex items-center")}>
                                        Claim
                                        {false && claim.assessment !== undefined && claim.assessment.status === "Failed" &&
                                            <XMarkIcon className="ml-2 h-6 w-6 text-red-600"/>
                                        }
                                        {false && (claim.assessment === undefined || claim.assessment.status == "N/A") &&
                                            <MinusIcon className="ml-2 h-6 w-6 text-gray-400"/>
                                        }
                                    </div>
                                }

                                {claim.relevance &&
                                <div className="inline-flex items-center">
                                    Assess this pillar if
                                    <p className="font-semibold mx-1">
                                        {claim.match_type}
                                    </p>
                                    matched
                                </div>
                                }

                                {!claim.relevance && claim.applied &&
                                <div className="inline-flex items-center">
                                    <PillChange
                                        className={Util.classNames(
                                            "mx-2 cursor-pointer",
                                            (claim.rating > 0) ? 'ring-1 ring-green-200' : '',
                                            (claim.rating < 0) ? 'ring-1 ring-red-200' : '',
                                        )}
                                        {...simpleValue(claim.rating)}
                                        onClick={() => setState(prev => ({...prev, show_user_rating: true}))}
                                    />
                                    because
                                    <p className="font-semibold mx-1">
                                        {claim.match_type}
                                    </p>
                                    matched
                                </div>
                                }

                                {!claim.relevance && !claim.applied &&
                                    <div className="inline-flex items-center text-gray-400">
                                        <p className={Util.classNames("font-semibold mx-1",
                                                        (claim.rating > 0)? 'text-green-400': '',
                                                        (claim.rating < 0)? 'text-red-400': '',
                                        )}>
                                            {claim.rating > 0 ? `+${claim.rating}`: claim.rating}
                                        </p>
                                        if
                                        <p className="font-semibold mx-1">
                                            {claim.match_type}
                                        </p>
                                        match
                                    </div>
                                }
                            </div>
                            }

                            {expanded[pillar.uid] && claim.prompts.map( ( prompt, pr_idx ) => (<>
                                <div key={`${claim.uid}_prompt_${prompt.uid}`}
                                    className={Util.classNames(
                                    "w-full flex flex-row justify-between",
                                    (pillar.uid in expanded && expanded[pillar.uid] ? 'py-2' : 'py-0'),
                                )}>
                                    <div className="inline-flex items-center text-base text-gray-900 cursor-pointer"
                                         onClick={e => (prompt.rating !== null)? handleExpand( prompt.uid ): onRatePrompt( prompt.uid )}>
                                        {prompt.rating !== null && prompt.rating.response.length > 0 &&
                                            <ChatBubbleLeftEllipsisIcon
                                                className="cursor-pointer mr-2 h-6 w-6 text-indigo-600 flex-shrink-0"
                                                aria-hidden="true"
                                            />}
                                        <p className={promptMatch(prompt, "font-semibold", "text-gray-500", "text-gray-500")}>
                                            {prompt.query}
                                        </p>
                                    </div>

                                    {/*Not expanded, simplified view*/}
                                    {(!(prompt.uid in expanded) || !expanded[prompt.uid] || prompt.rating === null || prompt.rating.response.length === 0) &&
                                        <div className="inline-flex items-center text-sm text-gray-800 cursor-pointer"
                                             onClick={() => onRatePrompt( prompt.uid )}>

                                            <div className="w-10 pt-1">
                                                {prompt.pill !== null && !(prompt.uid in updating_prompt) &&
                                                    promptMatch(prompt, <p className="-ml-4">Matched</p>, <MinusIcon className="w-5 h-6 text-gray-400"/>, <MinusIcon className="w-5 h-6 text-gray-400"/>)
                                                }
                                                {prompt.rating === null && !(prompt.uid in updating_prompt) &&
                                                    <XMarkIcon
                                                        className="ml-1 h-6 w-6 text-indigo-600"
                                                        aria-hidden="true"
                                                    />}
                                                {prompt.uid in updating_prompt &&
                                                    <Spinner
                                                        className="ml-1 w-6 h-6 text-indigo-600"
                                                    />
                                                }
                                            </div>
                                        </div>
                                    }

                                    {/*Expanded, detailed view*/}
                                    {prompt.uid in expanded && expanded[prompt.uid] && prompt.rating !== null && prompt.rating.response.length > 0 &&
                                    <div className="inline-flex items-center text-sm text-gray-500 cursor-pointer"
                                         onClick={() => onRatePrompt( prompt.uid )}>
                                        {!prompt.inverted &&
                                            <div className={Util.classNames("ring-1 rounded-full", promptMatch(prompt, "bg-indigo-50 ring-indigo-200", "bg-gray-50 ring-gray-200", "bg-gray-0 ring-gray-50"))}>
                                                <ArrowUpIcon className={Util.classNames("m-1 h-5 w-5 shrink-0", promptMatch(prompt, "text-indigo-500", "text-gray-500", "text-gray-400"))}/>
                                                <Tooltip tooltip="Match if affirming"/>
                                            </div>
                                        }
                                        {prompt.inverted &&
                                            <div className={Util.classNames("ring-1 rounded-full", promptMatch(prompt, "bg-indigo-50 ring-indigo-200", "bg-gray-50 ring-gray-200", "bg-gray-0 ring-gray-50"))}>
                                                <ArrowDownIcon className={Util.classNames("m-1 h-5 w-5 shrink-0", promptMatch(prompt,"text-indigo-500", "text-gray-500", "text-gray-400"))}/>
                                                <Tooltip tooltip="Match if false"/>
                                            </div>
                                        }

                                        {promptMatch(prompt, <p className="ml-2">=</p>, <p className="ml-2">≠</p>, <p className="ml-2">=</p>)}

                                        {false && <Bars2Icon className="ml-1 h-3 w-3"/>}

                                        <div className="w-10 pt-1">
                                            {prompt.pill !== null && !(prompt.uid in updating_prompt) &&
                                                <PillChange
                                                    className={Util.classNames("ml-1", promptMatch(prompt, "ring-1 ring-indigo-200", "", ""))}
                                                    value={prompt.pill.value}
                                                    change={prompt.pill.change}
                                                    type={promptMatchType(prompt)}
                                                    good={"bg-indigo-50 text-indigo-500"}
                                                    bad={"bg-gray-50 text-gray-500"}
                                                    na={"bg-gray-0 text-gray-400"}
                                                />}
                                            {prompt.rating === null && !(prompt.uid in updating_prompt) &&
                                                <XMarkIcon
                                                    className="ml-1 h-6 w-6 text-indigo-600"
                                                    aria-hidden="true"
                                                />}
                                            {prompt.uid in updating_prompt &&
                                                <Spinner
                                                    className="ml-1 w-6 h-6 text-indigo-600"
                                                    />
                                                }
                                        </div>
                                    </div>
                                    }
                                </div>
                                {prompt.uid in expanded && expanded[prompt.uid] && prompt.rating !== null && prompt.rating.response.length > 0 && (
                                    <p className="italic mb-2 ml-4 mr-8">{prompt.rating.response}</p>
                                )}
                            </>))}
                        </div>
                    ) )}
                </div>
            ) )}
        </div>
    )
}
