import {
    CheckCircleIcon,
    DocumentPlusIcon,
    DocumentDuplicateIcon,
    HandRaisedIcon,
} from '@heroicons/react/24/solid'
import {
    ChatBubbleLeftEllipsisIcon,
    QueueListIcon,
    ChatBubbleLeftRightIcon, ScaleIcon, XCircleIcon, ArrowsRightLeftIcon, UserPlusIcon, UserMinusIcon
} from '@heroicons/react/24/outline'
import {
    FaceFrownIcon,
    FaceSmileIcon,
    FireIcon,
    HandThumbUpIcon,
    HeartIcon,
    PaperClipIcon, UserIcon,
    XMarkIcon,
} from '@heroicons/react/20/solid'
import * as Util from "../helpers/util.js";


export const buildActivity = (logs, humans, use_collections=false) => {
    const human_lookup = {}
    humans.forEach( human => { human_lookup[human.uid] = human })

    /*
        (TYPE_ACTION_ENGROSSED, 'Engrossed'),
        (TYPE_ACTION_ENROLLED, 'Enrolled'),
        (TYPE_ACTION_OVERRIDE, 'Override'),
        (TYPE_ACTION_CHAPTERED, 'Chaptered'),
        (TYPE_ACTION_REPORT_PASS, 'Report Pass'),
        (TYPE_ACTION_REPORT_DNP, 'Report DNP'),
        (TYPE_ACTION_DRAFT, 'Draft'),
        (TYPE_ACTION_HISTORY, 'History'),

        (TYPE_UPDATE, 'Update'),
        (TYPE_CHAMBER_ACTIVITY, 'Chamber Activity'),
     */

    const codes = {}
    logs.forEach( x => {
        if ( 'document_code' in x ) {
            if ( x.document_code in codes ) {
                codes[x.document_code] += 1
            }
            else {
                codes[x.document_code] = 1
            }
        }
    })

    let collection = null

    const result = []
    logs.forEach( log => {
        const human = human_lookup[log.human_uid] || {
            name: '',
        }
        //const date = Util.epochToAgo( log.timestamp_on )
        const dt = Util.epochExpanded( log.timestamp_on )
        const dateTime = `${dt.year}-${dt.month}-${dt.day}T${dt.hour}:${dt.minute}`

        //Set the icon
        let icon = (<div className="h-1.5 w-1.5 rounded-full bg-gray-100 ring-1 ring-gray-300"/>)
        if ( log.type.toLowerCase() === 'created' || log.type.toLowerCase() === 'introduced') {
            icon = (<DocumentPlusIcon className="h-6 w-6 text-green-600" aria-hidden="true" />)
        }
        else if ( log.type.toLowerCase() === "new content" ) {
            icon = (<DocumentDuplicateIcon className="h-6 w-6 text-green-600" aria-hidden="true" />)
        }
        else if ( log.type.toLowerCase() === "vote" ) {
            icon = (<HandRaisedIcon className="h-6 w-6 text-orange-600" aria-hidden="true" />)
        }
        else if ( log.type.toLowerCase() === "comment" ) {
            icon = (<ChatBubbleLeftEllipsisIcon className="h-6 w-6 text-indigo-600" aria-hidden="true" />)
        }
        else if ( log.type.toLowerCase() === "rating" ) {
            icon = (<QueueListIcon className="h-6 w-6 text-gray-600" aria-hidden="true" />)
        }
        else if ( log.type.toLowerCase() === "passed" ) {
            icon = (<CheckCircleIcon className="h-6 w-6 text-green-600" aria-hidden="true" />)
        }
        else if ( log.type.toLowerCase() === "failed" || log.type.toLowerCase() === "vetoed" ) {
            icon = (<XCircleIcon className="h-6 w-6 text-red-600" aria-hidden="true" />)
        }
        else if ( log.type.toLowerCase() === "chat" ) {
            icon = (<ChatBubbleLeftRightIcon className="h-6 w-6 text-indigo-600" aria-hidden="true" />)
        }
        else if ( log.type.toLowerCase() === "refer" ) {
            icon = (<ArrowsRightLeftIcon className="h-6 w-6 text-indigo-600" aria-hidden="true" />)
        }
        else if ( log.type.toLowerCase() === "history" || log.type.toLowerCase() === "report pass" ) {
            const msg = log.message.toLowerCase()
            if ( /sponsor/.test(msg) ) {
                if (/added/.test(msg)) {
                    icon = (<UserPlusIcon className="h-6 w-6 text-green-600" aria-hidden="true" />)
                }
                else if (/removed/.test(msg)) {
                    icon = (<UserMinusIcon className="h-6 w-6 text-red-600" aria-hidden="true" />)
                }
                else {
                    icon = (<UserIcon className="h-6 w-6 text-gray-600" aria-hidden="true" />)
                }
            }
            else if ( /amendment/.test(msg) ) {
                icon = (<PaperClipIcon className="h-6 w-6 text-gray-600" aria-hidden="true" />)
            }
            else {
                icon = (<ScaleIcon className="h-6 w-6 text-indigo-600" aria-hidden="true" />)
            }
        }
        else {
            icon = (<ScaleIcon className="h-6 w-6 text-indigo-600" aria-hidden="true" />)
        }

        //All logs in one list
        if ( !use_collections ) {
            result.push({
                uid: log.uid,
                type: log.type.toLowerCase(),
                document_uid: log.document_uid,
                document_code: log.document_code,
                icon,
                human,
                date: log.date_on,
                dateTime,
                message: log.message
            })
        }

        //Group logs by document_code and date
        else {
            if ( collection !== null && (collection.document_code !== log.document_code || collection.date !== log.date_on) ) {
                result.push(collection)
                collection = null
            }

            if (collection === null) {
                collection = {
                    document_code: log.document_code,
                    document_uid: log.document_uid,
                    date: log.date_on,
                    human,
                    logs: [],
                }
            }

            collection.logs.push({
                uid: log.uid,
                type: log.type.toLowerCase(),
                icon,
                human,
                date: log.date_on,
                dateTime,
                message: log.message
            })
        }
    } )

    //Add the last collection
    if (collection !== null) {
        result.push(collection)
    }

    //Add the length since its impossible for the sub comps to know now
    if ( use_collections ) {
        result.forEach( x => {
            x.length = result.length
        })
    }

    return result
}