import React, { useState, useRef, useEffect } from "react"
import * as Util from "../helpers/util.js"
import { Link, useNavigate } from "react-router-dom";

import {
    ArrowDownIcon, ArrowLeftIcon, ArrowsUpDownIcon, ArrowUpIcon, TrashIcon
} from '@heroicons/react/20/solid'
import { Dropdown } from "../components/dropdown";
import {PillChange, PillType} from "../components/pill_change";
import { DocumentTextIcon, TagIcon } from "@heroicons/react/24/outline";
import {PlusIcon} from "@heroicons/react/24/solid";
import { EmptyList } from "../components/empty_list";
import { ConfirmModal } from "../modals/confirm_modal";
import {COLORS, PRIORITIES, CircleColor, ARROW_DIRECTION, TextColor} from "../helpers/consts";
import {PageTitle} from "../components/page_title";


export const Tags = (props) => {
    const {showToast} = props

    const [state, setState] = useState({
        loaded: false,
        tags: [],
        edit_tag: null,
        show_delete: null,
    })
    const { loaded, edit_tag, tags, show_delete } = state

    useEffect(() => {
        //Any government change, we need to update the tag
        Util.fetch_js('/api/tags/list/', {},
            js => {
                setState(prev => ({ ...prev,
                    loaded: true,
                    tags: js.tags,
                }))

            }, showToast)

    }, [])

    const handleCreate = () => {
        let count = 0
        while ( tags.findIndex( tag => tag.name === `new-tag${count}` ) >= 0 ) {
            count++
        }

        //Create a new tag
        Util.fetch_js( '/api/tags/create/', { name: `new-tag${count}` },
            js => {
                setState(prev => ({ ...prev,
                    tags: js.tags,
                }))
            }, showToast )
    }

    const handleDelete = (uid) => {
        setState(prev => ({ ...prev, show_delete: null }))

        if ( uid !== null ) {
            Util.fetch_js( '/api/tags/remove/', { tag_uids: [uid] },
                js => {
                    setState(prev => ({ ...prev,
                        tags: js.tags,
                    }))
                }, showToast )
        }
    }

    const handleStepPriority = (idx) => {
        const tags = [...state.tags]
        const tag = tags[idx]

        const pidx = PRIORITIES.findIndex( p => p === tag.priority.toLowerCase() )
        tag.priority = PRIORITIES[(pidx + 1) % PRIORITIES.length]

        Util.fetch_js( '/api/tags/modify/', { tag_uid: tag.uid, priority: tag.priority },
            js => {}, showToast )

        setState(prev => ({ ...prev, tags }))
    }

    const handleStepColor = (idx) => {
        const tags = [...state.tags]
        const tag = tags[idx]

        const cidx = COLORS.findIndex( p => p === tag.color.toLowerCase() )
        tag.color = COLORS[(cidx + 1) % COLORS.length]

        Util.fetch_js( '/api/tags/modify/', { tag_uid: tag.uid, color: tag.color },
            js => {}, showToast )

        setState(prev => ({ ...prev, tags }))
    }

    const handleChange = ( e ) => {
        let { name, value } = e.target
        if ( name === 'edit_tag' ) {
            if ( edit_tag === value ) {
                return
            }

            if ( edit_tag !== null ) {
                value = null
            }
        }

        setState(prev => ({ ...prev, [name]: value }))
    }

    const handleTagNameChange = ( idx, value ) => {
        const tags = [...state.tags]
        tags[idx].name = value.toLowerCase().replace(/^[0-9]*/g, '').replace(/[^a-zA-Z0-9]+/g, '-')
        setState(prev => ({ ...prev, tags }))
    }

    const handleKeyDownBlur = (e) => {
        if (e.key === 'Enter') {
            e.target.blur()
        }
    }

    const handleUpdate = ( idx ) => {
        //Update the tags
        const tag = tags[idx]
        Util.fetch_js( '/api/tags/modify/', { tag_uid: tag.uid, name: tag.name },
            js => {
                setState(prev => ({ ...prev,
                    tags: js.tags,
                }))
            }, showToast )

        //Clear the edit tag
        setState(prev => ({ ...prev,
            edit_tag: null
        }))
    }

    return (
        <div>
            <PageTitle
                title="Tags"
                subtitle="Create and manage your tags"
                button_text="Create Tag"
                button_icon={<PlusIcon className="-ml-0.5 mr-1.5 h-5 w-5" aria-hidden="true" />}
                onClick={handleCreate}
            />

            <div className="px-4 sm:px-6 lg:px-8">
                <div className="-mx-4 -my-2 sm:-mx-6 lg:-mx-8">
                    <div className="inline-block min-w-full py-2 align-middle">
                        <table className="min-w-full border-separate border-spacing-0">
                            <thead>
                            <tr>
                                <th scope="col"
                                    className="sticky top-0 z-10 border-b border-gray-300 bg-white bg-opacity-75 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:pl-6 lg:pl-8">
                                    Name
                                </th>
                                <th scope="col"
                                    className="sticky top-0 z-10 hidden border-b border-gray-300 bg-white bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:table-cell">
                                    Priority
                                </th>
                                <th scope="col"
                                    className="sticky top-0 z-10 border-b border-gray-300 bg-white bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter">
                                    Color
                                </th>
                                <th scope="col"
                                    className="sticky top-0 z-10 border-b border-gray-300 bg-white bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter">
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            {tags.map( ( tag, idx ) => (
                                <tr key={`tag_tr_${idx}`}>
                                    <td onClick={() => handleChange({target: {name: 'edit_tag', value: idx }})}
                                        className={Util.classNames(
                                        idx !== tags.length - 1 ? 'border-b border-gray-200' : '',
                                        'whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 lg:pl-8 cursor-pointer',
                                        (edit_tag !== idx ? TextColor(tag.color): '')
                                    )}>
                                        {edit_tag !== idx ? tag.name :
                                            <input
                                                type="text"
                                                name="name"
                                                id="name"
                                                className="block rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                placeholder="Tag name"
                                                value={tag.name}
                                                autoFocus
                                                onChange={e => handleTagNameChange( idx, e.target.value )}
                                                onKeyDown={handleKeyDownBlur}
                                                onBlur={() => handleUpdate( idx )}
                                            />}
                                    </td>
                                    <td className={Util.classNames( idx !== tags.length - 1 ? 'border-b border-gray-200' : '', 'whitespace-wrap hidden px-3 py-4 text-sm text-gray-500 sm:table-cell cursor-pointer' )}
                                        onClick={() => handleStepPriority( idx )}>
                                        {ARROW_DIRECTION[tag.priority.toLowerCase()]}
                                    </td>
                                    <td className={Util.classNames( idx !== tags.length - 1 ? 'border-b border-gray-200' : '', 'whitespace-nowrap px-3 py-4 text-sm text-gray-500 cursor-pointer' )}
                                        onClick={() => handleStepColor(idx)}>
                                        {CircleColor(tag.color)}
                                    </td>
                                    <td className={Util.classNames( idx !== tags.length - 1 ? 'border-b border-gray-200' : '', 'whitespace-nowrap px-3 py-4 text-sm text-gray-500' )}>
                                        <TrashIcon className="h-5 w-5 text-gray-400 hover:text-red-500 cursor-pointer"
                                                   aria-hidden="true"
                                                   onClick={() => setState(prev => ({ ...prev, show_delete: tag.uid }))}
                                        />
                                    </td>
                                </tr>) )}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

            {loaded && tags.length === 0 &&
                <EmptyList
                    icon={TagIcon}
                    name="tag"
                    onCreate={handleCreate}
                />
            }

            <ConfirmModal
                open={show_delete !== null}
                uid={show_delete}
                danger={true}
                title="Delete this tag?"
                message="Are you sure you want to delete this tag?"
                confirmBtn="Delete"
                onClose={handleDelete}
            />
        </div>
    )
}
