import React, {useEffect, useState} from 'react'
import * as Util from "../helpers/util.js";
import { PhotoIcon, UserCircleIcon } from '@heroicons/react/24/solid'
import { Dropdown, DropdownItem } from "../components/dropdown";
import {
    ARROW_DIRECTION,
    PRIORITIES
} from "../helpers/consts";
import { AutoFillTextarea } from "../components/auto_fill_textarea";
import { LegislationTags, } from "../components/legislation_tags";
import { Spinner } from "../components/spinner";


export const LegislationDetailTab = props => {
    const {document, content_idx, contents, sponsors, committee, referrals, roll_calls, showToast} = props
    const og_td = props.tenant_document || { title: '', priority: 'none', color: 'none' }
    const onChange = props.onChange || (() => {})

    const [state, setState] = useState({
        title: og_td.title,
        priority: og_td.priority,
        color: og_td.color,
    })
    const { title, priority, color } = state

    const content = (contents.length > 0 && content_idx < contents.length) ? contents[content_idx] : { ai_summary: '' }

    useEffect(() => {
        Util.fetch_js('/api/document/detail/', {document_uid: document.uid},
            js => {
                const {tenant_document} = js
                if ( tenant_document === null ) {
                    return
                }

                setState(prev => ({ ...prev,
                    title: tenant_document.title,
                    priority: tenant_document.priority.toLowerCase(),
                    color: tenant_document.color.toLowerCase(),
                }))
            }, showToast)

    }, [document])

    const handleChange = (e) => {
        console.log(e)
        const {name, value} = e.target
        const obj = { ...state, [name]: value }

        if ( name === 'priority' || name === 'color' ) {
            handleSave( obj )
        }
        setState(prev => ({ ...prev, [name]: value }))
    }

    const handleSave = ( payload ) => {
        if ( payload === undefined ) {
            payload = { title, priority, color }
        }

        //Update
        Util.fetch_js( '/api/document/modify/', { document_uid: document.uid, ...payload },
            js => {
                onChange( {target: { name: 'tenant_document', value: js.tenant_document}} )
                showToast( "Saved", "successful" )
            }, showToast )
    }

    const headerValue = header => {
        if ( header === 'priority' ) {
            const pri = priority || 'none'
            return (
                <>
                    {ARROW_DIRECTION[pri.toLowerCase()]}
                    <span className="ml-1">{Util.capitalize(pri)}</span>
                </>
            )
        }
        /*
        if ( header === 'color' ) {
            return (
                <>
                    {COLOR_LOOKUP[color.toLowerCase()]}
                    <span className="ml-1">{Util.capitalize(color)}</span>
                </>
            )
        }
         */
    }

    return (
        <div className="px-4 py-5 sm:p-6 flex flex-col items-center">
            <form className="w-full">
                <div className="space-y-12 md:mx-20">
                    <div className="pb-12">
                        <div
                            className="mt-5 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            <div className="sm:col-span-4">
                                <label htmlFor="title"
                                       className="block text-sm font-semibold leading-6 text-gray-900">
                                    Title
                                </label>
                                <div className="mt-2">
                                    <input
                                        id="title"
                                        name="title"
                                        type="title"
                                        autoComplete="title"
                                        value={title}
                                        placeholder={document.title}
                                        className="block w-full rounded-md border-0 pl-3 pr-1 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                        onChange={handleChange}
                                        onBlur={() => handleSave()}
                                    />
                                </div>
                            </div>

                            {/*
                            <div
                                className="hidden sm:block col-start-6 col-span-1">
                                <label htmlFor="priority"
                                       className="block text-sm font-medium leading-6 text-gray-900">
                                    Priority
                                </label>
                                <div className="mt-2">
                                    <Dropdown
                                        header="Priority"
                                        value={headerValue( 'priority' )}
                                        align="left">
                                        {PRIORITIES.map( ( item, idx ) => (
                                            <DropdownItem
                                                key={`pri_${item}_${idx}`}
                                                id="priority"
                                                name="priority"
                                                value={item}
                                                active={priority === item}
                                                onClick={handleChange}>
                                                {ARROW_DIRECTION[item.toLowerCase()]}
                                                <span className="ml-1">
                                                        {Util.capitalize( item )}
                                                    </span>
                                            </DropdownItem>) )}
                                    </Dropdown>
                                </div>
                            </div>
                            */}

                            <div className="col-span-full">
                                <h1 className="text-sm font-semibold text-gray-900 pb-4">
                                    AI Summary
                                </h1>
                                {content.ai_summary === "" && <Spinner
                                    className="w-6 h-6 text-indigo-600"/>}
                                {content.ai_summary !== "" &&
                                    <p className="ml-2 max-w-2xl text-sm text-gray-700">
                                        {content.ai_summary?.split( "\n" ).map( ( line, idx ) =>
                                            <span
                                                key={`ai_summary_${idx}`}>{line}<br/></span> )}
                                    </p>
                                }
                            </div>

                            <div className="col-span-full">
                                <h1 className="text-sm font-semibold text-gray-900 pb-4">
                                    Summary
                                </h1>
                                <p className="ml-2 max-w-2xl text-sm text-gray-700">
                                    {document.summary}
                                </p>
                            </div>

                            {/*
                            <div
                                className="sm:hidden col-span-full grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                                <div className="sm:col-span-3">
                                    <label htmlFor="priority"
                                           className="block text-sm font-medium leading-6 text-gray-900">
                                        Priority
                                    </label>
                                    <div className="mt-2">
                                        <Dropdown
                                            header="Priority"
                                            value={headerValue( 'priority' )}
                                            align="left">
                                            {PRIORITIES.map( ( item, idx ) => (
                                                <DropdownItem
                                                    key={`pri_${item}_${idx}`}
                                                    id="priority"
                                                    name="priority"
                                                    value={item}
                                                    active={priority === item}
                                                    onClick={handleChange}>
                                                    {ARROW_DIRECTION[item.toLowerCase()]}
                                                    <span className="ml-1">
                                                        {Util.capitalize( item )}
                                                    </span>
                                                </DropdownItem>) )}
                                        </Dropdown>
                                    </div>
                                </div>
                            </div>
                            */}
                        </div>

                        <LegislationTags
                            document_uid={document.uid}
                            className="mt-6"
                            showToast={showToast}
                        />

                        <div
                            className="mt-5 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            <div className="sm:col-span-4">
                                <label htmlFor="title"
                                       className="block text-sm font-medium leading-6 text-gray-900">
                                    Sponsors
                                </label>
                                <div className="mt-2">
                                    {sponsors.map( ( sponsor, idx ) => (
                                        <div key={`sponsor_${idx}`}
                                             className="flex items-center space-x-2">
                                            <UserCircleIcon
                                                className="h-6 w-6 text-gray-400"/>
                                            <span
                                                className="text-sm text-gray-900">
                                                {sponsor.name}
                                            </span>
                                        </div>) )}
                                </div>
                            </div>

                            <div className="sm:col-span-4">
                                <label htmlFor="title"
                                       className="block text-sm font-medium leading-6 text-gray-900">
                                    Referrals
                                </label>
                                <div className="mt-2">
                                    {referrals.map( ( referral, idx ) => (
                                        <div key={`referral_${idx}`}
                                             className="flex items-center space-x-2">
                                            <PhotoIcon
                                                className="h-6 w-6 text-gray-400"/>
                                            <span
                                                className="text-sm text-gray-900">
                                                {referral.name}
                                            </span>
                                        </div>) )}
                                </div>
                            </div>

                            {/*}
                            <div className="sm:col-span-4">
                                <label htmlFor="title"
                                       className="block text-sm font-medium leading-6 text-gray-900">
                                    Committee
                                </label>
                                <div className="mt-2">
                                    <div className="flex items-center space-x-2">
                                        <PhotoIcon className="h-6 w-6 text-gray-400"/>
                                        <span className="text-sm text-gray-900">
                                            {committee && committee.name}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            */}

                            <div className="sm:col-span-4">
                                <label htmlFor="title"
                                       className="block text-sm font-medium leading-6 text-gray-900">
                                    Roll Calls
                                </label>
                                <div className="mt-2">
                                    {roll_calls.map( ( roll_call, idx ) => (
                                        <div key={`roll_call_${idx}`}
                                             className="flex items-center space-x-2">
                                            <PhotoIcon
                                                className="h-6 w-6 text-gray-400"/>
                                            <span
                                                className="text-sm text-gray-900">
                                                {roll_call.desc}
                                            </span>
                                            <span className="text-sm text-gray-900">
                                                Yea {roll_call.yeas}
                                            </span>
                                            <span className="text-sm text-gray-900">
                                                Nay {roll_call.nays}
                                            </span>
                                            <span className="text-sm text-gray-900">
                                                Present {roll_call.abstains}
                                            </span>
                                            <span className="text-sm text-gray-900">
                                                Absent {roll_call.absents}
                                            </span>
                                        </div>) )}
                                </div>
                            </div>
                        </div>

                        {/*
                        <h2 className="mt-6 text-base font-semibold leading-7 text-gray-900">
                            Bill Text
                        </h2>

                        <div className="mt-6 col-span-full">
                            <div className="mt-2">
                                <AutoFillTextarea
                                    id="summary"
                                    name="summary"
                                    rows={40}
                                    marginTop={200}
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    value={document_text}
                                    readOnly
                                />
                            </div>
                        </div>
                        */}
                    </div>
                </div>
            </form>
        </div>)
}
