import * as Util from "../helpers/util.js"
import { classNames, sendWS } from "../helpers/util.js";

import React, { Fragment, useState } from 'react'
import {
    CheckCircleIcon,
    ChevronDoubleRightIcon,
    EllipsisHorizontalIcon, UserCircleIcon
} from '@heroicons/react/24/solid'
import {
    FaceFrownIcon,
    FaceSmileIcon,
    FireIcon,
    HandThumbUpIcon,
    HeartIcon,
    PaperClipIcon,
    XMarkIcon,
} from '@heroicons/react/20/solid'
import { Listbox, Switch, Transition } from '@headlessui/react'
import {Link} from "react-router-dom";
import { useStore } from "../store";
import { PillChange, PillType } from "../components/pill_change";
import { EmptyList } from "../components/empty_list";
import {
    ChatBubbleLeftIcon, DocumentMinusIcon, PencilSquareIcon, TagIcon
} from "@heroicons/react/24/outline";
import { ChatQuery } from "../components/chat_query";
import { ChatConversation } from "../components/chat_conversation";
import { Spinner } from "../components/spinner";


export const LegislationText = (props) => {
    const {document, content_idx, contents, showToast} = props

    const [show_html, setShowHtml] = useState( true )

    const {usr_info} = useStore( x => x)

    const handleToggle = checked => {
        showToast(`Showing ${checked ? "HTML" : "raw text"} version`, "success")
        setShowHtml(checked)
    }

    if (contents.length === 0 || content_idx >= contents.length ) {
        return (
            <div className="flex justify-center items-center h-64">
                <Spinner
                    className="ml-2 w-8 h-8 text-indigo-600"
                />
            </div>
        )
    }

    const is_empty = (show_html? contents[content_idx].html_body : contents[content_idx].text_body) === ""

    return (
        <div>
            <div className="w-full inline-flex justify-end mt-4 pr-4 items-center">
                <div className="text-sm font-semibold text-gray-600 mr-3">
                    {show_html? "HTML" : "Raw text"}
                </div>
                <Switch
                    checked={show_html}
                    onChange={handleToggle}
                    className={classNames(
                        show_html ? 'bg-indigo-600' : 'bg-gray-200',
                        'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2'
                    )}>
                    <span className="sr-only">Txt/Html</span>
                    <span aria-hidden="true"
                        className={classNames(
                            show_html ? 'translate-x-5' : 'translate-x-0',
                            'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
                        )}
                    />
                </Switch>
            </div>
            {show_html &&
            <div className="py-0 px-8 pb-20 flex flex-col items-center"
                 dangerouslySetInnerHTML={{__html: contents[content_idx].html_body}}>
            </div>
            }
            {!show_html &&
                <pre className="py-0 px-8 pb-20 whitespace-pre-wrap">
                    {contents[content_idx].text_body}
                </pre>
            }
            {is_empty &&
                <div className="pb-44">
                    <EmptyList
                        icon={DocumentMinusIcon}
                        title="Missing content"
                        description="This document is missing content."
                    />
                </div>
            }
        </div>
    )
}
