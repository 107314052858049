import React, {useEffect, useState} from 'react'
import {
    ArrowDownIcon,
    ArrowLeftIcon,
    ArrowUpIcon,
    CheckIcon,
    ChevronUpDownIcon,
    TrashIcon
} from '@heroicons/react/20/solid'
import { Combobox } from '@headlessui/react'
import * as Util from "../helpers/util.js";
import {EmptyList} from "../components/empty_list";
import {TagIcon} from "@heroicons/react/24/outline";
import {ARROW_DIRECTION, CircleColor} from "../helpers/consts";
import { useStore } from "../store";


export const LegislationTags = props => {
    const {document_uid, className, showToast} = props

    const { cache, setCache } = useStore(x => x)

    const [state, setState] = useState({
        loaded: false,
        tags: cache.tags || [],
        selected_tags: [],
        query: "",
    })
    const {loaded, tags, selected_tags, query} = state

    useEffect(() => {
        Util.fetch_js('/api/tags/list/', {},
            js => {
                const {tags} = js
                setState(prev => ({ ...prev,
                    tags,
                }))

                setCache({ tags })
            }, showToast)

        Util.fetch_js('/api/document/tags/', {document_uid},
            js => {
                const {tags} = js
                setState(prev => ({ ...prev,
                    loaded: true,
                    selected_tags: tags,
                }))
            }, showToast)
    }, [])

    const _filteredTags = (query === '') ? tags : tags.filter((tag) => { return tag.name.toLowerCase().includes(query.toLowerCase()) })
    const filteredTags = _filteredTags.filter((tag) => selected_tags.findIndex(x => x.uid === tag.uid) === -1)

    const handleAdd = (tag) => {
        Util.fetch_js('/api/document/tag_add/', {document_uid, tag_uid: tag.uid},
            js => {
                setState(prev => ({ ...prev,
                    selected_tags: js.tags,
                    query: "",
                }))
                showToast('Tag added', 'success')
            }, showToast)
    }

    const handleDelete = (tag_uid) => {
        Util.fetch_js('/api/document/tag_remove/', {document_uid, tag_uid},
            js => {
                setState(prev => ({ ...prev,
                    selected_tags: js.tags,
                }))
            }, showToast)
    }

    return (
        <div className={Util.classNames("py-5 flex flex-col items-center", className)}>
            <div className="w-full flex items-start">
                <Combobox as="div" onChange={handleAdd}>
                    <Combobox.Label className="block text-sm font-medium leading-6 text-gray-900">
                        Tags
                    </Combobox.Label>
                    <div className="relative mt-2">
                        <Combobox.Input
                            className="w-full rounded-md border-0 bg-white py-1.5 pl-3 pr-12 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            onChange={(event) => setState(prev => ({...prev, query: event.target.value}))}
                        />
                        <Combobox.Button
                            className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
                            <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true"/>
                        </Combobox.Button>

                        {filteredTags.length > 0 && (
                            <Combobox.Options
                                className="absolute z-20 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                {filteredTags.map((tag) => (
                                    <Combobox.Option
                                        key={tag.uid}
                                        value={tag}
                                        className={({active}) =>
                                            Util.classNames(
                                                'relative cursor-default select-none py-2 pl-3 pr-9',
                                                active ? 'bg-indigo-600 text-white' : 'text-gray-900'
                                            )
                                        }
                                    >
                                        {({active, selected}) => (
                                            <>
                                                <div className="flex items-center">
                                                    {ARROW_DIRECTION[tag.priority.toLowerCase()]}
                                                    <span className={Util.classNames('ml-3 truncate', selected && 'font-semibold')}>
                                                        {tag.name}
                                                        <span className="sr-only"> is {tag.online ? 'online' : 'offline'}</span>
                                                    </span>
                                                </div>

                                                <span
                                                    className={Util.classNames(
                                                        'absolute inset-y-0 right-0 flex items-center pr-4',
                                                        active ? 'text-white' : 'text-indigo-600'
                                                    )}>
                                                    {CircleColor(tag.color)}
                                                </span>
                                            </>
                                        )}
                                    </Combobox.Option>
                                ))}
                            </Combobox.Options>
                        )}
                    </div>
                </Combobox>
            </div>

            <div className="px-4 sm:px-6 lg:px-8 w-full">
                <div className="mt-8 flow-root">
                    <div className="-mx-4 -my-2 sm:-mx-6 lg:-mx-8">
                        <div className="inline-block min-w-full py-2 align-middle">
                            <table className="min-w-full border-separate border-spacing-0">
                                <thead>
                                <tr>
                                    <th scope="col"
                                        className="sticky top-0 border-b border-gray-300 bg-white bg-opacity-75 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:pl-6 lg:pl-8">
                                        Name
                                    </th>
                                    <th scope="col"
                                        className="sticky top-0 hidden border-b border-gray-300 bg-white bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:table-cell">
                                        Priority
                                    </th>
                                    <th scope="col"
                                        className="sticky top-0 border-b border-gray-300 bg-white bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter">
                                        Color
                                    </th>
                                    <th scope="col"
                                        className="sticky top-0 border-b border-gray-300 bg-white bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter">
                                    </th>
                                </tr>
                                </thead>
                                <tbody>
                                {selected_tags.map( ( tag, idx ) => (
                                    <tr key={`tag_tr_${idx}`}>
                                        <td className={Util.classNames(
                                                idx !== selected_tags.length - 1 ? 'border-b border-gray-200' : '',
                                                'whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 lg:pl-8',
                                            )}>
                                            {tag.name}
                                        </td>
                                        <td className={Util.classNames( idx !== selected_tags.length - 1 ? 'border-b border-gray-200' : '', 'whitespace-wrap hidden px-3 py-4 text-sm text-gray-500 sm:table-cell' )}>
                                            {ARROW_DIRECTION[tag.priority.toLowerCase()]}
                                        </td>
                                        <td className={Util.classNames( idx !== selected_tags.length - 1 ? 'border-b border-gray-200' : '', 'whitespace-nowrap px-3 py-4 text-sm text-gray-500' )}>
                                            {CircleColor(tag.color)}
                                        </td>
                                        <td className={Util.classNames( idx !== selected_tags.length - 1 ? 'border-b border-gray-200' : '', 'whitespace-nowrap px-3 py-4 text-sm text-gray-500' )}>
                                            <TrashIcon className="h-5 w-5 text-gray-400 hover:text-red-500 cursor-pointer"
                                                       aria-hidden="true"
                                                       onClick={() => handleDelete( tag.uid )}
                                            />
                                        </td>
                                    </tr>) )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>

            {loaded && selected_tags.length === 0 &&
                <EmptyList
                    icon={TagIcon}
                    name="tag"
                />
            }
        </div>
    )
}
