import React from "react";
import {PlusIcon} from "@heroicons/react/24/solid";
import {Dropdown, DropdownItem} from "./dropdown";
import {ARROW_DIRECTION, PRIORITIES} from "../helpers/consts";
import * as Util from "../helpers/util";
import { ButtonOptions } from "./button_options";


export const PageTitle = props => {
    const { title, subtitle, disabled,
            button_text, button_icon, button_options,
            combo_header, combo_text, combo_list,
            onClick } = props

    return (
        <div className="mx-auto px-4 max-w-7xl pb-12 flex flex-row justify-between">
            <div>
                <h1 className="text-2xl font-bold tracking-tight text-gray-900">
                    {title}
                </h1>
                <p className="mt-2 max-w-xl text-sm text-gray-700 overflow-ellipsis line-clamp-1">
                    {subtitle}
                </p>
            </div>

            <div>
                {button_text && !button_options &&
                    <button
                        type="button"
                        className="inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        disabled={disabled}
                        onClick={onClick}>
                        {button_icon}
                        <span className="hidden sm:block ml-1 line-clamp-1 text-clip whitespace-nowrap">
                            {button_text}
                        </span>
                        <span className="sm:hidden ml-1 line-clamp-1 text-clip whitespace-nowrap">
                            {button_text.split(" ")[0]}
                        </span>
                    </button>
                }

                {button_options &&
                <ButtonOptions
                    options={button_options}
                    onClick={onClick}>
                    {button_icon}
                    {button_text}
                </ButtonOptions>
                }

                {combo_header &&
                    <Dropdown
                        header={combo_header}
                        value={combo_text}
                        align="right">
                        {combo_list.map( ( item, idx ) => (
                            <DropdownItem
                                key={`pri_${item}_${idx}`}
                                id="combo"
                                name="combo"
                                value={item}
                                active={combo_text === item}
                                onClick={() => onClick(item)}>
                                {item}
                            </DropdownItem>)
                        )}
                    </Dropdown>
                }
            </div>
        </div>)
}
