import React, { useState, useEffect } from 'react';
import {
    Route, Routes, BrowserRouter, useLocation, useNavigate
} from "react-router-dom";

import { createRoot } from 'react-dom/client';

import { Toast } from "./components/toast";

import { useStore } from "./store"

import './assets/css/style.css'
import Logo from './assets/images/logo.png'

import * as Util from './helpers/util';
import { Privacy } from "./pages/privacy";
import { TermsService } from "./pages/terms_service";

import {GoogleDrive} from "./pages/google_drive";
import {Legislation} from "./pages/legislation";
import {Policy} from "./pages/policy";
import {PolicyEdit} from "./pages/policy_edit";
import {Landing} from "./pages/landing";
import {Sidebar} from "./components/sidebar";
import {HeaderSearch} from "./components/header_search";
import { Login } from "./pages/login";
import { Team } from "./pages/team";
import { Profile } from "./pages/profile";
import { Tags } from "./pages/tags";
import { Tenant } from "./pages/tenant";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { GOOGLE_CLIENT_ID } from "./settings";
import { WelcomeBack } from "./components/welcome_back";
import { Activity } from "./pages/activity";
import { Health } from "./pages/health";
import { TutorialModal } from "./modals/tutorial_modal";


export const App = (props) => {
    const [state, setState] = useState({
        preloaded: false,
        delay_loading: false,
        slow: false,
        err: false,
        toast: { msg: "", status: "" },
    })
    const { preloaded, delay_loading, err, slow, toast } = state

    const { setCsrfIfEmpty } = useStore( x => x )

    useEffect(() => {
        setTimeout(() => {
            setState(prev => ({...prev,
                delay_loading: true,
            }))
        }, 600)

        setTimeout(() => {
            setState(prev => ({...prev,
                slow: "Communication is taking longer than expected."
            }))
        }, 3000)

        //Assign the csrf token if I was given one
        if ( props.csrf.indexOf("csrf_token") < 0 ) {
            setCsrfIfEmpty(props.csrf)
        }
    }, [])

    const handleLoaded = () => {
        setState(prev => ({...prev,
            preloaded: true,
        }))
    }

    const handleToast = ( msg, status ) => {
        if ( status === undefined || status === null ) {
            status = "failure"
        }

        const toast = { msg, status };
        setState(prev => ({ ...prev, toast }))
    }

    const handleToastComplete = () => {
        const toast = { msg: "", status: "" };
        setState(prev => ({ ...prev, toast }))
    }

    //Main entry into program
    return (
        <BrowserRouter>
            {(!delay_loading || !preloaded) &&
                <Preload
                    msg={err || slow}
                    showToast={handleToast}
                    onLoaded={handleLoaded}
                />
            }
            {preloaded &&
            <Core
                hidden={!delay_loading}
                showToast={handleToast}
            />
            }

            <Toast
                msg={toast.msg}
                status={toast.status}
                timeout={5000}
                onComplete={handleToastComplete}
            />
        </BrowserRouter>
    );
}

export const Preload = (props) => {
    const { msg, showToast, onLoaded } = props

    const { setUsrInfo, setCsrfIfEmpty } = useStore( x => x )

    const path = `${window.location.pathname}`.replace(/^\//, '').replace(/\/.*$/, '').toLowerCase()
    const navigate = useNavigate()

    useEffect(() => {
        //Pull the human info
        Util.fetch_js('/api/human/is_valid/', {},
            js => {
                const { csrf } = js
                setCsrfIfEmpty( csrf )
                if ( js.valid ) {
                    const { human } = js
                    human.tenant = js.tenant || {}
                    setUsrInfo( human )

                    if ( path === 'login' ) {
                        navigate('/')
                    }
                }
                // Pages that don't require login
                else if ( path === 'privacy' || path == 'terms_service' ) {
                }
                // Everything else requires login
                else if ( path !== 'login' ) {
                    navigate('/login')
                }

                onLoaded()
            }, showToast )
    }, [])

    return (
        <div className="flex flex-col justify-center items-center h-screen bg-white max-w-full max-h-full z-50">
            <img src={Logo} alt="Logo" className="w-1/4"/>
            <p className="text-xl text-red-600">{msg}</p>
        </div>
    )
}

//This odd nesting is required because I need to load the path and that can only happen inside the router
export const Core = (props) => {
    const { hidden, showToast } = props

    const { usrInfo } = useStore( x => x )

    const [state, setState] = useState({
        government_uid: null,
        policy_uid: null,
        governments: [],
        session_uid: null,
        sessions: {},
        policies: [],
        show_tutorial: false,
        sub_tutorial: '',
    })
    const { government_uid, policy_uid, governments, session_uid, sessions, policies, show_tutorial, sub_tutorial } = state

    const [sidebarOpen, setSidebarOpen] = useState(false)

    const [_search, setSearch] = useState({})

    const location = useLocation()
    const path = `${window.location.pathname}`.replace(/^\//, '').replace(/\/.*$/, '').toLowerCase()

    const reloadPolicy = ( government_uid ) => {
        Util.fetch_js('/api/policy/list/', { government_uid, is_public: true },
            js => {
                setState(prev => ({...prev,
                    policies: js.policies,
                    policy_uid: js.policy_uid,
                }))
            }, showToast )
    }

    useEffect(() => {
        //Title controls
        if ( path === 'landing' || path === '' ) {
            document.title = `Anchordoc.ai`
        }
        else {
            document.title = `Anchordoc.ai | ${Util.namify( path )}`
        }

        setSidebarOpen(false)
    }, [location])

    //Nested call to get all policies and governments
    useEffect(() => {
        Util.fetch_js('/api/government/list/', {},
            js => {
                const government_uid = js.government_uid

                //Reload the policy
                reloadPolicy( government_uid )

                const session_uid = (government_uid in js.sessions)? ((js.sessions[government_uid].length > 0)? js.sessions[government_uid][0].uid: null): null

                //Define the governments
                setState(prev => ({...prev,
                    sessions: js.sessions,
                    governments: js.governments,
                    government_uid,
                    session_uid,
                }))
            }, showToast )
    }, []);

    //Fixes login bug that queries for policy before the user is logged in
    useEffect(() => {
        if ( government_uid ) {
            reloadPolicy( government_uid )
        }

    }, [usrInfo]);

    const handleGovernmentChange = (e) => {
        const government_uid = e.target.value

        //Run my policy reload
        reloadPolicy( government_uid )

        //Sync the user to keep their government settings
        Util.fetch_js('/api/human/client_settings/', { government_uid },
            js => {},
            showToast )

        const session_uid = (government_uid in sessions)? ((sessions[government_uid].length > 0)? sessions[government_uid][0].uid: null): null

        setState(prev => ({...prev,
            government_uid,
            session_uid,
            policy_uid: null,
        }))
    }

    const handlePolicyChange = (e) => {
        const policy_uid = e.target.value

        //Sync the user to keep their government settings
        Util.fetch_js('/api/human/client_settings/', { policy_uid },
            js => {},
            showToast )

        setState(prev => ({ ...prev,
            policy_uid: e.target.value
        }))
    }

    const handleChange = (e) => {
        const key = e.target.id || e.target.name
        setState(prev => ({ ...prev,
            [key]: e.target.value
        }))
    }

    const handlePolicyTaint = () => {
        reloadPolicy( government_uid )
    }

    const handleSearch = s => {
        const safe_path = (path !== '')? path: 'index'

        setSearch(prev => ({ ...prev,
            [safe_path]: s
        }))
    }

    const handleTabChange = (sub_tutorial) => {
        setState(prev => ({ ...prev,
            sub_tutorial
        }))
    }

    const safe_path = (path !== '')? path: 'index'
    const search = _search[safe_path] || ''

    //const government = governments.find( gov => gov.uid === government_uid ) || { uid: null, name: 'Government'}
    //const policy = policies.find( pol => pol.uid === policy_uid ) || { uid: null, name: 'Empty'}

    if ( path === 'login' ) {
        return (
            <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
                <Routes>
                    <Route path="/login/:reset_token" element={
                        <Login
                            onReloadPolicy={handlePolicyTaint}
                            showToast={showToast}
                            />
                    }/>
                    <Route path="*" element={
                        <Login
                            onReloadPolicy={handlePolicyTaint}
                            showToast={showToast}
                        />
                    }/>
                </Routes>
            </GoogleOAuthProvider>
        )
    }

    if ( path === 'privacy' ) {
        return (
            <Privacy />
        )
    }
    if ( path === 'terms_service' ) {
        return (
            <TermsService />
        )
    }

    return (
        <div>
            <WelcomeBack
                showToast={showToast}
            />

            <Sidebar
                hidden={hidden}
                sidebarOpen={sidebarOpen}
                onSidebarOpen={setSidebarOpen}
                onTutorial={() => handleChange({ target: { id: 'show_tutorial', value: true }})}
                showToast={showToast}
                />

            <div className="lg:pl-52">
                <HeaderSearch
                    search={search}
                    showToast={showToast}
                    onSidebarOpen={setSidebarOpen}
                    onSearch={handleSearch}
                    />

                <main className="py-10">
                    <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                        <Routes>
                            <Route path="/document/:document_uid" element={
                                <Legislation
                                    government_uid={government_uid}
                                    governments={governments}
                                    policy_uid={policy_uid}
                                    policies={policies}
                                    onTabChange={handleTabChange}
                                    onGovernmentChange={handleGovernmentChange}
                                    onSessionChange={handleChange}
                                    onPolicyChange={handlePolicyChange}
                                    showToast={showToast} />
                            }/>
                            <Route path='/activity' element={
                                <Activity
                                    search={search}
                                    government_uid={government_uid}
                                    governments={governments}
                                    policy_uid={policy_uid}
                                    policies={policies}
                                    session_uid={session_uid}
                                    sessions={government_uid in sessions? sessions[government_uid]: []}
                                    onGovernmentChange={handleGovernmentChange}
                                    onSessionChange={handleChange}
                                    onPolicyChange={handlePolicyChange}
                                    showToast={showToast} />
                            }/>
                            <Route path='/health' element={
                                <Health
                                    showToast={showToast} />
                            }/>
                            <Route path='/anchors' element={
                                <Policy
                                    government_uid={government_uid}
                                    governments={governments}
                                    onPolicyTaint={handlePolicyTaint}
                                    showToast={showToast} />
                            }/>
                            <Route path="/anchor/:policy_uid" element={
                                <PolicyEdit
                                    search={search}
                                    onPolicyTaint={handlePolicyTaint}
                                    showToast={showToast} />
                            }/>
                            <Route path='/tags' element={
                                <Tags
                                    showToast={showToast} />
                            }/>
                            <Route path="/team" element={
                                <Team
                                    showToast={showToast} />
                            }/>
                            <Route path="/tenant" element={
                                <Tenant
                                    governments={governments}
                                    showToast={showToast} />
                            }/>
                            <Route path="/gdrive" element={
                                <GoogleDrive
                                    showToast={showToast} />
                            }/>
                            <Route path="/profile/:human_uid" element={
                                <Profile
                                    showToast={showToast} />
                            }/>
                            <Route path='*' element={
                                <Landing
                                    search={search}
                                    government_uid={government_uid}
                                    governments={governments}
                                    policy_uid={policy_uid}
                                    policies={policies}
                                    session_uid={session_uid}
                                    sessions={government_uid in sessions? sessions[government_uid]: []}
                                    onGovernmentChange={handleGovernmentChange}
                                    onSessionChange={handleChange}
                                    onPolicyChange={handlePolicyChange}
                                    showToast={showToast} />
                            }/>
                        </Routes>

                        <TutorialModal
                            open={show_tutorial}
                            sub_tutorial={sub_tutorial}
                            onClose={() => handleChange({ target: { id: 'show_tutorial', value: false }})}
                            showToast={showToast}
                        />
                    </div>
                </main>
            </div>
        </div>
    );
}

//*** This is the part that inserts the react app into the dom
const app = document.getElementById('app');
const root = createRoot(app);

const csrf = app.getAttribute("csrf");

root.render(
    //<React.StrictMode>
        <App csrf={csrf} />
    //</React.StrictMode>
)
