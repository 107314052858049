import React, { useEffect, useState } from 'react'
import * as Util from "../helpers/util";
import {
    EllipsisHorizontalIcon, UserCircleIcon
} from "@heroicons/react/24/solid";
import { EmptyList } from "./empty_list";
import { ChatBubbleLeftIcon } from "@heroicons/react/24/outline";
import { convertRating, PillChange } from "./pill_change";
import { Spinner } from "./spinner";


export const ChatConversation = props => {
    const { chat, is_rating, rating, onRate, showToast } = props

    const conversation = (chat !== null)?
        chat.payload.conversation.map( (conv, idx) => ({
            type: 'commented',
            person: chat.human,
            uid: conv.uid,
            comment_uid: chat.uid,
            comment: conv.question,
            response: conv.response,
            date: Util.epochToAgo( conv.created_on ),
            dateTime: '2023-01-23T15:56',
        })) : []

    const containerRef = React.useRef(null);
    useEffect(() => {
        if (containerRef.current) {
          containerRef.current.scrollTop = containerRef.current.scrollHeight;
        }
    }, [chat])

    return (
        <div className="relative w-full h-[calc(100%-1rem)] overflow-auto" ref={containerRef}>
            <ul role="list" className="space-y-6">
                {conversation.map( ( comment, idx ) => (
                    <li key={`comment_${idx}`}
                        className="relative flex gap-x-4">
                        <div className={Util.classNames( idx === conversation.length - 1 ? 'h-6' : '-bottom-6', 'absolute left-0 top-0 flex w-6 justify-center' )}>
                            <div className="w-px bg-gray-200"/>
                        </div>
                        {comment.person.profile_url && <img
                            src={comment.person.profile_url}
                            alt=""
                            className="relative mt-3 h-6 w-6 flex-none rounded-full bg-gray-50"
                        />}
                        {comment.person.profile_url === null && <div
                            className="relative mt-3 h-6 w-6 flex-none rounded-full bg-white">
                            <UserCircleIcon
                                className="h-6 w-6 rounded-full text-gray-300"
                                aria-hidden="true"/>
                        </div>}
                        <div className="flex-auto rounded-md py-3 ring-1 ring-inset ring-gray-200">
                            <div className="flex justify-between gap-x-4 px-3">
                                <div className="py-0.5 text-xs leading-5 text-gray-500">
                                    <span className="font-medium text-gray-900">{comment.person.name}</span> commented
                                </div>
                                <time
                                    dateTime={comment.dateTime}
                                    className="flex-none py-0.5 text-xs leading-5 text-gray-500">
                                    {comment.date}
                                </time>
                            </div>
                            <p className="text-sm leading-6 text-gray-500 px-3">
                                {comment.comment?.split( "\n" ).map( ( line, c_idx ) =>
                                    <span key={`${idx}_${c_idx}`}>{line}<br/></span>
                                )}
                            </p>
                            <div className="border-t my-2 border-gray-200"/>
                            <div className="flex justify-between gap-x-4 px-3">
                                <div className="py-0.5 text-xs leading-5 text-gray-500">
                                    <span className="font-medium text-gray-900">AI Chat</span> response
                                </div>
                                {comment.response && onRate && !rating &&
                                    <div
                                        className="bg-indigo-600 text-white inline-flex items-baseline rounded-full px-2.5 py-0.5 text-sm font-medium md:mt-2 lg:mt-0 cursor-pointer"
                                        onClick={() => onRate( comment )}>
                                        Rate
                                    </div>
                                }
                                {comment.response && rating &&
                                    <PillChange
                                        className="ml-2"
                                        value={rating.value}
                                        change={rating.change}
                                        type={rating.type}
                                    />
                                }

                                {comment.response && !rating && is_rating &&
                                    <Spinner
                                        className="ml-2 w-6 h-6 text-indigo-600 z-50 shrink-0"
                                    />
                                }
                            </div>
                            {comment.response === null &&
                                <EllipsisHorizontalIcon
                                    className="animate-pulse flex-shrink-0 h-12 w-12 ml-1 text-gray-400"/>
                            }
                            {comment.response &&
                                <p className="text-sm leading-6 text-gray-500 px-3">
                                    {comment.response?.split( "\n" ).map( ( line, resp_idx ) =>
                                        <span key={`${idx}_${resp_idx}`}>{line}<br/></span>
                                    )}
                                </p>
                            }
                        </div>
                    </li>
                ))}
                {conversation.length <= 0 &&
                    <li className="relative flex justify-center gap-x-4">
                        <EmptyList
                            icon={ChatBubbleLeftIcon}
                            name="conversation"
                        />
                    </li>
                }
            </ul>
        </div>
    )
}