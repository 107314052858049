import { PhotoIcon, PlusIcon, UserCircleIcon } from '@heroicons/react/24/solid'
import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import * as Util from "../helpers/util.js";
import {useStore} from "../store";
import { ConfirmModal } from "../modals/confirm_modal";
import { ButtonOptions } from "../components/button_options";
import { ArrowPathIcon } from "@heroicons/react/24/outline";


const ResyncActions = {
    RESYNC: 0,
    UPLOAD: 1,
}

const ResyncOptions = [
    'Google Sync',
    'Upload Image',
]

export const Profile = props => {
    const { showToast } = props

    const [state, setState] = useState({
        auth: '',
        name: '',
        email: '',
        profile_url: null,
        password: '',
        password_repeat: '',
        auto_notify: false,
        confirm_remove: false,
        raw_file: null,
        filename: null,
    })
    const {auth, name, email, profile_url, password, password_repeat, auto_notify, confirm_remove, raw_file, filename} = state

    const fileRef = React.useRef(null)

    const navigate = useNavigate()

    const {human_uid} = useParams()

    const { usr_info, setUsrInfo } = useStore( x => x )

    useEffect(() => {
        if ( human_uid === null || human_uid === undefined || human_uid === "create" ) {
            return
        }

        //Load up the humans
        Util.fetch_js('/api/human/detail/', { human_uid },
            js => {
                const { name, auth, email, profile_url, auto_notify } = js.human
                setState(prev => ({...prev,
                    auth,
                    name,
                    email,
                    profile_url,
                    auto_notify,
                }))
            }, showToast)
    }, [])

    const handleEnter = (e) => {
        if (e.key === 'Enter') {
            handleSave( true )
        }
    }

    const handleSave = (prevent_nav) => {
        if ( password !== '' && password !== password_repeat ) {
            showToast( 'Passwords do not match', 'Error' )
            return
        }

        const pwd_hash = (password === '') ? null : Util.hashStr(password)
        const action = (human_uid === null || human_uid === undefined || human_uid === "create") ? 'create' : 'modify'

        Util.fetch_js( `/api/human/${action}/`, { email, name, pwd_hash, human_uid, image: raw_file },
            js => {
                if ( js.human.uid === usr_info.uid ) {
                    const { name, email, profile_url } = js.human
                    setUsrInfo( { ...usr_info, name, email, profile_url } )
                }

                if ( !prevent_nav ) {
                    navigate( '/team' )
                }
                showToast( 'Updated', 'Successful' )
            }, showToast)
    }

    const handleDelete = del => {
        if ( !del ) {
            setState(prev => ({...prev,
                confirm_remove: false,
            }))
            return
        }

        Util.fetch_js( `/api/human/disable/`, { human_uids: [human_uid], disable: true },
            js => {
                navigate( '/team' )
                showToast( 'Deleted', 'Successful' )
            }, showToast)
    }

    const handleChange = e => {
        const { name, value } = e.target;
        setState(prev => ({ ...prev,
            [name]: value
        }))
    }

    const handleChecked = e => {
        const { name, checked } = e.target;
        setState(prev => ({ ...prev,
            [name]: checked
        }))
    }

    const handleFileChange = (e) => {
        const raw_file = e.target.files[0];

        //Set the state
        setState(prev => ({...prev,
            profile_url: URL.createObjectURL(raw_file),
            filename: raw_file.name,
            raw_file,
        }))
    }

    const handleFileClick = () => {
        fileRef.current.click();
    };

    const handleResync = action => {
        if ( action === ResyncActions.RESYNC ) {
            Util.fetch_js( `/api/human/modify/`, { resync_profile: true },
                js => {
                    if ( js.human.uid === usr_info.uid ) {
                        const { name, email, profile_url } = js.human
                        setUsrInfo( { ...usr_info, name, email, profile_url } )
                    }

                    setState(prev => ({...prev,
                        profile_url: js.human.profile_url,
                    }))

                    showToast( 'Resynced', 'Successful' )
                }, showToast)
        }
        else if ( action === ResyncActions.UPLOAD ) {
            handleFileClick()
        }
    }

    return (
        <form>
            <div className="space-y-12 sm:space-y-16">
                <div>
                    <h2 className="text-base font-semibold leading-7 text-gray-900">Profile</h2>
                    <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-600">
                        View/Edit account information
                    </p>

                    <div
                        className="mt-10 space-y-8 border-b border-gray-900/10 pb-12 sm:space-y-0 sm:divide-y sm:divide-gray-900/10 sm:border-t sm:pb-0">
                        <div
                            className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
                            <label htmlFor="first-name"
                                   className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                                Name
                            </label>
                            <div className="mt-2 sm:col-span-2 sm:mt-0">
                                <input
                                    type="text"
                                    name="name"
                                    id="name"
                                    value={name}
                                    onChange={handleChange}
                                    onKeyDown={handleEnter}
                                    autoComplete="given-name"
                                    placeholder="John Doe"
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                                />
                            </div>
                        </div>

                        <div
                            className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
                            <label htmlFor="humanname"
                                   className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                                Email
                            </label>
                            <div className="mt-2 sm:col-span-2 sm:mt-0">
                                <input
                                    type="text"
                                    name="email"
                                    id="email"
                                    value={email}
                                    onChange={handleChange}
                                    onKeyDown={handleEnter}
                                    autoComplete="email"
                                    placeholder="xxx@gmail.com"
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                                />
                            </div>
                        </div>

                        <div
                            className="sm:grid sm:grid-cols-3 sm:items-center sm:gap-4 sm:py-6">
                            <label htmlFor="photo"
                                   className="block text-sm font-medium leading-6 text-gray-900">
                                Photo
                            </label>
                            <div className="mt-2 sm:col-span-2 sm:mt-0">
                                <div className="flex items-center gap-x-3">
                                    <input
                                        type="file"
                                        accept="image/*"
                                        className="hidden"
                                        ref={fileRef}
                                        onChange={handleFileChange}
                                    />
                                    {profile_url && <img src={profile_url}
                                                         className="h-12 w-12 text-gray-300 rounded-full"
                                                         aria-hidden="true"
                                                         alt="User profile image"
                                    />}
                                    {profile_url === null && <UserCircleIcon
                                        className="h-12 w-12 text-gray-300 rounded-full"
                                        aria-hidden="true"/>}
                                    {auth.toLowerCase() !== 'google' && <button
                                        type="button"
                                        className="rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                                        onClick={handleFileClick}>
                                        Change
                                    </button>}
                                    {auth.toLowerCase() === 'google' &&
                                        <ButtonOptions
                                            className="ml-6"
                                            align="right"
                                            theme="white"
                                            options={ResyncOptions}
                                            onClick={handleResync}>
                                            <ArrowPathIcon
                                                className="mr-2 h-5 w-5 shrink-0"
                                                aria-hidden="true"/>
                                            Resync
                                        </ButtonOptions>}
                                </div>
                            </div>
                        </div>

                        {auth.toLowerCase() !== 'google' && <div
                            className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
                            <label htmlFor="humanname"
                                   className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                                Password
                            </label>
                            <div className="mt-2 sm:col-span-2 sm:mt-0">
                                <input
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                                    type="password"
                                    name="password"
                                    id="password"
                                    value={password}
                                    onChange={handleChange}
                                />
                            </div>
                            {password !== '' && <div
                                className="mt-2 sm:col-start-2 sm:col-span-2 sm:mt-0">
                                <p className="text-sm font-medium text-gray-600">
                                    (Repeat)
                                </p>
                                <input
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                                    type="password"
                                    name="password_repeat"
                                    id="password_repeat"
                                    value={password_repeat}
                                    onChange={handleChange}
                                    onKeyDown={handleEnter}
                                />
                            </div>}
                        </div>}

                        <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
                            <label htmlFor="auto_notify"
                                   className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                                Auto Notify Bookmarked Documents
                            </label>
                            <div className="mt-2 sm:mt-0">
                                <input
                                    type="checkbox"
                                    name="auto_notify"
                                    id="auto_notify"
                                    checked={auto_notify}
                                    onChange={handleChecked}
                                    className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="mt-6 flex items-center justify-between gap-x-6">
                {(human_uid !== null && human_uid !== undefined && human_uid !== "create" && human_uid !== usr_info.uid) ?
                    <button
                        type="button"
                        onClick={() => handleChange( {
                            target: {
                                name: 'confirm_remove',
                                value: true
                            }
                        } )}
                        className="text-sm font-semibold leading-6 text-red-600">
                        Delete
                    </button> : <div></div>}
                <div>
                    <button
                        type="button"
                        onClick={() => navigate( '/team' )}
                        className="text-sm font-semibold leading-6 text-gray-900">
                        Cancel
                    </button>
                    <button
                        type="button"
                        onClick={handleSave}
                        className="inline-flex justify-center ml-6 rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                        Save
                    </button>
                </div>
            </div>

            <ConfirmModal
                open={confirm_remove}
                danger={true}
                title='Delete User'
                message={`Are you sure you want to delete ${name}?`}
                confirmBtn="Delete"
                onClose={handleDelete}
            />
        </form>)
}
