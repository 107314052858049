import { Link } from "react-router-dom";
import Logo from "../assets/images/logo.png";
import ReactMarkdown from 'react-markdown';

export const Privacy = props => {
    const markdown = `
**Privacy Policy**

**Introduction**
Anchordoc.ai ("we", "our", "us") is committed to protecting your privacy. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you visit our website anchordoc.ai, and describes your data protection rights.

**Data Collection**
We may collect the following types of information:
- Personal Data: Name, email address, phone number, payment information.
- Usage Data: IP address, browser type, pages visited, time spent on pages.
- Google User Data: Information from your Google Drive, including file metadata and content, as permitted by you.

**Use of Data**
We use the collected data to:
- Provide and maintain our services.
- Improve and personalize user experience.
- Process transactions.
- Send marketing and promotional communications (with user consent).
- Access, use, and manage files in your Google Drive only as explicitly allowed by you, strictly for the purpose of [specific purpose, e.g., "enhancing your experience on our platform"].

**Data Sharing and Disclosure**
We may share your information with:
- Service providers who perform services on our behalf.
- Third parties to comply with legal obligations.
We do not share Google user data with third parties except as necessary to comply with legal obligations or as explicitly permitted by you.

**Data Protection**
We implement security measures such as encryption and secure servers to protect your data. In case of a data breach, we will notify you and relevant authorities as required by law.

**User Rights**
You have the right to:
- Access and update your personal data.
- Request deletion or restriction of your data.
- Opt-out of marketing communications.
- Revoke access to your Google Drive data at any time through your Google account settings.

**Google Drive API Specific Disclosures**
- Our app accesses, uses, stores, and shares Google user data only in ways that are clearly disclosed to you and with your explicit consent.
- We comply with Google’s Limited Use requirements, meaning that we use the data solely for providing or improving user-facing features that are prominent in the requesting app’s user interface.
- We do not transfer Google user data to third parties except as necessary to comply with legal obligations or with your explicit consent.
- We do not use Google user data for serving advertisements.

**Cookies and Tracking Technologies**
We use cookies to enhance your experience on our website. You can manage your cookie preferences through your browser settings.

**Children's Privacy**
Our website is not intended for children under the age of 13. We do not knowingly collect personal data from children under 13 without parental consent.

**International Data Transfers**
Your information may be transferred to and processed in countries outside your own. We ensure appropriate safeguards are in place.

**Changes to the Privacy Policy**
We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page.

**Contact Information**
If you have any questions about this Privacy Policy, please contact us at luke.dupin@anchordoc.ai.

**Effective Date:** 2024-07-24
`;
    const {  } = props

    return (
        <>
            <div className="flex min-h-full flex-1 flex-col justify-center py-12 sm:px-6 lg:px-8">
                <div className="sm:mx-auto sm:w-full sm:max-w-md">
                    <img
                        className="mx-auto h-10 w-auto"
                        src={Logo}
                        alt="Anchor"
                    />
                    <h2 className="mt-6 text-center text-2xl font-bold leading-9 tracking-tight text-gray-500">
                        Privacy Policy
                    </h2>
                </div>

                <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-[900px]">
                    <div className="bg-white px-6 py-12 sm:rounded-lg sm:px-12">
                        <ReactMarkdown className="privacy-policy">{markdown}</ReactMarkdown>
                    </div>
                </div>
            </div>
            <footer className="relative bg-white pt-8 pb-6">
                <div className="container mx-auto px-4">
                    <hr className="my-6 border-gray-400" />
                    <div className="flex flex-wrap items-center md:justify-between justify-center">
                        <div className="w-full md:w-4/12 px-4 mx-auto text-center">
                            <div className="text-sm text-gray-600 py-1">
                                <span className="pr-4 text-gray-400">Copyright © 2024 anchordoc.ai</span>
                                <Link to="/privacy" className="text-gray-600 hover:text-gray-900">Privacy Policy</Link>
                                {/* <span className="px-1"> | </span><a href="/terms" className="text-gray-600 hover:text-gray-900">Terms of Service</a> */}
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </>)
}
