import React, {useState, useRef, useEffect} from "react"
import * as Util from "../helpers/util.js"
import {Link, useNavigate, useParams} from "react-router-dom";
import { TrashIcon as TrashSolidIcon } from "@heroicons/react/24/solid";
import {
    Cog6ToothIcon, DocumentTextIcon, ScaleIcon, TagIcon, TrashIcon
} from "@heroicons/react/24/outline";
import {PlusIcon} from "@heroicons/react/24/solid";
import {ConfirmModal} from "../modals/confirm_modal";
import { GovernmentSelector } from "../components/government_selector";
import { PolicyClaimRead } from "../components/policy_claim_read";
import { PolicyClaimEdit } from "../components/policy_claim_edit";
import { PolicyClaimFooter } from "../components/policy_claim_footer";
import { PillarEdit } from "../components/pillar_edit";
import { ButtonOptions } from "../components/button_options";
import { EmptyList } from "../components/empty_list";
import { PolicySandbox } from "../components/policy_sandbox";
import { Banner } from "../components/banner";
import { ImportFileModal } from "../modals/import_file_modal";
import { PillarConfig } from "../components/pillar_config";
import {Tooltip} from "../components/tooltip";

const PillarActions = {
    ADD_PILLAR: 0,
    IMPORT_XLSX: 1,
    EXPORT_XLSX: 2,
}

const PillarOptions = [
    'Add Pillar',
    'Import XLSX',
    'Export XLSX',
]

export const PolicyEdit = (props) => {
    const {search, onPolicyTaint, showToast} = props

    const [state, setState] = useState({
        name: "",
        mode: "",
        last_question: '',
        government_uids: [],
        pillars: [],
        tags: [],
        confirm_delete_pillar: null,
        show_upload: false,
        show_mode: false,
        edit_pillar: -1,
        connection_error: false,

        document_uid: null,
    })
    const {name, mode, last_question, government_uids, pillars, tags, confirm_delete_pillar, show_mode, show_upload, edit_pillar, connection_error, document_uid} = state

    const {policy_uid} = useParams()

    const textareaRef = useRef(null);
    const spreadsheetRef = useRef(null)

    useEffect(() => {
        Util.fetch_js('/api/tags/list/', {},
            js => {
                setState(prev => ({ ...prev,
                    tags: js.tags,
                }))
            }, showToast)
    }, [])

    useEffect(() => {
        revertPolicy( null )

    }, [policy_uid])

    useEffect(() => {
        if (textareaRef.current) {
            textareaRef.current.focus()
        }
    })

    const revertPolicy = (toast) => {
        if (toast !== null && toast !== undefined) {
            showToast(toast, "error")
        }

        //Any government change, we need to update the policy
        if (policy_uid !== null && policy_uid !== undefined && policy_uid !== "" && policy_uid !== "create") {
            setState(prev => ({ ...prev,
                confirm_delete_pillar: null,
                show_upload: false,
                edit_pillar: -1,
            }))
            Util.fetch_js('/api/policy/detail/', {policy_uid},
                js => {
                    setState(prev => ({ ...prev,
                        name: js.policy.name,
                        mode: js.policy.mode,
                        government_uids: js.policy.government_uids,
                        pillars: js.policy.pillars,
                    }))

                }, showToast)
        }
        //Preload one claim
        else {
            const pillar = {
                name: "",
                claims: [{
                    query: "",
                }],
            }
            setState(prev => ({ ...prev,
                pillars: [pillar],
            }))
        }
    }

    const handleRemovePillar = (uid) => {
        //Delete the pillar and then update the list
        Util.fetch_js('/api/policy/pillar_remove/', { policy_uid, pillar_uids: [uid] },
            js => {
                showToast("Removed", "success")
                setState(prev => ({ ...prev,
                    pillars: js.policy.pillars,
                }))
            }, revertPolicy)
    }

    const handleRemoveClaim = (uid) => {
        //Delete the pillar and then update the list
        Util.fetch_js('/api/policy/claim_remove/', { policy_uid, claim_uids: [uid] },
            js => {
                showToast("Removed", "success")
                setState(prev => ({ ...prev,
                    pillars: js.policy.pillars,
                }))
            }, revertPolicy)
    }

    const handleChange = (e) => {
        if (e.target.id === 'show_mode' && mode.toLowerCase() === "sandbox" ) {
            return
        }

        setState(prev => ({
            ...prev,
            [e.target.id]: e.target.value
        }))
    }

    const handlePillar = (action) => {
        if ( action === PillarActions.ADD_PILLAR ) {
            Util.fetch_js( '/api/policy/pillar_create/', { policy_uid, name: 'New Pillar', prompts: ['Does this bill contain X?'] },
                js => {
                    showToast( "Pillar added", "success" )
                    setState( prev => ({
                        ...prev,
                        pillars: [js.pillar, ...state.pillars],
                    }) )
                }, revertPolicy )
        }
        else if ( action === PillarActions.IMPORT_XLSX ) {
            setState(prev => ({ ...prev,
                show_upload: true,
            }))
        }
        else if ( action === PillarActions.EXPORT_XLSX ) {
            if ( pillars.length === 0 ) {
                showToast( "No pillars to export", "error" )
                return
            }
            Util.post_new_tab( '/api/policy/export/', { policy_uid } )
        }
    }

    const handleImportFile = (file) => {
        //Import the file
        Util.fetch_js( '/api/policy/import/', { policy_uid, file },
            js => {
                showToast('Pillars imported', "success")
                setState(prev => ({ ...prev,
                    show_upload: false,
                    pillars: js.policy.pillars,
                }))
            },
            err => {
                spreadsheetRef.current.cancelUpload()
                showToast(err, "error")
            } )
    }

    const handleImportClose = () => {
        setState(prev => ({ ...prev, show_upload: false }))
    }

    const handleAddClaim = (p_idx) => {
        Util.fetch_js('/api/policy/claim_create/', { pillar_uid: pillars[p_idx].uid, query: last_question },
            js => {
                const pillars = [...state.pillars]
                pillars[p_idx].claims = [...pillars[p_idx].claims, js.claim]

                showToast("Claim added", "success")

                setState(prev => ({ ...prev,
                    pillars
                }))
            }, revertPolicy )

        setState(prev => ({ ...prev,
            last_question: '',
        }))
    }

    const handleChangePillar = (p_idx, value) => {
        const pillars = [...state.pillars]

        pillars[p_idx].name = value

        setState(prev => ({ ...prev,
            pillars,
        }))
    }

    const handleChangeClaim = (p_idx, c_idx, e) => {
        const pillars = [...state.pillars]

        let {id, value} = e.target
        pillars[p_idx].claims[c_idx][id] = value

        //Convert tags to server format
        if ( id === 'tags' ) {
            id = 'tag_uids'
            value = value.map( t => t.uid )
        }

        //Sync the data
        Util.fetch_js('/api/policy/claim_modify/', { claim_uid: pillars[p_idx].claims[c_idx].uid, [id]: value },
            js => {
            }, showToast )

        setState(prev => ({ ...prev,
            pillars,
        }))
    }

    const handleChangePrompt = (p_idx, c_idx, pr_idx, e, force=false) => {
        const pillars = [...state.pillars]

        const {id, value} = e.target
        pillars[p_idx].claims[c_idx].prompts[pr_idx][id] = value

        //Sync the data
        const prompt_uid = pillars[p_idx].claims[c_idx].prompts[pr_idx].uid
        if ( force || id !== 'query' ) {
            Util.fetch_js( '/api/policy/prompt_modify/', { prompt_uid, [id]: value },
                js => {}, showToast )
        }

        setState(prev => ({ ...prev,
            pillars,
        }))
    }

    const handleAddPrompt = (p_idx, c_idx) => {
        Util.fetch_js('/api/policy/prompt_create/', { claim_uid: pillars[p_idx].claims[c_idx].uid },
            js => {
                const pillars = [...state.pillars]
                pillars[p_idx].claims[c_idx].prompts.push( js.prompt )

                setState(prev => ({ ...prev,
                    pillars,
                }))
            }, showToast )
    }

    const handleRemovePrompt = (p_idx, c_idx, pr_idx) => {
        const pillars = [...state.pillars]

        const prompt_uids = [pillars[p_idx].claims[c_idx].prompts[pr_idx].uid]
        pillars[p_idx].claims[c_idx].prompts.splice(pr_idx, 1)

        //Update the server
        Util.fetch_js('/api/policy/prompt_remove/', { policy_uid, prompt_uids },
            js => {
                onPolicyTaint()

                showToast("Removed prompt", "success")
            }, revertPolicy )

        setState(prev => ({ ...prev,
            pillars,
        }))
    }

    const handleMode = (mode) => {
        if ( mode === null || mode === false || mode === undefined || mode === props.mode ) {
            setState(prev => ({ ...prev,
                show_mode: false,
            }))
            return
        }

        //Set the mode
        setState(prev => ({ ...prev,
            show_mode: false,
            mode
        }))

        //Update the server
        Util.fetch_js('/api/policy/modify/', { policy_uid, mode },
            js => {
                onPolicyTaint()

                showToast(`Policy mode ${js.policy.mode}`, "success")

                setState(prev => ({ ...prev,
                    mode: js.policy.mode,
                }))
            }, revertPolicy )
    }

    const handlePolicyUpdate = () => {
        Util.fetch_js('/api/policy/modify/', { policy_uid, name },
            js => {
                onPolicyTaint()

                setState(prev => ({ ...prev,
                    name: js.policy.name,
                    mode: js.policy.mode,
                }))
            }, revertPolicy )
    }

    const handlePillarUpdate = (p_idx) => {
        const pillar = pillars[p_idx]
        Util.fetch_js('/api/policy/pillar_modify/', { pillar_uid: pillar.uid, name: pillar.name },
            js => {
                const pillars = [...state.pillars]
                pillars[p_idx].name = js.pillar.name

                setState(prev => ({ ...prev,
                    pillars
                }))
            }, revertPolicy )
    }

    const handleKeyDownBlur = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            e.target.blur()
        }
    };

    const handleGovernments = (government_uids) => {
        //Update the server
        Util.fetch_js('/api/policy/modify/', { policy_uid, government_uids },
            js => {
                onPolicyTaint()

                setState(prev => ({ ...prev,
                    government_uids: js.policy.government_uids,
                }))
            }, revertPolicy )

        setState(prev => ({ ...prev,
            government_uids,
        }))
    }

    return (<>
        {connection_error &&
            <Banner
                message={"AI Server connection failed."}
                color={"bg-red-600"}
                onDismiss={() => {
                    setState( prev => ({ ...prev, connection_error: false }) )
                }}
            />
        }

        <div className="space-y-10">
            <div className="grid grid-cols-1 gap-x-8 gap-y-8 md:grid-cols-3 border-b pb-10">
                <div className="px-4 sm:px-0">
                    <h2 className="text-base font-semibold leading-7 text-gray-900">
                        Anchor
                    </h2>
                </div>

                <div className="bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2">
                    <div className="px-4 pt-6 pb-0 sm:p-8">
                        <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            <div className="sm:col-span-3">
                                <label htmlFor="pillar_name"
                                       className="block text-sm font-bold leading-6 text-gray-900">
                                    Name
                                </label>
                                <div className="mt-2">
                                    <input
                                        type="text"
                                        name="name"
                                        id="name"
                                        autoComplete="given-name"
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                        placeholder="Policy name"
                                        value={name}
                                        onChange={handleChange}
                                        onKeyDown={handleKeyDownBlur}
                                        onBlur={handlePolicyUpdate}
                                    />
                                </div>
                            </div>

                            <div className="sm:col-span-6 pb-2">
                                <fieldset>
                                    <legend className="text-sm font-semibold leading-6 text-gray-900">
                                        Mode
                                    </legend>
                                    <p className="mt-1 text-sm leading-6 text-gray-600">
                                        Protect policies from being modified.
                                    </p>
                                    <div className="mt-2 space-y-2">
                                        <div className="flex items-center gap-x-3">
                                            <input
                                                id="mode-locked"
                                                name="mode"
                                                type="radio"
                                                className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                                onClick={() => handleMode( 'locked' )}
                                                checked={mode.toLowerCase() === 'locked'}
                                                readOnly
                                            />
                                            <label htmlFor="mode-locked"
                                                   className="block text-sm font-medium leading-6 text-gray-900">
                                                Locked
                                            </label>
                                        </div>
                                        <div className="flex items-center gap-x-3">
                                            <input
                                                id="mode-sandbox"
                                                name="mode"
                                                type="radio"
                                                className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                                onClick={() => handleChange({target: {id: 'show_mode', value: true}})}
                                                checked={mode.toLowerCase() === 'sandbox'}
                                                readOnly
                                            />
                                            <label htmlFor="mode-sandbox"
                                                   className="block text-sm font-medium leading-6 text-gray-900">
                                                Sandbox
                                            </label>
                                        </div>
                                    </div>
                                </fieldset>
                            </div>
                        </div>

                        <div className="sm:col-span-3">
                            <GovernmentSelector
                                className="pb-0"
                                selected_uids={government_uids}
                                 onChange={handleGovernments}
                                showToast={showToast}
                            />
                        </div>
                    </div>
                </div>
            </div>

            {pillars.length > 0 && mode.toLowerCase() === "sandbox" &&
                <PolicySandbox
                    className="w-full border-b pb-10"
                    document_uid={document_uid}
                    onDocumentChange={value => handleChange({target: {id: 'document_uid', value}})}
                    onConnectionError={() => handleChange({target: {id: 'connection_error', value: true}})}
                    onQuestion={value => handleChange({target: {id: 'last_question', value}})}
                    showToast={showToast}
                />
            }

            <div className="grid grid-cols-1 gap-x-8 gap-y-8 md:grid-cols-3">
                {pillars.map( ( pillar, p_idx ) => {
                    const result = []

                    if ( search !== "" && !pillar.name.toLowerCase().includes( search.toLowerCase() ) ) {
                        return result
                    }

                    //Start UID
                    let start_idx = 0
                    if ( search !== "" ) {
                        const tmp = pillars.filter( p => p.name.toLowerCase().includes( search.toLowerCase() ) )
                        if ( tmp.length > 0 && tmp[0].uid === pillar.uid ) {
                            start_idx = p_idx
                        }
                    }

                    //Left Column
                    if ( p_idx === start_idx ) {
                        result.push( <div key={`pillar_space_${p_idx}`}
                                          className="flex flex-row content-start justify-between items-start">
                            <ButtonOptions
                                align="left"
                                options={PillarOptions}
                                onClick={handlePillar}>
                                <PlusIcon className="mr-1 h-5 w-5 shrink-0"
                                          aria-hidden="true"/>
                                Pillars
                            </ButtonOptions>
                        </div> )
                    }
                    else {
                        result.push( <div key={`pillar_space_${p_idx}_`}
                                          className="px-4 sm:px-0"></div> )
                    }

                    //Right Column
                    result.push( <div key={`pillar_${p_idx}`}
                                      className="bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2">
                        <div className="px-4 py-6 sm:px-8 sm:py-8">
                            <div className="grid w-full grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                                <div className="sm:col-span-6 inline-flex justify-between">
                                    <div className="w-3/4">
                                        <label htmlFor="pillar_name"
                                               className="block text-sm font-bold leading-6 text-gray-900">
                                            Pillar
                                        </label>
                                        <input
                                            type="text"
                                            name="pillar_name"
                                            id="pillar_name"
                                            className="block w-full mt-2 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                            value={pillar.name}
                                            placeholder="Human readable claim"
                                            onChange={e => handleChangePillar( p_idx, e.target.value )}
                                            onBlur={() => handlePillarUpdate( p_idx )}
                                            onKeyDown={e => handleKeyDownBlur( e )}
                                        />
                                    </div>

                                    <div className="relative group h-6 w-6 sm:mt-4">
                                        <TrashSolidIcon
                                            className="cursor-pointer h-5 w-5 shrink-0 text-gray-400 hover:text-red-700"
                                            aria-hidden="true"
                                            onClick={() => handleChange( { target: { id: 'confirm_delete_pillar', value: pillar.uid } } )}
                                        />
                                        <Tooltip tooltip="Delete this pillar"/>
                                    </div>
                                </div>

                                {pillar.claims.map( ( claim, c_idx ) => (
                                    <PillarConfig
                                        key={`claim_${claim.uid}_${c_idx}`}
                                        pillar={pillar}
                                        claim={claim}
                                        tags={tags}
                                        onChangeClaim={e => handleChangeClaim( p_idx, c_idx, e )}
                                        onChangePrompt={(pr_idx, e, force) => handleChangePrompt( p_idx, c_idx, pr_idx, e, force )}
                                        onAddPrompt={() => handleAddPrompt( p_idx, c_idx )}
                                        onRemovePrompt={(pr_idx) => handleRemovePrompt( p_idx, c_idx, pr_idx )}
                                        onRemoveClaim={() => handleRemoveClaim( claim.uid )}
                                        showToast={showToast}
                                        />
                                ))}
                            </div>
                        </div>

                        <PolicyClaimFooter
                            p_idx={p_idx}
                            pillar={pillar}
                            onAdd={handleAddClaim}
                            onDelete={value => handleChange( { target: { id: 'confirm_delete_pillar', value } } )}
                            className="text-sm font-semibold leading-6 text-red-600"
                        />
                    </div> )

                    return result
                } )}
            </div>

            {pillars.length === 0 &&
            <div className="pb-44">
                <EmptyList
                    icon={ScaleIcon}
                    name="pillar"
                    options={PillarOptions}
                    onCreate={handlePillar}
                />
            </div>
            }

            <ConfirmModal
                open={show_mode}
                uid={'sandbox'}
                danger={true}
                title="Unlock Policy"
                message="Are you sure you want to unlock this policy?"
                confirmBtn="Unlock"
                onClose={handleMode}
            />

            <ConfirmModal
                open={confirm_delete_pillar !== null}
                uid={confirm_delete_pillar}
                danger={true}
                setState={setState}
                keyState="confirm_delete_pillar"
                title="Delete pillar?"
                message="Are you sure you want to delete this pillar?"
                confirmBtn="Delete"
                onConfirm={handleRemovePillar}
            />

            <ImportFileModal
                ref={spreadsheetRef}
                open={show_upload}
                load_on_start={true}
                onUpload={handleImportFile}
                onClose={handleImportClose}
                showToast={showToast}
            />
        </div>
    </>)
}
