import { PillChange, PillType } from "./pill_change";
import * as Util from "../helpers/util";
import React from "react";
import { Dropdown, DropdownItem } from "./dropdown";

export const SummaryRating = props => {
    const { policies, policy_uid, stats, onPolicy } = props
    const className = props.className || ''

    const policy = policies.find( pol => pol.uid === policy_uid ) || { uid: 'xxx983823', name: 'Empty'}

    return (
        <dl className={Util.classNames(
                className,
                "grid grid-cols-1 divide-y divide-gray-200 rounded-lg bg-white shadow md:divide-x md:divide-y-0",
                stats.length == 2? "md:grid-cols-3": "md:grid-cols-4",
            )}>
            <div className="px-6 py-2">
                <dt className="text-base font-normal text-gray-900">
                    Anchor Document
                </dt>
                <dd className="mt-1 flex items-baseline justify-between md:block lg:flex">
                    <div className="flex items-baseline text-2xl font-semibold text-indigo-600">
                        <Dropdown
                            header="Select Anchor"
                            value={policy.name}
                            align="left">
                            {policies.map( (item, idx) => (
                                <DropdownItem
                                    id={item.uid}
                                    key={item.uid}
                                    value={item.uid}
                                    onClick={onPolicy}>
                                    {item.name}
                                </DropdownItem>
                            ))}
                        </Dropdown>
                    </div>
                </dd>
            </div>

            {stats.map( ( item ) => {
                const change = ('total' in item && item.total !== 0) ? item.stat / item.total : null
                return (<div key={item.name} className="px-6 py-2">
                    <dt className="text-base font-normal text-gray-900">
                        {item.name}
                    </dt>
                    <dd className="mt-1 flex items-baseline justify-between md:block lg:flex">
                        <div className="flex items-baseline text-2xl font-semibold text-indigo-600">
                            {item.stat}
                            {item.children}
                        </div>

                        {change !== null &&
                            <PillChange
                                value={Util.toPercentage( Math.abs( change ) )}
                                change={change}
                                type={item.isGood( change ) ? PillType.GOOD : PillType.BAD}
                            />
                        }
                        {'value' in item && item.value !== null &&
                            <PillChange
                                value={Math.abs( item.value )}
                                change={item.value}
                                type={item.isGood( item.value ) ? PillType.GOOD : PillType.BAD}
                            />
                        }
                    </dd>
                </div>)
            })}
        </dl>
    )
}