import {ArrowDownIcon, ArrowsUpDownIcon, ArrowUpIcon} from "@heroicons/react/20/solid";
import {ArrowPathIcon, TagIcon, TrashIcon} from "@heroicons/react/24/outline";
import { PillChange, simpleValue } from "./pill_change";
import { UserRatingModal } from "../modals/user_rating_modal";
import React, { useState } from "react";
import {Tooltip} from "./tooltip";
import * as Util from "../helpers/util";
import {PlusIcon} from "@heroicons/react/24/solid";
import * as Consts from "../helpers/consts";
import {Dropdown, DropdownCheckbox, DropdownItem} from "./dropdown";
import { ButtonOptions } from "./button_options";
import {ConfirmModal} from "../modals/confirm_modal";


const PromptOptions = ["Add Prompt", "Delete Claim"]
const ADD_PROMPT = 0
const DELETE_CLAIM = 1

export const PillarConfig = (props) => {
    const {pillar, claim, tags, onChangeClaim, onChangePrompt, onRemovePrompt, onRemoveClaim, onAddPrompt, showToast} = props

    const [state, setState] = useState({
        show_delete_prompt: null,
        show_delete_claim: null,
        show_user_rating: false,
        show_tags: false,
    })
    const { show_delete_prompt, show_delete_claim, show_user_rating, show_tags } = state

    const handleChangeTag = (tag, checked) => {
        const new_tags = claim.tags.filter(t => t.uid !== tag.uid)
        if (checked) {
            new_tags.push(tag)
        }
        onChangeClaim({target: {id: 'tags', value: new_tags}})
    }

    const handleChangeUserRating = (idx, value) => {
        setState(prev => ({...prev,
            show_user_rating: false,
        }))

        if (value == null) {
            return
        }

        onChangeClaim({target:{id:'rating', value}})
    }

    const handlePrompt = (option) => {
        console.log(option)
        if (option === ADD_PROMPT) {
            onAddPrompt()
        } else if (option === DELETE_CLAIM) {
            setState(prev => ({...prev, show_delete_claim: claim.uid}))
        }
    }

    const handleDeletePrompt = (idx) => {
        if (claim.prompts[idx].query === "") {
            onRemovePrompt( idx )
        }
        else {
            setState( prev => ({ ...prev, show_delete_prompt: idx }) )
        }
    }

    const handleShowTags = () => {
        setState(prev => ({...prev,
            show_tags: true
        }))
    }

    return (
        <div className="col-span-full" key={claim.uid}>
            <div className="w-[calc(100%+2rem)] -ml-4 sm:w-[calc(100%+4rem)] sm:-ml-8 border-t border-gray-200"></div>

            <div className="flex flex-row justify-between mt-6 mb-4">
                <p className="inline-flex items-center text-sm font-bold leading-6 text-indigo-600 cursor-pointer px-2 ring-1 ring-indigo-200 rounded-full bg-gray-50"
                   onClick={() => onChangeClaim({target: {id: 'relevance', value: !claim.relevance}})}>
                    <ArrowPathIcon className="h-4 w-4 mr-2 text-indigo-600 flex-shrink-0"/>
                    {(claim.relevance) ? "Relevance Test" : "Claim"}
                </p>

                <div className="inline-flex text-sm leading-6 items-center">
                    {claim.relevance && <>
                        Apply this pillar if
                    </>}
                    {!claim.relevance && <>
                        Apply a rating of
                        <PillChange
                            className={Util.classNames(
                                "mx-2 cursor-pointer",
                                (claim.rating > 0) ? 'ring-1 ring-green-200' : '',
                                (claim.rating < 0) ? 'ring-1 ring-red-200' : '',
                            )}
                            {...simpleValue(claim.rating)}
                            onClick={() => setState(prev => ({...prev, show_user_rating: true}))}
                        />
                        if
                    </>}
                    <p className="mx-2 px-2 inline-flex items-center font-bold leading-6 text-indigo-600 cursor-pointer ring-1 ring-indigo-200 rounded-full bg-gray-50"
                       onClick={() => onChangeClaim({ target: { id: 'match_type', value: (claim.match_type === 'All' ? 'Any' : 'All') }})}>
                        <ArrowPathIcon
                            className="h-4 w-4 mr-1 text-indigo-600 flex-shrink-0"/>
                        {claim.match_type}
                    </p>
                    match
                </div>
            </div>
            {claim.prompts.map((prompt, pr_idx) => (
                <div key={`prompt_${pillar.uid}_${pr_idx}`}
                     className="relative flex flex-1 mt-2 text-gray-600 items-center">
                    {!prompt.inverted &&
                        <div
                            className="absolute group insert-y-0 left-2 h-6 w-6 bg-green-50 ring-1 rounded-full ring-green-600 cursor-pointer"
                            onClick={() => onChangePrompt(pr_idx, {target: {id: 'inverted', value: true}})}>
                            <ArrowUpIcon className="m-1 h-4 w-4 text-green-600 shrink-0"/>

                            <Tooltip tooltip="Match if affirming"/>
                        </div>
                    }
                    {prompt.inverted &&
                        <div
                            className="absolute group insert-y-0 left-2 h-6 w-6 bg-red-50 ring-1 rounded-full ring-red-600 cursor-pointer"
                            onClick={() => onChangePrompt(pr_idx, {target: {id: 'inverted', value: false}})}>
                            <ArrowDownIcon className="m-1 h-4 w-4 text-red-600 shrink-0"/>

                            <Tooltip tooltip="Match if false"/>
                        </div>
                    }
                    <input
                        type="text"
                        className="px-10 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        id="query"
                        value={prompt.query}
                        placeholder="Prompt to match against the claim"
                        onChange={e => onChangePrompt(pr_idx, e)}
                        onBlur={e => onChangePrompt(pr_idx, e, true)}
                    />
                    <TrashIcon
                        className="absolute insert-y-2 right-2 h-5 w-5 text-red-600 shrink-0 cursor-pointer"
                        onClick={() => handleDeletePrompt(pr_idx)}
                    />
                </div>
            ))}

            <div className="w-full flex flex-row justify-between mt-4">
                <div className="inline-flex h-6 items-center cursor-pointer">
                    {show_tags &&
                        <Dropdown
                            show={show_tags}
                            header="Tags"
                            align="left"
                            onClose={() => setState(prev => ({...prev, show_tags: false}))}>
                            {tags.length === 0 &&
                                <DropdownItem
                                    icon={TagIcon}>
                                    No tags available
                                </DropdownItem>
                            }
                            {tags.map((tag, idx) => (
                                <DropdownCheckbox
                                    key={`tag_${idx}`}
                                    name={`quick_tag_${idx}`}
                                    checked={claim.tags.map(t => t.uid).includes(tag.uid)}
                                    onChange={e => handleChangeTag(tag, e.target.checked)}>
                                    <p className={Util.classNames(Consts.TextColor(tag.color.toLowerCase()),)}>
                                        {tag.name}
                                    </p>
                                </DropdownCheckbox>))}
                        </Dropdown>
                    }

                    <p className="text-sm font-bold leading-6 text-indigo-600 px-2 mr-2 ring-1 ring-indigo-200 rounded-full bg-gray-50"
                       onClick={handleShowTags}>
                        Tags
                    </p>

                    {claim.tags.slice(0,4).map((tag, idx) => {
                        if (idx < 3 || idx == 3 && claim.tags.length == 4) {
                            return (<p key={`tag_list_${idx}`}
                                       className={Util.classNames( Consts.TextColor( tag.color.toLowerCase() ), 'ml-1' )}
                                       onClick={handleShowTags}>
                                {`${tag.name}${idx + 1 < claim.tags.length ? ', ' : ''}`}
                            </p>)
                        }
                        else {
                            return (<p key={`tag_list_${idx}`}
                                       className='ml-1'
                                       onClick={handleShowTags}>
                                ... +{claim.tags.length - 3} more
                            </p>)
                        }
                    })}
                </div>

                <ButtonOptions
                    align="right"
                    options={PromptOptions}
                    onClick={handlePrompt}>
                    <PlusIcon className="mr-1 h-5 w-5 shrink-0"
                              aria-hidden="true"/>
                    Prompt
                </ButtonOptions>
            </div>

            <ConfirmModal
                open={show_delete_prompt != null}
                uid={show_delete_prompt}
                danger={true}
                title="Delete Pillar?"
                message="Are you sure you want to delete this prompt?"
                keyState="show_delete_prompt"
                setState={setState}
                confirmBtn="Delete"
                onConfirm={uid => onRemovePrompt(uid)}
            />

            <ConfirmModal
                open={show_delete_claim != null}
                uid={show_delete_claim}
                danger={true}
                title="Delete Claim?"
                message="Are you sure you want to delete entire claim?"
                keyState="show_delete_claim"
                setState={setState}
                confirmBtn="Delete"
                onConfirm={() => onRemoveClaim()}
            />

            <UserRatingModal
                uid={show_user_rating}
                open={show_user_rating}
                value={claim.rating}
                onClose={handleChangeUserRating}
                showToast={showToast}
            />
        </div>
    )
}
