import React, { Fragment, useState } from 'react'
import { Dialog, Disclosure, Menu, Popover, Transition } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import {ChevronDownIcon, ChevronUpIcon} from '@heroicons/react/20/solid'
import * as Util from '../helpers/util'
import { Dropdown, DropdownItem } from "./dropdown";
import {CircleColor, COLORS, TextColor} from "../helpers/consts";


export const LegislationFilter = props => {
    const { /*governments, government_uid,*/ sessions, session_uid, policies, policy_uid, tags, selected_tags, selected_colors, sorting, onSort,/* onGovernmentChange,*/ onPolicyChange, onTagChange, onColorChange, onSessionChange } = props

    const [open, setOpen] = useState(false)

    //const government = governments.find( gov => gov.uid === government_uid ) || { uid: null, name: 'Government'}
    const policy = policies.find( pol => pol.uid === policy_uid ) || { uid: null, name: 'Empty'}
    const session = sessions.find( ses => ses.uid === session_uid ) || { uid: null, name: 'Session'}

    const handleTagChecked = (name, checked) => {
        if ( tags.findIndex( x => x.uid === name ) < 0 ) {
            return
        }

        const selected = { ...props.selected_tags }
        if ( checked ) {
            selected[name] = true
        }
        else {
            delete selected[name]
        }
        onTagChange(selected)
    }

    const handleColorChecked = (name, checked) => {
        if ( COLORS.indexOf(name) < 0 ) {
            return
        }

        const selected = { ...props.selected_colors }
        if ( checked ) {
            selected[name] = true
        }
        else {
            delete selected[name]
        }

        onColorChange(selected)
    }

    const handleUnchecked = (name) => {
        if ( name == null ) {
            onTagChange({})
            onColorChange({})
            return
        }

        handleTagChecked(name, false)
        handleColorChecked(name, false)
    }

    const filters = [
        /*
        {
            id: 'government',
            name: 'Governments',
            title: 'Select Government',
            type: 'dropdown',
            value: government.name,
            onClick: onGovernmentChange,
            options: governments,
        },
         */
        /*
        {
            id: 'policy',
            uid: 'policy_uid',
            name: 'Anchors',
            title: 'Select Anchor',
            type: 'dropdown',
            value: policy.name,
            onClick: onPolicyChange,
            options: policies,
        },
        */
        {
            id: 'tag',
            name: 'Tags',
            title: 'Select Tag(s)',
            type: 'checkbox',
            empty: 'Please create a tag.',
            count: Object.keys(selected_tags).length,
            onClick: handleTagChecked,
            options: tags.map( tag => ({
                name: tag.uid,
                label: tag.name,
                checked: (tag.uid in selected_tags),
            }) ),
        },
        /*
        {
            id: 'session',
            name: 'Sessions',
            title: 'Select Session',
            type: 'dropdown',
            value: session.name,
            onClick: onSessionChange,
            options: sessions,
        },
         */
        {
            id: 'color',
            name: 'Colors',
            title: 'Select Color(s)',
            type: 'checkbox',
            count: Object.keys(selected_colors).length,
            onClick: handleColorChecked,
            options: COLORS.filter(x => x !== 'none').map( color => ({
                name: color,
                label: (<div className="inline-flex items-center gap-2">{CircleColor(color)} {Util.capitalize(color)}</div>),
                checked: (color in selected_colors),
            })),
        },
    ]

    const activeFilters = tags.filter( x => x.uid in selected_tags ).map( x => ({ value: x.uid, label: x.name, klass: TextColor(x.color) }) )
        .concat(
            COLORS.filter( x => x !== 'none' && x in selected_colors ).map( x => ({ value: x, label: (<div className="inline-flex items-center gap-2">{CircleColor(x)} {Util.capitalize(x)}</div>) }) )
        )

    const icon = {
        false: (<ChevronDownIcon className="h-5 w-5" aria-hidden="true"/>),
        true: (<ChevronUpIcon className="h-5 w-5" aria-hidden="true"/>),
    }

    const sortOptions = ['Code', 'Title', 'Priority', 'Status', 'Newest', 'Rating'].map((x) => ({
        name: x,
        asc: sorting.asc,
        current: sorting.field === x.toLowerCase(),
    }))

    return (
        <div className="bg-white">
            {/* Mobile filter dialog */}
            <Transition.Root show={open} as={Fragment}>
                <Dialog as="div" className="relative z-40 sm:hidden" onClose={setOpen}>
                    <Transition.Child
                        as={Fragment}
                        enter="transition-opacity ease-linear duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="transition-opacity ease-linear duration-300"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-black bg-opacity-25" />
                    </Transition.Child>

                    <div className="fixed inset-0 z-40 flex">
                        <Transition.Child
                            as={Fragment}
                            enter="transition ease-in-out duration-300 transform"
                            enterFrom="translate-x-full"
                            enterTo="translate-x-0"
                            leave="transition ease-in-out duration-300 transform"
                            leaveFrom="translate-x-0"
                            leaveTo="translate-x-full"
                        >
                            <Dialog.Panel className="relative ml-auto flex h-full w-full max-w-xs flex-col overflow-y-auto bg-white py-4 pb-12 shadow-xl">
                                <div className="flex items-center justify-between px-4">
                                    <h2 className="text-lg font-medium text-gray-900">Filters</h2>
                                    <button
                                        type="button"
                                        className="-mr-2 flex h-10 w-10 items-center justify-center rounded-md bg-white p-2 text-gray-400"
                                        onClick={() => setOpen(false)}
                                    >
                                        <span className="sr-only">Close menu</span>
                                        <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                                    </button>
                                </div>

                                {/* Mobile Filters */}
                                <form className="mt-4">
                                    {filters.map((section, idx) => (
                                        <div key={`filters_${idx}`}>
                                            {section.type === "dropdown" &&
                                            <Dropdown
                                                className="mx-4"
                                                header={section.header}
                                                value={section.value}
                                                align="left">
                                                {section.options.map( (item, idx) => (
                                                    <DropdownItem
                                                        id={section.uid}
                                                        key={`yo_${section.id}_${idx}`}
                                                        value={item.uid}
                                                        onClick={section.onClick}>
                                                        {item.name}
                                                    </DropdownItem>
                                                ))}
                                            </Dropdown>
                                            }
                                            {section.type === "checkbox" &&
                                            <Disclosure as="div" key={section.name} className="border-t border-gray-200 px-4 py-6">
                                                {({ open }) => (
                                                    <>
                                                        <h3 className="-mx-2 -my-3 flow-root">
                                                            <Disclosure.Button className="flex w-full items-center justify-between bg-white px-2 py-3 text-sm text-gray-400">
                                                                <span className="font-medium text-gray-900">
                                                                    {section.name}
                                                                </span>
                                                                <span className="ml-6 flex items-center">
                                                                    <ChevronDownIcon
                                                                        className={Util.classNames(open ? '-rotate-180' : 'rotate-0', 'h-5 w-5 transform')}
                                                                        aria-hidden="true"
                                                                    />
                                                                </span>
                                                            </Disclosure.Button>
                                                        </h3>
                                                        <Disclosure.Panel className="pt-6">
                                                            <div className="space-y-6">
                                                                {section.options.length === 0 &&
                                                                <div className="flex items-center">
                                                                    <p className="ml-3 text-sm text-gray-500">
                                                                        {section.empty}
                                                                    </p>
                                                                </div>
                                                                }
                                                                {section.options.map( ( option, optionIdx ) => (
                                                                    <div
                                                                        key={`bob_${option.name}`}
                                                                        className="flex items-center">
                                                                        <input
                                                                            id={option.name}
                                                                            name={option.name}
                                                                            type="checkbox"
                                                                            checked={option.checked}
                                                                            onChange={e => section.onClick( option.name, e.target.checked )}
                                                                            className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                                                        />
                                                                        <label
                                                                            htmlFor={option.name}
                                                                            className="ml-3 text-sm text-gray-500" >
                                                                            {option.label}
                                                                        </label>
                                                                    </div>) )}
                                                            </div>
                                                        </Disclosure.Panel>
                                                    </>)}
                                            </Disclosure>}
                                        </div>) )}
                                </form>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </Dialog>
            </Transition.Root>

            {/* Filters */}
            <section aria-labelledby="filter-heading">
                <h2 id="filter-heading" className="sr-only">
                    Filters
                </h2>

                <div className="border-b border-gray-200 bg-white pb-4">
                    <div
                        className="mx-auto flex max-w-7xl items-center justify-between px-4 sm:px-6 lg:px-8">
                        <Menu as="div"
                              className="relative inline-block text-left">
                            <div>
                                <Menu.Button
                                    className="group inline-flex justify-center text-sm font-medium text-gray-700 hover:text-gray-900">
                                    Sort
                                    {sorting.field !== '' && sorting.field !== 'code' && ` by ${Util.capitalize(sorting.field.replace('_', ' '))}`}
                                    <ChevronDownIcon
                                        className="-mr-1 ml-1 h-5 w-5 flex-shrink-0 text-gray-400 group-hover:text-gray-500"
                                        aria-hidden="true"
                                    />
                                </Menu.Button>
                            </div>

                            <Transition
                                as={Fragment}
                                enter="transition ease-out duration-100"
                                enterFrom="transform opacity-0 scale-95"
                                enterTo="transform opacity-100 scale-100"
                                leave="transition ease-in duration-75"
                                leaveFrom="transform opacity-100 scale-100"
                                leaveTo="transform opacity-0 scale-95" >
                                <Menu.Items className="absolute left-0 z-10 mt-2 w-40 origin-top-left rounded-md bg-white shadow-2xl ring-1 ring-black ring-opacity-5 focus:outline-none">
                                    <div className="py-1">
                                        {sortOptions.map((option) => (
                                            <Menu.Item key={`bz_${option.name}`}
                                                       onClick={() => onSort(option.name)}>
                                                {({ active }) => (
                                                    <div className={Util.classNames(
                                                            option.current ? 'font-medium text-gray-900' : 'text-gray-500',
                                                            active ? 'bg-gray-100' : '',
                                                            'block px-4 py-2 text-sm',
                                                            "flex flex-row justify-between",
                                                        )}>
                                                        {option.name}
                                                        {option.current && icon[!!option.asc]}
                                                    </div>
                                                )}
                                            </Menu.Item>
                                        ))}
                                    </div>
                                </Menu.Items>
                            </Transition>
                        </Menu>

                        <button
                            type="button"
                            className="inline-block text-sm font-medium text-gray-700 hover:text-gray-900 sm:hidden"
                            onClick={() => setOpen(true)} >
                            Filters
                        </button>

                        {/* Desktop filters */}
                        <div className="hidden sm:block">
                            <div className="flow-root">
                                <Popover.Group className="-mx-4 flex items-center divide-x divide-gray-200">
                                    {filters.map((section, idx) => (
                                        <div key={`filterzm_${idx}`}>
                                            {section.type === "dropdown" &&
                                            <Dropdown
                                                className="mx-4"
                                                header={section.name}
                                                value={section.value}
                                                align="left">
                                                {section.options.map( (item, idx) => (
                                                    <DropdownItem
                                                        id={section.uid}
                                                        key={`m${section.id}_${idx}`}
                                                        value={item.uid}
                                                        onClick={section.onClick}>
                                                        {item.name}
                                                    </DropdownItem>
                                                ))}
                                            </Dropdown>
                                            }
                                            {section.type === "checkbox" &&
                                            <Popover key={section.name} className="relative inline-block px-4 text-left">
                                                <Popover.Button className="group inline-flex justify-center text-sm font-semibold text-gray-700 hover:text-gray-900 ring-1 ring-gray-300 rounded py-1.5 px-2 shadow-sm hover:bg-gray-50">
                                                    <span>{section.name}</span>
                                                    {section.count > 0 &&
                                                        <span className="ml-1.5 rounded bg-gray-200 px-1.5 py-0.5 text-xs font-semibold tabular-nums text-gray-700">
                                                            {section.count}
                                                        </span>
                                                    }
                                                    <ChevronDownIcon
                                                        className="-mr-1 ml-1 h-5 w-5 flex-shrink-0 text-gray-400 group-hover:text-gray-500"
                                                        aria-hidden="true"
                                                    />
                                                </Popover.Button>

                                                <Transition
                                                    as={Fragment}
                                                    enter="transition ease-out duration-100"
                                                    enterFrom="transform opacity-0 scale-95"
                                                    enterTo="transform opacity-100 scale-100"
                                                    leave="transition ease-in duration-75"
                                                    leaveFrom="transform opacity-100 scale-100"
                                                    leaveTo="transform opacity-0 scale-95"
                                                >
                                                    <Popover.Panel className="absolute right-0 z-10 mt-2 origin-top-right rounded-md bg-white p-4 shadow-2xl ring-1 ring-black ring-opacity-5 focus:outline-none">
                                                        <form className="space-y-4">
                                                            {section.options.length === 0 &&
                                                                <div className="flex flex-col items-center">
                                                                    <p className="ml-3 whitespace-nowrap pr-6 font-semibold text-gray-900">
                                                                        No {section.name}
                                                                    </p>
                                                                    <p className="ml-3 whitespace-nowrap pr-6 text-sm font-medium text-gray-900">
                                                                        {section.empty}
                                                                    </p>
                                                                </div>
                                                            }
                                                            {section.options.length !== 0 &&
                                                                <div
                                                                    className="px-4 py-3 border-b border-gray-300 w-40">
                                                                <p className="text-sm font-semibold text-indigo-600">
                                                                    {section.title}
                                                                </p>
                                                            </div>
                                                            }
                                                            {section.options.map( ( option, optionIdx ) => (
                                                                <div key={`xx_${option.name}`}
                                                                    className="flex items-center">
                                                                    <input
                                                                        id={option.name}
                                                                        name={section.name}
                                                                        type="checkbox"
                                                                        checked={option.checked}
                                                                        onChange={e => section.onClick( option.name, e.target.checked )}
                                                                        className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                                                    />
                                                                    <label
                                                                        htmlFor={option.name}
                                                                        className="ml-3 whitespace-nowrap pr-6 text-sm font-medium text-gray-900">
                                                                        {option.label}
                                                                    </label>
                                                                </div>) )}
                                                        </form>
                                                    </Popover.Panel>
                                                </Transition>
                                            </Popover>}
                                        </div>) )}
                                </Popover.Group>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Active filters */}
                {activeFilters.length > 0 &&
                <div className="bg-gray-100">
                    <div
                        className="mx-auto max-w-7xl px-4 py-3 sm:flex sm:items-center sm:px-6 lg:px-8">
                        <h3 className="text-sm font-medium text-gray-500 cursor-pointer"
                            onClick={() => handleUnchecked(null)}>
                            Clear Filters
                            <span className="sr-only">, active</span>
                        </h3>

                        <div aria-hidden="true"
                             className="hidden h-5 w-px bg-gray-300 sm:ml-4 sm:block"/>

                        <div className="mt-2 sm:ml-4 sm:mt-0">
                            <div className="-m-1 flex flex-wrap items-center">
                                {activeFilters.map( ( activeFilter, a_idx ) => (
                                    <span key={`yx_${a_idx}`}
                                          className="m-1 inline-flex items-center rounded-full border border-gray-200 bg-white py-1.5 pl-3 pr-2 text-sm font-medium text-gray-900">
                                        <span className={activeFilter.klass}>{activeFilter.label}</span>
                                        <button
                                            type="button"
                                            className="ml-1 inline-flex h-4 w-4 flex-shrink-0 rounded-full p-1 text-gray-400 hover:bg-gray-200 hover:text-gray-500"
                                            onClick={() => handleUnchecked(activeFilter.value) }>
                                            <span className="sr-only">Remove filter for {activeFilter.label}</span>
                                            <svg className="h-2 w-2" stroke="currentColor" fill="none" viewBox="0 0 8 8">
                                                <path strokeLinecap="round" strokeWidth="1.5" d="M1 1l6 6m0-6L1 7" />
                                            </svg>
                                        </button>
                                    </span>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
                }
            </section>
        </div>
    )
}
