import React, { useState, useRef, useEffect } from "react"
import * as Util from "../helpers/util.js"
import { Link, useNavigate, useParams } from "react-router-dom";

import { closeWS, connectWS, sendWS } from "../helpers/util.js";
import { WS_URL } from "../settings";
import {
    BellAlertIcon,
    BellIcon,
    CpuChipIcon,
    DocumentChartBarIcon,
    FireIcon, ScaleIcon,
    TagIcon
} from "@heroicons/react/24/outline";
import {
    Dropdown, DropdownCheckbox,
    DropdownItem
} from "../components/dropdown";
import { LegislationFeedTab } from "./legislation_feed_tab";
import {LegislationDetailTab} from "./legislation_detail_tab";
import { LegislationChat } from "./legislation_chat";
import { LegislationRatingTab } from "./legislation_rating_tab";
import { PageTitle } from "../components/page_title";
import { useStore } from "../store";
import { Banner } from "../components/banner";
import { LegislationText } from "./legislation_text";
import {Spinner} from "../components/spinner";
import { PillChange, PillType, simpleValue } from "../components/pill_change";
import { DocumentDuplicateIcon, StarIcon } from "@heroicons/react/24/solid";
import { Sortable } from "../components/sortable";
import * as Consts from "../helpers/consts";
import { ImportFileModal } from "../modals/import_file_modal";
import { UserRatingModal } from "../modals/user_rating_modal";
import { ConfirmModal } from "../modals/confirm_modal";
import { Pagination } from "../components/pagination";
import { EmptyList } from "../components/empty_list";


export const Health = (props) => {
    const { showToast } = props

    const [state, setState] = useState( {
        gpu_count: 0,
        job_count: 0,
        public_jobs: [],
        ready: false,
        connection_error: false,
        connection_retry: 0,
    } )
    const { gpu_count, job_count, public_jobs, ready, connection_error, connection_retry } = state

    const [socket, setSocket] = useState( null );

    useEffect( () => {
        const _socket = connectWS( `${WS_URL}/ws/cluster`, setSocket, {
            'health': recvHealth,
        } )

        //Track the state of the socket
        _socket.onopen = () => {
            console.log( "Socket connected" )
            if ( connection_retry > 0 ) {
                showToast( "Connection re-established.", "successful" )
            }
            setState( prev => ({ ...prev, ready: true }) )
        }
        _socket.onclose = () => {
            console.log( 'Socket closed')
            setState( prev => ({ ...prev,
                ready: false,
                connection_error: true,
            }) )

            setTimeout( () => {
                setState( prev => ({ ...prev, connection_retry: prev.connection_retry + 1 }))
            }, 5000 )
        }

        return () => {
            console.log("Calling close")
            closeWS( _socket )
        }
    }, [connection_retry] );

    useEffect( () => {
        if ( !ready ) {
            return
        }

        const interval = setInterval(() => {
            sendWS( socket, 'health', {} )
        }, 1000);

        // Clean up the interval on component unmount
        return () => {
            clearInterval(interval);
        };
    }, [ready])

    const recvHealth = ( { gpu_count, job_count, public_jobs } ) => {
        setState( prev => ({ ...prev,
            gpu_count,
            job_count,
            public_jobs,
        }) )
    }

    return (<>
        {!ready && connection_error && <Banner
            message={"AI Server connection failed."}
            color={"bg-red-600"}
            onDismiss={() => {
                setState( prev => ({ ...prev, connection_error: false }) )
            }}
        />}

        <PageTitle
            title="Cluster Health"
            subtitle="Monitor the health of the AI server cluster."
        />

        <dl className="mt-8 grid grid-cols-1 divide-y divide-gray-200 overflow-hidden rounded-lg bg-white shadow md:grid-cols-3 md:divide-x md:divide-y-0">
            <div className="px-6 py-2">
                <dt className="text-base font-normal text-gray-900 inline-flex">
                    <CpuChipIcon className="h-6 w-6 mr-2 text-gray-500"/>
                    Processing Units
                </dt>
                <dd className="mt-1 flex items-baseline justify-between md:block lg:flex">
                    <div
                        className="flex items-baseline text-2xl font-semibold text-indigo-600">
                        {gpu_count}
                    </div>
                </dd>
            </div>

            <div className="px-6 py-2">
                <dt className="text-base font-normal text-gray-900 inline-flex">
                    <FireIcon className="h-6 w-6 mr-2 text-gray-500"/>
                    Active Jobs
                </dt>
                <dd className="mt-1 flex items-baseline justify-between md:block lg:flex">
                    <div
                        className="flex items-baseline text-2xl font-semibold text-indigo-600">
                        {job_count}
                    </div>
                </dd>
            </div>

            <div className="px-6 py-2">
                <dt className="text-base font-normal text-gray-900 inline-flex">
                    <DocumentChartBarIcon
                        className="h-6 w-6 mr-2 text-gray-500"/>
                    Rating Jobs
                </dt>
                <dd className="mt-1 flex items-baseline justify-between md:block lg:flex">
                    <div
                        className="flex items-baseline text-2xl font-semibold text-indigo-600">
                        {public_jobs.length}
                    </div>
                </dd>
            </div>
        </dl>

        <div className="px-4 sm:px-6 lg:px-8">
            <div className="mt-8 flow-root">
                <div className="-mx-4 -my-2 sm:-mx-6 lg:-mx-8">
                    <div className="inline-block min-w-full py-2 align-middle">
                        <table className="min-w-full border-separate border-spacing-0">
                            <thead>
                            <tr>
                                <th scope="col"
                                    className="py-3.5 text-left text-sm text-gray-900 border-b sm:pl-6 lg:pl-8">
                                    <div className="group inline-flex">
                                        Bill Code
                                    </div>
                                </th>
                                <th scope="col"
                                    className="py-3.5 text-left text-sm text-gray-900 border-b sm:pl-6 lg:pl-8">
                                    <div className="group inline-flex">
                                        Title
                                    </div>
                                </th>
                                <th scope="col"
                                    className="py-3.5 text-left text-sm text-gray-900 border-b sm:pl-6 lg:pl-8">
                                    <div className="group inline-flex">
                                        Version
                                    </div>
                                </th>
                                <th scope="col"
                                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 hidden sm:table-cell border-b">
                                    <div
                                        className="group inline-flex">
                                        Government
                                    </div>
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            {public_jobs.map( ( job, idx ) => (
                                <tr key={`document_tr_${idx}`}>
                                    <td className={Util.classNames( idx !== public_jobs.length - 1 ? 'border-b border-gray-200' : '', 'whitespace-nowrap py-4 pl-4 pr-3 text-sm text-gray-900 sm:pl-6 lg:pl-8' )}>
                                        {job.document}
                                    </td>
                                    <td className={Util.classNames( idx !== public_jobs.length - 1 ? 'border-b border-gray-200' : '', 'whitespace-wrap hidden px-3 py-4 text-sm text-gray-500 sm:table-cell' )}>
                                        {job.title}
                                    </td>
                                    <td className={Util.classNames( idx !== public_jobs.length - 1 ? 'border-b border-gray-200' : '', 'whitespace-wrap hidden px-3 py-4 text-sm text-gray-500 sm:table-cell' )}>
                                        {job.version}
                                    </td>
                                    <td className={Util.classNames( idx !== public_jobs.length - 1 ? 'border-b border-gray-200' : '', 'whitespace-wrap hidden px-3 py-4 text-sm text-gray-500 sm:table-cell' )}>
                                        {job.government}
                                    </td>
                                </tr>))}
                            </tbody>
                        </table>

                        {public_jobs.length === 0 &&
                            <div className="pb-44">
                                <EmptyList
                                    icon={DocumentChartBarIcon}
                                    name="rating jobs"
                                />
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    </>)
}
