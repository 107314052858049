import React, {
    Fragment, useEffect, useState
} from 'react'
import { Dialog, Transition } from '@headlessui/react'
import {
    AdjustmentsVerticalIcon,
    DocumentTextIcon,
    XMarkIcon
} from '@heroicons/react/24/outline'
import * as Util from "../helpers/util";
import {
    convertRating,
    PillChange,
    PillType, simpleValue
} from "../components/pill_change";
import { RatingOverlap } from "../helpers/consts";


export const UserRatingModal = props => {
    const { uid, open, onClose, showToast } = props
    const value_min = props.value_min || -10
    const value_max = props.value_max || 10
    const title = props.title || "User Ratings"

    const initial_state = {
        value: props.value,
    }

    const [state, setState] = useState(initial_state)
    const { value } = state

    useEffect(() => {
        setState( initial_state )
    }, [open])

    const handleChange = (e) => {
        setState( prev => ({ ...prev,
            [e.target.name]: e.target.value
        }))
    }

    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" className="relative z-40" onClose={() => onClose(uid, null)}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-40 w-screen overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel
                                className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                                <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                                    <button
                                        type="button"
                                        className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none"
                                        onClick={() => onClose( uid, null )}>
                                        <span className="sr-only">Close</span>
                                        <XMarkIcon className="h-6 w-6"
                                                   aria-hidden="true"/>
                                    </button>
                                </div>
                                <div className="sm:flex sm:items-start">
                                    <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-green-100 sm:mx-0 sm:h-10 sm:w-10">
                                        <AdjustmentsVerticalIcon
                                            className="h-6 w-6 text-black flex-shrink-0"
                                            aria-hidden="true"/>
                                    </div>
                                    <div className="mt-3 text-center sm:mx-4 sm:mt-0 sm:text-left w-full">
                                        <Dialog.Title as="h3"
                                                      className="text-base font-semibold leading-6 text-gray-900 flex items-center">
                                            {title}
                                            <PillChange
                                                className="ml-20"
                                                {...simpleValue( value )}
                                                onClick={() => setState( prev => ({
                                                    ...prev, show_user_rating: 5
                                                }) )}
                                            />
                                        </Dialog.Title>
                                        <div className="mt-3 w-full">
                                            <input id="steps-range"
                                                   name="value"
                                                   type="range"
                                                   min={value_min}
                                                   max={value_max}
                                                   value={value}
                                                   step="1"
                                                   onChange={handleChange}
                                                   className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer dark:bg-gray-700"/>

                                            <p className="mt-1 text-sm text-gray-500">
                                                Override the bill rating
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                                    <button
                                        type="button"
                                        className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 sm:ml-3 sm:w-auto"
                                        onClick={() => onClose( uid, value )}>
                                        Update
                                    </button>
                                    <button
                                        type="button"
                                        className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                                        onClick={() => onClose(uid, null)}>
                                        Cancel
                                    </button>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
}
