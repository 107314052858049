import { Link } from "react-router-dom";
import Logo from "../assets/images/logo.png";
import ReactMarkdown from 'react-markdown';

export const TermsService = props => {
    const markdown = `
# AnchorDoc Terms of Service

## 1. Acceptance of Terms

By accessing or using AnchorDoc ("the Service"), you agree to be bound by these Terms of Service ("Terms"). If you disagree with any part of the terms, you may not use the Service.

## 2. Description of Service

AnchorDoc is a document management and conversion service that allows users to store, convert, and manage various document formats.

## 3. User Responsibilities

3.1. You are responsible for maintaining the confidentiality of your account and password.

3.2. You agree to use the Service in compliance with all applicable local, state, and federal laws.

3.3. AnchorDoc does not endorse any illegal behavior. Users engaging in illegal activities through the Service will have their accounts terminated.

## 4. Data Storage and Conversion

4.1. AnchorDoc keeps converted document formats for LLM (Large Language Model) consumption for the purpose of caching.

4.2. By using the Service, you grant AnchorDoc the right to store and process your documents as necessary to provide the Service.

## 5. Privacy and Data Protection

5.1. AnchorDoc makes a best effort attempt to protect private data. However, AnchorDoc is not liable for data breaches or unauthorized access to user data.

5.2. Users are advised to review our Privacy Policy for detailed information on data collection and usage practices.

## 6. Intellectual Property

6.1. Users retain all ownership rights to their original content uploaded to the Service.

6.2. By using the Service, you grant AnchorDoc a non-exclusive license to use, reproduce, and process your content solely for the purpose of providing and improving the Service.

## 7. Limitation of Liability

7.1. AnchorDoc provides the Service on an "as is" and "as available" basis. We do not guarantee that the Service will be uninterrupted, timely, secure, or error-free.

7.2. In no event shall AnchorDoc be liable for any indirect, incidental, special, consequential or punitive damages arising out of or relating to your use of the Service.

## 8. Modifications to Service and Terms

8.1. AnchorDoc reserves the right to modify or discontinue the Service at any time without notice.

8.2. We may revise these Terms from time to time. The most current version will always be posted on our website.

## 9. Termination

9.1. AnchorDoc may terminate or suspend your account and access to the Service immediately, without prior notice or liability, for any reason.

9.2. Upon termination, your right to use the Service will immediately cease.

## 10. Governing Law

These Terms shall be governed and construed in accordance with the laws of [Your Jurisdiction], without regard to its conflict of law provisions.

## 11. Contact Information

If you have any questions about these Terms, please contact us at luke.dupin@anchordoc.ai.

Last updated: 2024-08-05
`;
    const {  } = props

    return (
        <>
            <div className="flex min-h-full flex-1 flex-col justify-center py-12 sm:px-6 lg:px-8">
                <div className="sm:mx-auto sm:w-full sm:max-w-md">
                    <img
                        className="mx-auto h-10 w-auto"
                        src={Logo}
                        alt="Anchor"
                    />
                    <h2 className="mt-6 text-center text-2xl font-bold leading-9 tracking-tight text-gray-500">
                        Terms of Service
                    </h2>
                </div>

                <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-[900px]">
                    <div className="bg-white px-6 py-12 sm:rounded-lg sm:px-12">
                        <ReactMarkdown className="privacy-policy">{markdown}</ReactMarkdown>
                    </div>
                </div>
            </div>
            <footer className="relative bg-white pt-8 pb-6">
                <div className="container mx-auto px-4">
                    <hr className="my-6 border-gray-400" />
                    <div className="flex flex-wrap items-center md:justify-between justify-center">
                        <div className="w-full md:w-4/12 px-4 mx-auto text-center">
                            <div className="text-sm text-gray-600 py-1">
                                <span className="pr-4 text-gray-400">Copyright © 2024 anchordoc.ai</span>
                                <Link to="/privacy" className="text-gray-600 hover:text-gray-900">Terms of Service</Link>
                                {/* <span className="px-1"> | </span><a href="/terms" className="text-gray-600 hover:text-gray-900">Terms of Service</a> */}
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </>)
}
