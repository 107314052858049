import * as Util from "../helpers/util.js"

import React, {useEffect, useRef, useState} from 'react'
import {
    EllipsisHorizontalIcon,
} from '@heroicons/react/24/solid'
import { useStore } from "../store";
import {
    PencilSquareIcon,
} from "@heroicons/react/24/outline";
import { ChatQuery } from "../components/chat_query";
import { ChatConversation } from "../components/chat_conversation";
import {closeWS, connectWS, sendWS} from "../helpers/util.js";
import {WS_URL} from "../settings";
import {Banner} from "../components/banner";
import { InputModal } from "../modals/input_modal";


export const GoogleDrive = (props) => {
    const {showToast} = props

    const {usr_info} = useStore( x => x)

    const [state, setState] = useState({
        channels: [],
        channel: null,
        open: false,

        ready: false,
        connection_error: false,
        connection_retry: -1,
    })
    const {channels, channel, open, ready, connection_error, connection_retry} = state
    const [fileTree, setFileTree] = useState([])
    const [socket, setSocket] = useState( null );
    const [ai_response, setAiResponse] = useState( null )
    const [chat, setChat] = useState({payload: {conversation: []}, human: usr_info})

    const [contentHeight, setContentHeight] = useState(0);
    const footerRef = useRef(null);

    useEffect(() => {
        const resizeContentDiv = () => {
            if ( !footerRef.current ) {
                return;
            }

            const screenHeight = window.innerHeight;
            const headerHeight = 328 //Imperical number
            const footerHeight = footerRef.current.offsetHeight;
            const newContentHeight = screenHeight - headerHeight - footerHeight;

            setContentHeight((newContentHeight < 520)? 520: newContentHeight);
        };

        resizeContentDiv(); // Call on initial render
        window.addEventListener('resize', resizeContentDiv); // Call on window resize

        return () => {
            window.removeEventListener('resize', resizeContentDiv); // Cleanup on component unmount
        };
    }, []);

    // Fetch the list of Google Drive channels
    useEffect(() => {
        Util.fetch_js("/api/slack/list/", {},
            js => {
                const channels = js.channels.map( ch => ({...ch, name: (ch.name !== '')? ch.name: 'General' }))
                setState(prev => ({...prev,
                    channels,
                    channel: (js.channels.length > 0)? js.channels[0]: null,
                    connection_retry: 0,
                }))
            }, showToast)
    }, [])

    //Get the file list
    useEffect(() => {
        if ( channel === null || (fileTree !== null && channel.uid === fileTree.channel_uid)) {
            return
        }

        setFileTree({channel_uid: channel.uid, files: []})

        //Fetch the file tree
        Util.fetch_js("/api/slack/file_tree/", { channel_uid: channel.uid },
            js => {
            console.log(js)
                setFileTree(js)
                showToast(`Fetched ${js.files.length} file(s)`, 'success')
            }, showToast)
    }, [channel])

    useEffect( () => {
        if ( connection_retry < 0 ) {
            return
        }

        const _socket = connectWS( `${WS_URL}/ws/cluster`, setSocket, {
            'enqueue_query_multi': recvQueryMulti,
        } )

        //Track the state of the socket
        _socket.onopen = () => {
            console.log( "Socket connected" )
            if ( connection_retry > 0 ) {
                showToast( "Connection re-established.", "successful" )
            }

            setState( prev => ({ ...prev, ready: true }) )
        }
        _socket.onclose = () => {
            console.log( 'Socket closed')
            setState( prev => ({ ...prev,
                ready: false,
                connection_error: true,
            }) )

            setTimeout( () => {
                setState( prev => ({ ...prev, connection_retry: prev.connection_retry + 1 }))
            }, 5000 )
        }

        return () => {
            console.log("Calling close")
            closeWS( _socket )
        }
    }, [connection_retry] );

    useEffect( () => {
        //Called on start so just ignore
        if ( ai_response === null ) {
            return
        }

        //Update the conversation and update the last index response to ai_response
        setChat( prev => ({...prev,
            human: usr_info,
            payload: {
                conversation: [
                    ...prev.payload.conversation.slice(0, prev.payload.conversation.length - 1),
                    {
                        ...prev.payload.conversation[prev.payload.conversation.length - 1],
                        response: ai_response,
                    }]
            }
        }))

    }, [ai_response] );

    const recvQueryMulti = ( { response } ) => {
        setAiResponse( response ) // Required to do this, and then "useEffect" to keep things in sync
    }

    const handleQuestion = (question) => {
        //Don't start this unless we're connected to the ai server
        if ( !ready ) {
            showToast( "Not connected to AI Server.", "failure" )
            return
        }

        const payload = {
            question,
            human_uid: usr_info.uid,
            documents: fileTree.files
        }
        sendWS( socket, 'enqueue_query_multi', payload )

        //Update the conversation and update the last index response to ai_response
        setChat( prev => ({...prev,
            human: usr_info,
            payload: {
                conversation: [ ...prev.payload.conversation,
                    {
                        uid: usr_info.uid,
                        question,
                        response: null,
                        created_on: Date.now(),
                    }]
            }
        }))
    }

    const handleCreate = () => {
        setState(prev => ({...prev,
            open: true
        }))
    }

    const handleClose = (e) => {
        setState(prev => ({...prev,
            open: false
        }))

        if ( e ) {
            handleAuthGoogle( e.target.value )
        }
    }

    const handleAuthGoogle = ( channel_name ) => {
        const qs = {
            tenant_uid: usr_info.tenant.uid,
            channel_name,
        }
        const querystring = Object.keys(qs).map( k => `${k}=${qs[k]}`).join("&")

        //Open a new tab pointing to the Google Drive /google_drive/connect/
        window.open(`/google_drive/connect/?${querystring}`, "_blank")
    }

    const handleChannel = (chan) => {
        setState(prev => ({...prev,
            channel: chan
        }))
    }

    /*
                {
                    uid: usr_info.uid,
                    question: "What is the capital of Spain?",
                    response: "Madrid",
                    created_on: Date.now(),
                },
     */

    return (
        <div>
            <InputModal
                open={open}
                title="Create a new Google Drive Channel"
                placeholder="Channel name"
                defaultValue="General"
                onClose={handleClose}
                onConfirm={handleClose}
            />

            {connection_error &&
                <Banner
                    message={"AI Server connection failed."}
                    color={"bg-red-600"}
                    onDismiss={() => {
                        setState(prev => ({...prev, connection_error: false}))
                    }}
                />
            }

            <div style={{height: `${contentHeight}px`}} className="py-0 flex flex-col md:flex-row">
                <div className={
                    Util.classNames(
                        "panel flex flex-col md:w-1/4 py-4 md:border-r",
                        "overflow-y-auto",
                    )}>
                    <p className="px-4 pb-4 inline-flex justify-between text-lg font-bold text-gray-600">
                        GDrives
                        <PencilSquareIcon
                            className="h-6 w-6 ml-2 text-indigo-600 cursor-pointer"
                            onClick={handleCreate}
                        />
                    </p>

                    {channels.map( ( chan, idx ) => (
                        <div key={`channel_${idx}`}
                             className={Util.classNames(
                                 "flex justify-between gap-x-4 p-2 border-t border-gray-300 cursor-pointer",
                                 (channel.uid === chan.uid)? "bg-gray-100": ""
                             )}
                             onClick={() => handleChannel(chan)}>
                            <p className={Util.classNames("text-sm truncate", (channel.uid === chan.uid)? "font-semibold": "")}>
                                {chan.name}
                            </p>
                            {channel.uid === chan.uid &&
                            <EllipsisHorizontalIcon
                                className="hidden flex-shrink-0 sm:inline h-6 w-6 hover:text-indigo-600"/>
                            }
                        </div>
                    ))}
                </div>
                <div className="panel p-4 lg:pr-1 md:w-full">
                    <p className="text-lg font-bold text-gray-600">
                        Chat
                    </p>
                    <ChatConversation
                        chat={chat}
                        showToast={showToast}
                    />
                    {/*onRate={onRate}*/}
                </div>
            </div>
            <div ref={footerRef} className="px-4 py-4 sm:px-6">
                <ChatQuery
                    onQuestion={handleQuestion}
                    showToast={showToast}
                />
            </div>
        </div>
    )
}
