import React, { useState, useRef, useEffect } from "react"
import * as Util from "../helpers/util.js"
import { Link, useNavigate, useParams } from "react-router-dom";

import { closeWS, connectWS, sendWS } from "../helpers/util.js";
import { WS_URL } from "../settings";
import {
    QueueListIcon,
    ListBulletIcon,
    ChatBubbleOvalLeftEllipsisIcon,
    BellIcon,
    ArrowTopRightOnSquareIcon, DocumentIcon, BarsArrowDownIcon
} from "@heroicons/react/24/outline";
import {Dropdown, DropdownItem} from "../components/dropdown";
import { LegislationFeedTab } from "./legislation_feed_tab";
import {LegislationDetailTab} from "./legislation_detail_tab";
import { LegislationChat } from "./legislation_chat";
import { LegislationRatingTab } from "./legislation_rating_tab";
import { PageTitle } from "../components/page_title";
import { useStore } from "../store";
import { Banner } from "../components/banner";
import { LegislationText } from "./legislation_text";
import {Spinner} from "../components/spinner";
import {TutorialModal} from "../modals/tutorial_modal";


const tabs = [
    { name: 'Feed', icon: BellIcon },
    { name: 'Rating', icon: QueueListIcon},
    { name: 'Details', icon: ListBulletIcon },
    { name: 'Bill Chat', icon: ChatBubbleOvalLeftEllipsisIcon },
    { name: 'Bill Text', icon: DocumentIcon },
]

export const Legislation = (props) => {
    const { policy_uid, government_uid, policies, governments, onGovernmentChange, onPolicyChange, onTabChange, showToast } = props

    const { document_uid } = useParams()
    const { cache, usr_info } = useStore( x => x )

    const [state, setState] = useState( {
        analyzing: {},
        document: ('document' in cache && document_uid === cache.document.uid) ? cache.document : {},
        content_idx: -1,
        contents: [],
        tenant_document: {},

        committee: {},
        sponsors: [],
        referrals: [],
        roll_calls: [],

        humans: [],
        logs: [],
        history: [],
        chat: null,
        ready: false,
        download_contents: false,
        connection_error: false,
        connection_retry: -1,
    } )
    const {
        analyzing,
        document,
        content_idx,
        contents,
        tenant_document,
        humans,
        logs,
        history,
        chat,
        download_contents,
        ready,
        connection_error,
        connection_retry,

        committee,
        sponsors,
        referrals,
        roll_calls,
    } = state

    const [tab, setTab] = useState( tabs[0].name )

    const [ai_response, setAiResponse] = useState( null )
    const [ai_summary, setAiSummary] = useState( null )
    const [socket, setSocket] = useState( null );

    const [updated_policy, setUpdatedPolicy] = useState( {} )

    const content = (content_idx >= 0 && content_idx < contents.length) ? contents[content_idx]: { ai_summary: "", uid: null }
    const human_uid = usr_info.uid || null
    const content_uid = (content_idx >= 0) ? contents[content_idx].uid : null

    useEffect( () => {
        Util.fetch_js( '/api/document/modify/', { document_uid, is_read: true },
            js => {}, showToast )

        Util.fetch_js( '/api/human/list/', {}, js => {
            setState( prev => ({
                ...prev, humans: js.humans,
            }) )
        }, showToast )

        Util.fetch_js( '/api/comment/list/', { document_uid }, js => {
            setState( prev => ({
                ...prev, history: js.comments
            }) )
        }, showToast )

        //Get the details of the document
        Util.fetch_js( '/api/document/detail/', { document_uid }, js => {
            if ( government_uid !== js.government_uid ) {
                onGovernmentChange({ target:{ id: 'government_uid', name: 'government_uid', value: js.government_uid}})
            }

            //Trigger a AI summary on the titles when first loaded

            setState( prev => ({ ...prev,
                document: js.document,
                contents: js.contents,
                content_idx: js.contents.length - 1,
                tenant_document: js.tenant_document,
                committee: js.committee,
                sponsors: js.sponsors,
                referrals: js.referrals,
                roll_calls: js.roll_calls,
                download_contents: true,
                connection_retry: connection_retry + 1,
            }) )
        }, showToast )

        onTabChange( tabs[0].name )
    }, [])

    useEffect( () => {
        if ( connection_retry < 0 ) {
            return
        }

        const _socket = connectWS( `${WS_URL}/ws/cluster`, setSocket, {
            'enqueue_query': recvQuery,
            'enqueue_ai_summary': recvAiSummary,
            'chat_comment_rate': recvChatCommentRate,
            'enqueue_prompt_update': recvPromptUpdate,
            'enqueue_prompt_progress': recvPromptProgress,
        } )

        //Track the state of the socket
        _socket.onopen = () => {
            console.log( "Socket connected" )
            if ( connection_retry > 0 ) {
                showToast( "Connection re-established.", "successful" )
            }

            setState( prev => ({ ...prev, ready: true }) )
        }
        _socket.onclose = () => {
            console.log( 'Socket closed')
            setState( prev => ({ ...prev,
                ready: false,
                connection_error: true,
            }) )

            setTimeout( () => {
                setState( prev => ({ ...prev, connection_retry: prev.connection_retry + 1 }))
            }, 5000 )
        }

        return () => {
            console.log("Calling close")
            closeWS( _socket )
        }
    }, [connection_retry] );

    useEffect( () => {
        if ( !download_contents ) {
            return
        }

        //Download the content, and update our values
        Util.fetch_js( '/api/document/download_contents/', { document_uid }, js => {
            const lookup = {}
            js.contents.forEach( c => {
                lookup[c.content_uid] = c
            })

            //Store media
            const contents = state.contents.map( c => {
                if ( c.uid in lookup ) {
                    const nc = lookup[c.uid]
                    c.html_body = nc.html_body
                    c.text_body = nc.text_body
                }

                return c
            })

            setState( prev => ({ ...prev,
                contents
            }) )
        }, showToast )

    }, [download_contents] );

    useEffect( () => {
        //Called on start so just ignore
        if ( ai_response === null ) {
            return
        }
        if ( state.chat === null ) {
            console.log( "Got AI response, but chat isn't read: ", state.chat )
            return
        }

        //Store the answer back to the server
        Util.fetch_js( '/api/comment/conversation/', { comment_uid: state.chat.uid, response: ai_response },
            js => {
                setState( prev => ({
                    ...prev, chat: js.comment,
                }) )
            }, showToast )
    }, [ai_response] );

    useEffect( () => {
        if ( ai_summary === null ) {
            return
        }
        if ( content_idx < 0 && content_idx >= state.contents.length ) {
            console.log( `Got AI response, but the content is invalid ${content_idx} and len ${contents.length}` )
            return
        }

        //Update the database with our sweet new summary
        Util.fetch_js( '/api/content/modify/', { content_uid, ai_summary },
            js => {}, showToast)

        //Update the ai summary
        const contents = [...state.contents]
        contents[content_idx].ai_summary = ai_summary
        setState( prev => ({ ...prev,
            contents
        }) )
    }, [ai_summary] );

    useEffect( () => {
        //If we don't have a valid ai_summary, make one now
        if ( ready && content.ai_summary === "" && content_uid !== null && human_uid !== null ) {
            console.log("Sending AI Summary Request")
            sendWS( socket, 'enqueue_ai_summary', { human_uid, content_uid } )
        }
    }, [ready, content_uid] );

    const recvQuery = ( { response } ) => {
        setAiResponse( response ) // Required to do this, and then "useEffect" to keep things in sync
    }

    const recvAiSummary = ( { response } ) => {
        setAiSummary( response ) // Required to do this, and then "useEffect" to keep things in sync
    }

    const recvChatCommentRate = ( { rating } ) => {
        console.log(rating)
    }

    const recvPromptUpdate = ( { prompt, rating } ) => {
        //console.log(rating)
        setUpdatedPolicy( { prompt, rating } )
        setState( prev => ({ ...prev,
            analyzing: Object.entries(prev.analyzing).reduce((acc, [key, value]) => {
                if (key !== prompt.uid) {
                    acc[key] = value
                }
                return acc
            })
        }))
    }

    const recvPromptProgress = ({ prompt_uid }) => {
        //console.log(prompt_uid)
        setState( prev => ({ ...prev, analyzing: {...prev.analyzing, [prompt_uid]: true}}) )
    }

    /*
    const recvChatPolicyComplete = ({prompt_uid}) => {
        showToast( "Policy analysis complete.", "success" )
        setState( prev => ({ ...prev,
            analyzing: Object.entries(prev.analyzing).reduce((acc, [key, value]) => {
                if (key != prompt_uid) {
                    acc[key] = value
                }
                return acc
            })
        }))
    }
     */

    const handleTabChange = ( tab ) => {
        setTab( tab )
        onTabChange( tab )
    }

    const handleUserRating = ( e ) => {
        const user_rating = Util.xint(e.target.value)
        if ( Util.xint(document.user_rating) == user_rating ) {
            return
        }

        Util.fetch_js( '/api/document/modify/', { document_uid, user_rating }, js => {
            setState( prev => ({ ...prev,
                document: js.document,
                tenant_document: js.tenant_document,
            }) )
        }, showToast )
    }

    const handleRatePolicy = ( policy_uid, force ) => {
        showToast( "Rating policy...", "info" )
        //Don't start this unless we're connected to the ai server
        if ( !ready ) {
            showToast( "Not connected to AI Server.", "failure" )
            return
        }
        if ( human_uid === null || content_uid === null ) {
            showToast( "Invalid human or content.", "failure" )
            return
        }

        if ( force !== true ) {
            force = false
        }
        sendWS( socket, 'enqueue_rate_policy', { policy_uid, force, human_uid, content_uid } )
    }

    const handleRateClaim = ( claim_uid ) => {
        //Don't start this unless we're connected to the ai server
        if ( !ready ) {
            showToast( "Not connected to AI Server.", "failure" )
            return
        }
        if ( human_uid === null || content_uid === null ) {
            showToast( "Invalid human or content.", "failure" )
            return
        }

        console.log( "Forcing claim: ", claim_uid)
        sendWS( socket, 'chat_claim', { claim_uid, force: true } )
    }

    const handleRatePrompt = ( prompt_uid ) => {
        //Don't start this unless we're connected to the ai server
        if ( !ready ) {
            showToast( "Not connected to AI Server.", "failure" )
            return
        }
        if ( human_uid === null || content_uid === null ) {
            showToast( "Invalid human or content.", "failure" )
            return
        }

        //console.log( "Forcing claim: ", prompt_uid)
        sendWS( socket, 'enqueue_rate_prompt', { prompt_uid, human_uid, content_uid, force: true } )
    }

    const handleInjectChat = ( chat ) => {
        setState( prev => ({ ...prev,
            chat,
        }) )
    }

    const handleChatQuestion = ( question ) => {
        if ( human_uid === null || content_uid === null ) {
            showToast( "Invalid human or content.", "failure" )
            return
        }

        //Don't start this unless we're connected to the ai server
        if ( !ready ) {
            showToast( "Not connected to AI Server.", "failure" )
            return
        }

        //Create a chat or use the conversation API
        if ( chat === null ) {
            Util.fetch_js( '/api/comment/create/', { content_uid, type: 'chat', comment: question },
                js => {
                    sendWS( socket, 'enqueue_query', { question, human_uid, content_uid } )
                    setState( prev => ({ ...prev,
                        chat: js.comment,
                    }) )
                }, showToast )
        }
        else {
            Util.fetch_js( '/api/comment/conversation/', { comment_uid: chat.uid, question },
                js => {
                    sendWS( socket, 'enqueue_query', { question, human_uid, content_uid } )
                    setState( prev => ({ ...prev,
                        chat: js.comment,
                    }) )
                }, showToast )
        }
    }

    const handleRateComment = ( conversation ) => {
        //Don't start this unless we're connected to the ai server
        if ( !ready ) {
            showToast( "Not connected to AI Server.", "failure" )
            return
        }

        const payload = {
            comment_uid: conversation.comment_uid,
            conversation_uid: conversation.uid,
            comment: conversation.comment,
            response: conversation.response,
        }
        sendWS( socket, 'chat_comment_rate', payload )
    }

    const handleContentChange = ( title ) => {
        const content_idx = contents.findIndex( c => c.title === title )
        if ( content_idx < 0 ) {
            return
        }

        setState( prev => ({ ...prev, content_idx }) )
    }

    const handleChange = (e) => {
        setState( prev => ({ ...prev, [e.target.name]: e.target.value }) )
    }

    const pdf_link = <ArrowTopRightOnSquareIcon
        className="h-5 w-5 ml-2 text-indigo-600 cursor-pointer shrink-0"
        onClick={() => window.open( document.remote_url, '_blank' )}
    />

    return (
        <>
            {connection_error &&
                <Banner
                    message={"AI Server connection failed."}
                    color={"bg-red-600"}
                    onDismiss={() => {
                        setState(prev => ({...prev, connection_error: false}))
                    }}
                />
            }

            <PageTitle
                title={<span className="inline-flex items-center">{document.code} {pdf_link}</span>}
                subtitle={document.title}
                combo_header={`${document.code} Version(s)`}
                combo_text={(content_idx >= 0 && content_idx < contents.length) ? contents[content_idx].title : "Newest"}
                combo_list={contents.map(c => c.title)}
                onClick={handleContentChange}
            />

            <div className="divide-y divide-gray-200 overflow-hidden rounded-lg bg-white shadow">
                <div className="px-4 py-5 pb-0 sm:px-6">
                    <div className="sm:hidden">
                        <label htmlFor="tabs" className="sr-only">
                            Select a tab
                        </label>
                        <Dropdown
                            header={document.code}
                            value={tab}
                            align="left">
                            {tabs.map((t, idx) => (<DropdownItem
                                id='tab'
                                key={`tab_${idx}`}
                                icon={t.icon}
                                value={t.name}
                                active={t.name == tab}
                                onClick={e => handleTabChange(e.target.value)}>
                                {t.name}
                            </DropdownItem>))}
                        </Dropdown>
                    </div>
                    <div className="hidden sm:block">
                        <div className="">
                            <nav className="-mb-px flex space-x-8" aria-label="Tabs">
                                {tabs.map((t) => (<div key={t.name}
                                                       onClick={e => handleTabChange(t.name)}
                                                       className={
                                                           Util.classNames(
                                                               t.name == tab ? 'border-indigo-500 text-indigo-600' : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                                                               'cursor-pointer group inline-flex items-center border-b-2 py-4 px-1 text-sm font-medium'
                                                           )}
                                                       aria-current={t.name === tab ? 'page' : undefined}>
                                    <t.icon
                                        className={Util.classNames(t.name == tab ? 'text-indigo-500' : 'text-gray-400 group-hover:text-gray-500', '-ml-0.5 mr-2 h-5 w-5')}
                                        aria-hidden="true"
                                    />
                                    <span>{t.name}</span>
                                </div>))}
                            </nav>
                        </div>
                    </div>
                </div>
                {tab === 'Rating' &&
                    <LegislationRatingTab
                        policy_uid={policy_uid}
                        policies={policies}
                        document={document}
                        content_idx={content_idx}
                        contents={contents}
                        humans={humans}
                        updating_prompt={analyzing}
                        updated_policy={updated_policy}
                        onPolicyChange={onPolicyChange}
                        onRatePolicy={handleRatePolicy}
                        onRateClaim={handleRateClaim}
                        onRatePrompt={handleRatePrompt}
                        onUserRating={handleUserRating}
                        showToast={showToast}
                    />}
                {tab === 'Feed' &&
                    <LegislationFeedTab
                        document_uid={document_uid}
                        humans={humans}
                        logs={logs}
                        content_idx={content_idx}
                        contents={contents}
                        onLogs={handleChange}
                        showToast={showToast}
                    />}
                {tab === 'Details' &&
                    <LegislationDetailTab
                        document={document}
                        content_idx={content_idx}
                        contents={contents}
                        tenant_document={tenant_document}
                        committee={committee}
                        sponsors={sponsors}
                        referrals={referrals}
                        roll_calls={roll_calls}
                        onChange={handleChange}
                        showToast={showToast}
                    />}
                {tab === 'Bill Chat' &&
                    <LegislationChat
                        document={document}
                        content_idx={content_idx}
                        contents={contents}
                        humans={humans}
                        chat={chat}
                        history={history}
                        onRate={handleRateComment}
                        onQuestion={handleChatQuestion}
                        onHistory={handleInjectChat}
                        showToast={showToast}
                    />}
                {tab === 'Bill Text' &&
                    <LegislationText
                        document={document}
                        content_idx={content_idx}
                        contents={contents}
                        showToast={showToast}
                    />}
            </div>
        </>
    )
}
